import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractInfoMessageScreenComponent } from '../../../abstract/abstract-info-message-screen.component';
import { select } from '@ngrx/store';
import { SocketSelectors } from '../../../../../store/socket';
import { SOCKET_STATUS } from '../../../../../core/consts/core/socket.constants';
import { unsubscribeObject } from '../../../../../core/utility/unsubscribe-array';

@Component({
  selector: 'app-m-info-message-screen',
  templateUrl: './info-message-screen.component.html',
  styleUrls: ['./info-message-screen.component.scss']
})
export class MInfoMessageScreenComponent extends AbstractInfoMessageScreenComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subs.socket = this.store
      .pipe(
        select(SocketSelectors.selectState)
      )
      .subscribe((socketState) => {
        if (this.socketState && this.socketState.socketStatus === SOCKET_STATUS.OTHER_SESSION) {
          return
        }

        this.socketState = socketState;
      });
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs)
  }
}
