import { CUSTOM_AUTH_ROUTING_COMPONENTS } from '../custom/routing-auth-components.const';
import { LoginComponent } from '../../basic/components/login/login.component';
import { ChangePasswordComponent } from '../../basic/components/change-password/change-password.component';
import { ForgotPasswordComponent } from '../../basic/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../../basic/components/reset-password/reset-password.component';
import { RegistrationAComponent } from '../../basic/components/registration/variant-a/registration-a.component';
import { RegistrationConfirmAComponent } from '../../basic/components/registration-confirm/variant-a/registration-confirm-a.component';
import { ReauthorizeComponent } from '../../basic/components/reauthorize/reauthorize.component';

export const AUTH_ROUTING_COMPONENTS = {
  LOGIN: LoginComponent,
  CHANGE_PASSWORD: ChangePasswordComponent,
  FORGOT_PASSWORD: ForgotPasswordComponent,
  RESET_PASSWORD: ResetPasswordComponent,
  REAUTHORIZE: ReauthorizeComponent,
  REGISTRATION_A: RegistrationAComponent,
  REGISTRATION_CONFIRM_A: RegistrationConfirmAComponent,
  ...CUSTOM_AUTH_ROUTING_COMPONENTS,
};
