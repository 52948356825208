import { Injectable } from '@angular/core';
import { AbstractEventDialogsService } from '../../../../../../core/abstracts/abstract-event-dialogs.service';
import { EVENT_DIALOGS_QA } from '../../consts/core/event-dialogs/event-dialogs.const';
import { EVENT_DIALOGS_QA_CUSTOM } from '../../consts/custom/event-dialogs/event-dialogs.const';

@Injectable({
  providedIn: 'root'
})
export class QaEventDialogsService extends AbstractEventDialogsService {
  getEventDialogs() {
    return {
      coreEventDialogs: EVENT_DIALOGS_QA,
      customEventDialogs: EVENT_DIALOGS_QA_CUSTOM,
    };
  }
}
