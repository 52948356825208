import { Pipe, PipeTransform } from '@angular/core';
import { AssetsService } from '../../../core/providers/assets.service';
import { DeviceService } from '../../../core/providers/device.service';

@Pipe({
  name: 'asset',
})
export class AssetPipe implements PipeTransform {
  constructor(private assetsService: AssetsService,
              private deviceService: DeviceService,
  ) {
  }

  transform(assetName: string, assetPath: string, isMobile = this.deviceService.mobileGui, extension: string = '') {
    const mobilePath = isMobile ? '' : ''; // temporary override mobile path
    let asset = this.assetsService.getAssetPath(`${assetPath}/${mobilePath}${assetName}${extension}`);
    if (!asset && isMobile) {
      asset = this.assetsService.getAssetPath(`${assetPath}/${assetName}${extension}`);
    }
    return asset;
  }
}
