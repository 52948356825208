import { Component, OnInit } from '@angular/core';
import { AbstractStockBase } from '../../../../abstract/abstract-stock-base.component';
import { STOCK_VIEW } from '../../../consts/stock-view.const';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { AssetsService } from '../../../../../../../../core/providers/assets.service';
import { translate } from '../../../../../../../../core/helpers/translate.helper';

@Component({
  selector: 'm-ui-stock-other',
  templateUrl: '../stock.html',
  styleUrls: ['../stock.scss']
})
export class MUiStockOtherComponent extends AbstractStockBase implements OnInit {
  @OwInject(AssetsService) assetsService: AssetsService;
  defaultStockView = STOCK_VIEW.A;

  ngOnInit() {
    this.setDefaultStockView();
    this.setIconUrl();
    this.parseItem();
  }

  setIconUrl() {
    switch (this.item.icon) {
      case 'exp':
        this.item.name = translate('global.exp');
        this.item.iconUrl = this.assetsService.getAssetPath(`parameters/basic/${this.item.icon}.png`);
        this.item.iconUrlBig = this.assetsService.getAssetPath(`parameters/big/${this.item.icon}.png`);
        break;

      default:
        this.item.iconUrl = this.assetsService.getAssetPath(`icons/basic/${this.item.icon}.png`);
        this.item.iconUrlBig = this.assetsService.getAssetPath(`icons/big/${this.item.icon}.png`);
    }
  }
}
