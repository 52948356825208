import { EventDialog } from '../../../../../../../core/interfaces/event-dialog.interface';
import { EVENT_DIALOGS_NAMES_BUILDINGS } from './event-names.const';
import { MBuildingSpecialComponent } from '../../../base/core/dialogs/building-special/building-special.component';
import { MBuildingUpgradeComponent } from '../../../base/core/dialogs/building-upgrade/m-building-upgrade.component';
import { MBuildingBuildComponent } from '../../../base/core/dialogs/building-build/m-building-build.component';
import { MBuildingProductionComponent } from '../../../base/core/dialogs/building-production/m-building-production.component';
import { MBuildingsListComponent } from '../../../base/core/dialogs/buildings-list/m-buildings-list.component';
import { MBuildingInfoComponent } from '../../../base/core/dialogs/building-info/m-building-info.component';
import { MBuildingAutoProductionComponent } from '../../../base/core/dialogs/building-auto-production/building-auto-production.component';
import { MBuildingDemolishComponent } from '../../../base/core/dialogs/building-demolish/building-demolish.component';
import { MBuildingMoveComponent } from '../../../base/core/dialogs/building-move/building-move.component';
import { MTileBuyComponent } from '../../../base/core/dialogs/tile-buy/tile-buy.component';


export const EVENT_DIALOGS_BUILDINGS: EventDialog = {
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_SPECIAL_UPGRADE]: MBuildingSpecialComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_UPGRADE]: MBuildingUpgradeComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_BUILD]: MBuildingBuildComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_PRODUCTION]: MBuildingProductionComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_LIST]: MBuildingsListComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_INFO]: MBuildingInfoComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_AUTO_PRODUCTION]: MBuildingAutoProductionComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_DEMOLISH]: MBuildingDemolishComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_MOVE]: MBuildingMoveComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_TILE_BUY]: MTileBuyComponent,
};
