import { Injectable, ViewChild } from '@angular/core';
import { BuildingDetailsConfig } from '../../../../game-engine/interfaces/building-details-config';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BuildingsService } from '../../../../services/buildings.service';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { BuildingBuildData } from '../../interfaces/core/dialogs/building-build-data.interface';
import { forkJoin, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BUILDING_TYPES } from '../../consts/core/buidling-types.const';
import { translate } from '../../../../../../core/helpers/translate.helper';
import * as moment from 'moment';

@Injectable()
export abstract class AbstractBuildingBuildComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractBuildingBuildComponent>;
  @OwInject(MAT_DIALOG_DATA) data: BuildingBuildData;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(DialogService) dialogService: DialogService;

  BUILDING_TYPES = BUILDING_TYPES;
  STOCK_VIEW = STOCK_VIEW;
  buildingDetails: BuildingDetailsConfig;
  unlockedBuildings: any[];
  @ViewChild('sliderBuilding', {static: false}) sliderBuilding;
  sliderActiveIndex = 0;
  maxSlides = 0;
  requirementsStatus: { valid: boolean, requirementsList: any[] };

  getData() {
    forkJoin([
      this.getBuildingDetails(this.data.buildingId),
      this.getUnlockedBuildings(this.data.buildingId),
    ])
      .pipe(
        tap(() => {
          this.checkSlideLength();
        })
      )
      .subscribe(() => {
        this.afterGetDataRequests();
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }

  afterGetDataRequests() {
  }

  getBuildingDetails(buildingId: number) {
    return this.buildingsService.getBuildingDetails(buildingId)
      .pipe(
        tap((buildingDetails) => {
          this.buildingDetails = buildingDetails;
        })
      );
  }

  getUnlockedBuildings(buildingId: number) {
    return this.buildingsService.getUnlockedBuildings(buildingId)
      .pipe(
        tap((buildings: any[]) => {
          this.unlockedBuildings = buildings;
        }),
        catchError((error) => of(error)),
      );
  }

  checkSlideLength() {
    setTimeout(() => {
      if (this.sliderBuilding) {
        this.maxSlides = this.sliderBuilding.swiperSlides.nativeElement.childElementCount;
      } else {
        this.maxSlides = 0;
      }

      this.checkSlideLength();
    }, 500);
  }

  build({fastBuild}: { fastBuild?: boolean } = {}) {
    this.buildingsService.build(this.data.playerTileId, this.data.buildingId, fastBuild)
      .subscribe((resp) => {
        this.dialogService.closeAll();
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }

  openFastBuildConfirm() {
    let description = translate('fast-action.description');
    if (this.buildingDetails.fast_building_time_in_seconds) {
      const time = moment.utc(this.buildingDetails.fast_building_time_in_seconds * 1000).format('HH[h] mm[m] ss[s]');
      const extendDescription = translate('building-build.fast-action-extend-description', [time]);
      description += extendDescription;
    }

    this.dialogService.openConfirm({
      title: translate('fast-action.title'),
      description,
      costs: {
        separatorTitle: translate('fast-action.separator-title'),
        currencies: this.buildingDetails.fast_build_currency_prices,
        products: this.buildingDetails.fast_build_product_prices,
      }
    }, (confirm) => {
      if (confirm) {
        this.build({fastBuild: true});
      }
    });
  }

  setRequirementsStatus(status) {
    this.requirementsStatus = status;
  }

  prevSlide() {
    this.sliderBuilding.directiveRef.prevSlide();
  }

  nextSlide() {
    this.sliderBuilding.directiveRef.nextSlide();
  }
}
