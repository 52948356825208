import { Wave } from './Wave';

export class WaveEmitter {

  x: number;
  y: number;
  targetX: number;
  targetY: number;
  isRipple?: boolean;
  imageKey: string;
  emitInterval: number;
  scene: Phaser.Scene;

  interval: any;

  constructor(params: WaveEmitterConfig) {
    this.x = params.x;
    this.y = params.y;
    this.targetX = params.targetX;
    this.targetY = params.targetY;
    this.isRipple = params.isRipple;
    this.imageKey = params.imageKey;
    this.emitInterval = params.emitInterval;
    this.scene = params.scene;

    this.start();
  }

  start() {
    this.emitWave();
    setTimeout(() => {
      this.start();
    }, this.emitInterval);
  }

  emitWave() {
    const wave = new Wave({
      scene: this.scene,
      x: this.x,
      y: this.y,
      key: this.imageKey,
      targetX: this.targetX,
      targetY: this.targetY
    });
  }

  stop() {

  }

  destroy() {

  }

}

interface WaveEmitterConfig {
  x: number;
  y: number;
  targetX: number;
  targetY: number;
  imageKey: string;
  emitInterval: number;
  isRipple?: boolean;
  scene: Phaser.Scene;
}
