<ng-container *ngIf="elements && elements.length > 1">
  <span class="dot"
        [class.stroke]="stroke"
        [class.active]="idx == currentIndex"
        [class.sibling]="idx == currentIndex + 1 || idx == currentIndex - 1"
        [class.sibling-far]="idx == currentIndex + 2 || idx == currentIndex - 2"
        [class.no-dynamic-bullets]="elements.length <= 3"
        *ngFor="let item of elements; let idx = index"
  >
  </span>
</ng-container>
