import { PLURAL_FUNCTIONS } from '../plurals/core/plural-functions';
import { GAME_CONFIG } from '../config/custom/_parsed-game.config';

const plurals = require('../plurals/custom/_plurals.json');

/* CHECK PLURAL ATTR FUNCTION */
function checkPluralAttributes(text) {
  const isPluralRegEx = new RegExp(/\[plural(.*?)\]/, 'g');
  const keyRegEx = /key='(.*?)'/;
  const valueRegEx = /value='(.*?)'/;
  const showValueRegEx = /showValue='(.*?)'/;

  const isPluralAttributesArray = text.match(isPluralRegEx);

  if (!isPluralAttributesArray) {
    return text;
  }

  return isPluralAttributesArray.map((item) => {
    const key = getFirstResultsRegex(keyRegEx, item);
    const value = getFirstResultsRegex(valueRegEx, item);
    const showValue = getFirstResultsRegex(showValueRegEx, item);

    return {
      value: isNaN(value) ? value : Number(value),
      showValue: Number(showValue) === 1,
      key,
      pluralAttribute: item, // [plural key=''....]
      replacedValue: null,
    };
  });
}

function getFirstResultsRegex(regex, text) {
  const results = regex.exec(text);

  return results ? results[1] : null;
}

/* PLURALIZE FUNCTION */
function pluralizeText(objectsArray, pluralDefinitions) {
  objectsArray.forEach((obj) => {
    if (obj.key) {
      let pluralizedTextResult = '';
      const pluralDefinition = pluralDefinitions[obj.key];
      const pluralDefinitionOptions = pluralDefinition.options;

      for (let pluralDefinition of pluralDefinitionOptions) {
        const values = pluralDefinition.values;

        if (values) {
          for (const value of values) {
            if (obj.value === value) {
              pluralizedTextResult = pluralDefinition.label;
              break;
            }
          }
        } else {
          if (pluralDefinition.func) {
            const func = PLURAL_FUNCTIONS[pluralDefinition.func];
            const funcResult = func && func(obj.value);

            if (funcResult && pluralDefinition.label) {
              pluralizedTextResult = pluralDefinition.label;
            } else if (funcResult && pluralDefinition.label === undefined) {
              pluralizedTextResult = funcResult;
            }
          }
        }

        if (pluralizedTextResult) {
          break;
        }
      }

      if (!pluralizedTextResult) {
        pluralizedTextResult = pluralDefinition.default;
      }

      if (pluralizedTextResult) {
        let pluralizedValue = '';
        if (obj.showValue) {
          pluralizedValue = obj.value + ' ';
        }
        pluralizedTextResult = `${pluralizedValue + pluralizedTextResult}`;
      }

      obj.replacedValue = pluralizedTextResult;
    }
  });

  return objectsArray;
}

/* REPLACE STRING FUNCTION */
function replacePluralizedString(objectsArray, editableText) {
  return objectsArray.reduce((string, obj) => {
    return string.replace(obj.pluralAttribute, obj.replacedValue || obj.pluralAttribute);
  }, editableText);
}

/* MAIN PARENT FUNCTION */
export function editPluralText(editableText) {
  let language = GAME_CONFIG.LANGUAGE;

  let pluralDefinitions: string = plurals[language] || plurals[GAME_CONFIG.LANGUAGE];

  const pluralAttributes = checkPluralAttributes(editableText);
  if (typeof (pluralAttributes) === 'string') {
    return pluralAttributes;
  } else {
    return replacePluralizedString(pluralizeText(pluralAttributes, pluralDefinitions), editableText);
  }
}
