import { WorldMainScene } from '../core/World.main.scene';
import { WORLD_SCENE_WORLD_HEIGHT, WORLD_SCENE_WORLD_WIDTH } from '../world.constants';

export class CustomWorldMainScene extends WorldMainScene {
  afterCreate() {
    this.cameras.main.setBounds(
      -200,
      0,
      WORLD_SCENE_WORLD_WIDTH + 200,
      WORLD_SCENE_WORLD_HEIGHT
    );
    this.cameras.main.setBackgroundColor('#4d92cd')
  }
}
