import { AfterViewInit, Directive, ElementRef, HostListener, Inject, Input, OnChanges, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DialogService } from '../providers/dialog.service';
import { translate } from '../../../core/helpers/translate.helper';

@Directive({
  selector: '[owEllipsis]'
})
export class OwEllipsisDirective implements AfterViewInit, OnChanges {
  @Input() innerText: string;
  @Input() labelMore = translate('ow-ellipsis.show-more-label');
  @Input() eventClass = 'event-more';
  @Input() isDisabledClick: boolean;
  @Input() textAlign?: string;
  @Input() maxWidth?: string;
  @Input() isScrollbarOn?: boolean;

  get el(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  @HostListener('click', ['$event']) onClick(event) {
    if (!this.isDisabledClick && event.target && event.target.className.includes(this.eventClass)) {
      this.open();
    }
  }

  constructor(
    private readonly elementRef: ElementRef,
    private dialogService: DialogService,
    @Inject(PLATFORM_ID) private readonly platformId,
  ) {
  }

  ngOnChanges(): void {
    this.truncate();
  }

  public ngAfterViewInit(): void {
    this.truncate();
  }

  @HostListener('window:resize')
  private onWindowResize() {
    this.truncate();
  }

  private truncate(): void {
    // verify execution context is the browser platform
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // store the original innerText
    if (this.innerText === undefined) {
      this.innerText = this.el.innerHTML.trim();
    }

    // reset the innerText
    this.el.innerHTML = this.innerText;

    // truncate the text and append the ellipsis
    const text = this.innerText
      .split(' ')
      .slice(0, 60); // #16507 - optimize while iteration

    while (text.length > 0 && this.el.scrollHeight > this.el.clientHeight) {
      text.pop();
      this.el.innerHTML = `${text.join(' ')}${this.getElementEventDialog()}`;
    }
  }

  getElementEventDialog() {
    return `<span class="${this.eventClass}">${this.labelMore}</span>`;
  }

  open() {
    if (this.innerText !== this.el.innerText) {
      this.dialogService.openAlert({
        description: this.innerText,
        style: {
          textAlign: this.textAlign,
          maxWidth: this.maxWidth,
        },
        isScrollbarOn: this.isScrollbarOn,
      });
    }
  }
}
