<!-- SEPARATOR -->
<m-ui-separator
  *ngIf="isShowSeparator"
  [title]="separatorTitle"
></m-ui-separator>

<div class="score-and-prizes" #scoreAndPrizes>

  <!-- SCORE AND TIME -->
  <m-ui-stock-other
    *ngIf="qa.type == QA_TYPE.QUIZ && answeringTime"
    [item]="{icon: 'time'}"
    [other]="{amount: answeringTime}"
    [stockView]="STOCK_VIEW.B"
    [lack]="false"
  ></m-ui-stock-other>

  <m-ui-stock-other
    *ngIf="qa.type == QA_TYPE.QUIZ && scoreText"
    [item]="{icon: 'check'}"
    [other]="{amount: scoreText}"
    [stockView]="STOCK_VIEW.B"
    [lack]="false"
  ></m-ui-stock-other>


  <!-- PRIZES FOR QA -->
  <ng-container *ngFor="let prize of qa.currency_prizes">
    <m-ui-currency [item]="prize"
                   [stockView]="STOCK_VIEW.B"
                   [lack]="false"
    ></m-ui-currency>
  </ng-container>

  <ng-container *ngFor="let prize of qa.product_prizes">
    <m-ui-product [item]="prize"
                  [stockView]="STOCK_VIEW.B"
                  [lack]="false"
    ></m-ui-product>
  </ng-container>

  <!-- PRIZES FOR THE RIGHT ANSWER -->
  <ng-container *ngIf="qa.last_attempt">
    <ng-container *ngFor="let prize of qa.last_attempt.currency_prizes_for_answers">
      <m-ui-currency [item]="prize"
                     [stockView]="STOCK_VIEW.B"
                     [lack]="false"
      ></m-ui-currency>
    </ng-container>

    <ng-container *ngFor="let prize of qa.last_attempt.product_prizes_for_answers">
      <m-ui-product [item]="prize"
                    [stockView]="STOCK_VIEW.B"
                    [lack]="false"
      ></m-ui-product>
    </ng-container>
  </ng-container>
</div>
