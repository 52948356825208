import { AbstractInjectBaseComponent } from '../../../../../core/abstracts/abstract-inject-base.component';
import { EventEmitter, Input, Output } from '@angular/core';
import { Currency } from '../../../../../core/interfaces/currency';
import { STOCK_VIEW } from '../mobile/consts/stock-view.const';
import { EVENTS } from '../../../../../core/consts/core/events';
import { translate } from '../../../../../core/helpers/translate.helper';

export abstract class AbstractCostsComponent extends AbstractInjectBaseComponent {
  @Input() currencies: Currency[];
  @Input() products: any[];
  @Input() stockView: string = STOCK_VIEW.D;
  @Input() observable = true;
  @Input() lack = true;
  @Input() customClassStock: string;
  @Input() customIconSize: string;

  @Output() eventEmitter = new EventEmitter();
  costsRequired = {
    valid: true,
    reason: '',
    items: [],
  };

  TYPE = {
    CURRENCY: 'currency',
    PRODUCT: 'product',
  };

  checkAndSetDefaultValue() {
    if (!this.products) {
      this.products = [];
    }

    if (!this.currencies) {
      this.currencies = [];
    }
  }

  itemChanged(item, type: string) {
    let existItemIndex;
    if (type === this.TYPE.CURRENCY) {
      existItemIndex = this.costsRequired.items.findIndex(i => i.currency_id === item.currency_id);
    }

    if (type === this.TYPE.PRODUCT) {
      existItemIndex = this.costsRequired.items.findIndex(i => i.product_id === item.product_id);
    }

    if (existItemIndex !== -1) {
      this.costsRequired.items.splice(existItemIndex, 1, item);
    } else {
      this.costsRequired.items.push(item);
    }

    this.checkCompletedRequired();
  }

  checkCompletedRequired() {
    const arrayLength = [...this.currencies, ...this.products].length;

    if (arrayLength === this.costsRequired.items.length) {
      this.checkValid();
    }
  }

  checkValid() {
    this.clearValid();
    this.costsRequired.items.forEach((item) => {
      if (!item.have) {
        this.costsRequired.valid = false;
        this.costsRequired.reason = translate('costs.required-reason');
      }
    });

    this.sendEvent();
  }

  clearValid() {
    this.costsRequired.valid = true;
    this.costsRequired.reason = '';
  }

  sendEvent() {
    this.eventEmitter.emit({
      action: EVENTS.GUI.REQUIREMENTS.COSTS,
      value: this.costsRequired,
    });
  }
}
