import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '../../../core/providers/currency.service';
import { Currency, CurrencyDefinition } from '../../../core/interfaces/currency';

@Pipe({
  name: 'singleCurrency',
})
export class SingleCurrencyPipe implements PipeTransform {
  constructor(private currencyService: CurrencyService) {
  }

  transform(currency: Currency): CurrencyDefinition {
    return this.currencyService.getCurrencyDefinition(currency);
  }
}
