import { Injectable } from '@angular/core';
import { AbstractRankDetailsBase } from './abstract-rank-details-base.component';
import { OwInject } from '../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FILTER_TYPE } from '../consts/filter-type.const';
import { RankDetails, RankGame } from '../interfaces';
import { ApiGameRanksService } from '../api/services/api-game-ranks.service';
import { RankDetailsData } from '../interfaces/dialogs/rank-details-data.interface';
import { STOCK_VIEW } from '../../shared-ui/mobile/consts/stock-view.const';
import { translate } from '../../../../../core/helpers/translate.helper';

@Injectable()
export abstract class AbstractRankDetailsGameComponent extends AbstractRankDetailsBase {
  @OwInject(ApiGameRanksService) apiGameRanksService: ApiGameRanksService;
  @OwInject(MAT_DIALOG_DATA) data: RankDetailsData & { rank: RankGame };
  STOCK_VIEW = STOCK_VIEW;

  getRank() {
    this.getGameRank();
  }

  getGameRank() {
    const rank = this.data.rank;

    this.apiGameRanksService.gameRanksResults({
      limit: this.limit,
      rankEditionId: rank.rank_edition_id,
      branch: this.filters[FILTER_TYPE.A],
      region: this.filters[FILTER_TYPE.B],
      makroregion: this.filters[FILTER_TYPE.C],
    })
      .subscribe((resp: RankDetails) => {
        this.rankDetails = resp;
        this.afterGetGameRankRequest();
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }

  afterGetGameRankRequest() {
    this.showAlertIfEmptyResults();
  }

  showAlertIfEmptyResults() {
    if (this.rankDetails.results.length === 0) {
      this.dialogService.openAlert({
        description: translate('ranks.rank-details-game.no-rank-items-alert.description'),
      }, () => {
        this.close();
      });
    }
  }
}
