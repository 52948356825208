import { AbstractInjectBaseComponent } from '../../../../../core/abstracts/abstract-inject-base.component';
import { EVENTS } from '../../../../../core/consts/core/events';
import { EventEmitter, Input, Output } from '@angular/core';
import { Currency } from '../../../../../core/interfaces/currency';
import { OwInject } from '../../../../../core/decorators/ow-inject.decorator';
import { AppState } from '../../../../../store/state';
import { select, Store } from '@ngrx/store';
import { selectPlayer } from '../../../../../store/player/selectors';
import { PlayerService } from '../../../../player/providers/player.service';
import { Player, Star } from '../../../../player/interfaces/player';
import { translate } from '../../../../../core/helpers/translate.helper';
import { AbstractGroupCountComponent } from './abstract-group-count.component';
import { filter } from 'rxjs/operators';
import { RequiredBuildingConfig } from '../../../game-engine/interfaces/required-building-config';
import { ParametersService } from '../../../../../core/providers/parameters.service';
import { getCorrectlyStarId } from '../../../../../store/player/custom/helpers/correctly-star-id.helper';

export abstract class AbstractRequirementsComponent extends AbstractInjectBaseComponent {
  @OwInject(Store) store: Store<AppState>;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ParametersService) parametersService: ParametersService;

  @Input() separator: string;
  @Input() products: any[];
  @Input() currencies: Currency[];
  @Input() playerLevel: number;
  @Input() populationOutcome: number;
  @Input() requiredBuilding: RequiredBuildingConfig;
  @Input() groupLimit: any;
  @Input() population: number;
  @Input() star: Star;

  @Output() requirementsStatus = new EventEmitter();

  player: Player;
  EVENTS = EVENTS;
  waitingList = {};
  textsList: any[] = [];

  subs = {
    player: null,
    groupCount: null,
  };

  subscribePlayer() {
    this.subs.player = this.store.pipe(
      select(selectPlayer),
    ).subscribe(player => {
      this.player = player;
      this.buildWaitingList();
    });
  }

  buildWaitingList() {
    if (this.products || this.currencies) {
      this.waitingList[EVENTS.GUI.REQUIREMENTS.COSTS] = {progress: true};
      // CHECK INNER COSTS COMPONENT
    }

    if (this.playerLevel) {
      this.waitingList[EVENTS.GUI.REQUIREMENTS.PLAYER_LEVEL] = {progress: true};
      this.checkPlayerLevel();
    }

    if (this.population) {
      this.waitingList[EVENTS.GUI.REQUIREMENTS.POPULATION] = {progress: true};
      this.checkPopulation();
    }

    if (this.requiredBuilding) {
      this.waitingList[EVENTS.GUI.REQUIREMENTS.BUILDING] = {progress: true};
      this.checkBuilding();
    }

    if (this.groupLimit) {
      this.waitingList[EVENTS.GUI.REQUIREMENTS.GROUP_LIMIT] = {progress: true};
      this.checkGroupLimit();
    }

    if (this.star) {
      this.waitingList['star'] = {progress: true};
      this.checkStar();
    }
  }

  checkPlayerLevel() {
    const valid = this.playerService.allowRequiredLevel(this.playerLevel);

    const event = {
      action: EVENTS.GUI.REQUIREMENTS.PLAYER_LEVEL,
      value: {
        valid,
        text: `${translate('global.level')} ${this.playerLevel}`,
      }
    };

    this.checkCompleted(event);
  }

  checkPopulation() {
    const valid = this.player.population_current >= this.population;
    const populationDef = this.parametersService.getParameterDefinitionByType('population_delta');

    const event = {
      action: EVENTS.GUI.REQUIREMENTS.POPULATION,
      value: {
        valid,
        text: `${populationDef.name} (${this.population})`,
      }
    };

    this.checkCompleted(event);
  }

  checkBuilding() {
    const valid = this.requiredBuilding.exists;

    const event = {
      action: EVENTS.GUI.REQUIREMENTS.BUILDING,
      value: {
        valid,
        text: `${this.requiredBuilding.name} (${this.requiredBuilding.level})`
      }
    };

    this.checkCompleted(event);
  }

  checkGroupLimit() {
    const event = {
      action: EVENTS.GUI.REQUIREMENTS.GROUP_LIMIT,
      value: {
        valid: null,
      }
    };

    if (this.groupLimit instanceof AbstractGroupCountComponent) {
      this.subs.groupCount = this.groupLimit.event$
        .pipe(
          filter((value) => value !== null),
        )
        .subscribe((next: { valid: boolean, message: string }) => {
          event.value = <any>next;

          if (event.value.valid !== null) {
            this.checkCompleted(event);
          }
        });
    }
  }

  checkStar() {
    this.star.star_id = getCorrectlyStarId(this.star.star_id);
    const valid = this.player.star.star_id >= this.star.star_id;

    const event = {
      action: 'star',
      value: {
        valid,
        text: translate('requirements.star', this.star),
      }
    };

    this.checkCompleted(event);
  }

  checkCompleted(event) {
    this.waitingList[event.action].progress = false;
    this.waitingList[event.action].value = event.value;
    this.checkEnd();
  }

  checkEnd() {
    const countNotFinished = Object.keys(this.waitingList).filter((key) => {
      return this.waitingList[key].progress === true;
    });

    if (countNotFinished.length === 0) {
      setTimeout(() => {
        this.createTextsList();
        this.emitStatus();
      });
    }
  }

  createTextsList() {
    this.textsList = [];

    Object.keys(this.waitingList).forEach((key) => {
      if (this.waitingList[key].value && this.waitingList[key].value.text) {
        this.textsList.push(this.waitingList[key].value);
      }
    });
  }

  emitStatus() {
    const valid = Object.keys(this.waitingList).filter((key) => {
      return this.waitingList[key].value.valid === false;
    }).length === 0;

    this.requirementsStatus.emit({
      valid: valid,
      requirementsList: this.waitingList,
    });
  }
}
