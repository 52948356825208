import { Component, OnInit } from '@angular/core';
import { AbstractTileBuyComponent } from '../../../../abstract/core/abstract-tile-buy.component';

@Component({
  selector: 'app-m-tile-buy',
  templateUrl: './tile-buy.component.html',
  styleUrls: ['./tile-buy.component.scss']
})
export class MTileBuyComponent extends AbstractTileBuyComponent implements OnInit {
  ngOnInit() {
    this.checkTileBuy();
  }
}
