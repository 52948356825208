<ng-container *ngIf="socketState" [ngSwitch]="socketState.socketStatus">
  <!-- CONNECTING TO SERVER -->
  <ng-container *ngSwitchCase="SOCKET_STATUS.CONNECT">
    <p class="message-top">
      {{ 'socket-messages.connecting-to-server' | myTranslate }}
    </p>
  </ng-container>

  <!-- SOCKET ERROR -->
  <ng-container *ngSwitchCase="SOCKET_STATUS.CONNECTION_ERROR">
    <div class="background"></div>
    <p class="message">
      {{ 'socket-messages.connecting-to-server-failed' | myTranslate:eventData }}
    </p>
  </ng-container>

  <!-- SOCKET DISCONNECTED -->
  <ng-container *ngSwitchCase="SOCKET_STATUS.DISCONNECTED">
    <div class="background"></div>
    <p class="message">
      {{ 'socket-messages.connecting-to-server-terminated' | myTranslate }}
    </p>
  </ng-container>

  <!-- OTHER SESSION -->
  <ng-container *ngSwitchCase="SOCKET_STATUS.OTHER_SESSION">
    <div class="background"></div>
    <p class="message">
      {{ 'socket-messages.multiple-instance' | myTranslate }}
    </p>
    <button class="base primary text-uppercase" (click)="reloadApp()">
      {{ 'socket-messages.button-reload-game' | myTranslate }}
    </button>
    <button class="base primary text-uppercase" (click)="logout()">
      {{ 'global.logout' | myTranslate }}
    </button>
  </ng-container>

  <!-- GAME VERSION -->
  <ng-container *ngSwitchCase="SOCKET_STATUS.DIFFERENT_VERSION_GAME">
    <div class="background"></div>
    <p class="message">{{ 'socket-messages.game-version-changed' | myTranslate }}</p>
    <button class="base primary text-uppercase" (click)="reloadApp()">
      {{ 'socket-messages.button-reload-game' | myTranslate }}
    </button>
  </ng-container>
</ng-container>
