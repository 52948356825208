import { Injectable, Input } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { PlayerService } from '../../../../../player/providers/player.service';
import { Player } from '../../../../../player/interfaces/player';
import { EVENT_DIALOGS_NAMES_HUD } from '../../const/core/event-dialogs/event-names.const';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { FEATURE_FLAG } from '../../../../../../core/config/core/feature-flags.config';

@Injectable()
export abstract class AbstractHudPlayerComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(PlayerService) playerService: PlayerService;
  FEATURE_FLAG = FEATURE_FLAG;
  @Input() changeProfileImage = false;
  @Input() allowHover = true;
  @Input() player: Player;
  strokeDashOffset: number;
  valuePerOnePercent = 4.66;

  openPlayerDetails() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_HUD.PLAYER_DETAILS,
    });
  }

  setProfileImage(file) {
    this.playerService.setProfileImage(file)
      .subscribe((resp) => {

      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }

  calcPlayerLevelProgress() {
    let strokeDashOffset = 0;
    const levelPercent = this.player.level_percent;
    if (Number.isInteger(levelPercent)) {
      strokeDashOffset = 578 - (levelPercent * this.valuePerOnePercent);
    }
    this.strokeDashOffset = strokeDashOffset;
  }

  changeImage(event: EventTarget) {
    const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    const files: FileList = target.files;
    const file = files[0];
    if (file) {
      this.setProfileImage(file);
    }
  }
}
