import { Component, OnInit } from '@angular/core';
import { AbstractBuildingAllowProductionComponent } from '../../../../abstract/core/abstract-building-allow-production.component';

@Component({
  selector: 'm-building-allow-production',
  templateUrl: './building-allow-production.component.html',
  styleUrls: ['./building-allow-production.component.scss']
})
export class MBuildingAllowProductionComponent extends AbstractBuildingAllowProductionComponent implements OnInit {
  ngOnInit() {
    this.getBuildingProduction();
  }
}
