<!-- MISSIONS -->
<ng-container *ngIf="data.player as player">
  <div
    class="player-info-container"
    *ngIf="!playerService.isActiveMe"
  >
    <button
      class="square small primary prev"
      (click)="previewPlayerService.changePlayer()"
    >
      <i class="fas fa-angle-left"></i>
    </button>

    {{ playerService.player.first_name }} {{ playerService.player.last_name }}
  </div>

  <button
    *ngIf="playerService.isActiveMe"
    class="wipe missions"
    (click)="openMissions()"
    [matTooltip]="'hud-menu.missions.tooltip' | myTranslate"
    matTooltipPosition="right"
    matTooltipShowDelay="300"
  >
    <ow-object-svg [svgObject]="OW_ASSETS_SVG['/assets/ui/mission.svg']"></ow-object-svg>
    <!-- NOTIFICATION -->
    <ng-container
      *ngIf="hasMissionsToCollect | async"
      [ngTemplateOutlet]="notificationTpl"
    ></ng-container>
  </button>

  <!-- MESSAGES -->
  <button
    *ngIf="playerService.isActiveMe"
    class="wipe messages"
    (click)="openMessages()"
    [matTooltip]="'hud-menu.messages.tooltip' | myTranslate"
    matTooltipPosition="right"
    matTooltipShowDelay="300"
  >
    <ow-object-svg [svgObject]="OW_ASSETS_SVG['/assets/ui/message.svg']"></ow-object-svg>
    <!-- NOTIFICATION -->
    <ng-container
      *ngIf="hasNewMessagesToRead | async"
      [ngTemplateOutlet]="notificationTpl"
    ></ng-container>
  </button>

  <!-- WAREHOUSE -->
  <button
    class="wipe warehouse"
    (click)="openWarehouse()"
    [matTooltip]="'hud-menu.warehouse.tooltip' | myTranslate"
    matTooltipPosition="right"
    matTooltipShowDelay="300"
  >
    <ow-object-svg [svgObject]="OW_ASSETS_SVG['/assets/ui/warehouse.svg']"></ow-object-svg>
    <!-- NOTIFICATION -->
    <ng-container
      *ngIf="(newProductsInStorage | async).length > 0"
      [ngTemplateOutlet]="notificationTpl"
    ></ng-container>
  </button>
</ng-container>

<ng-template #notificationTpl>
  <i class="fas fa-exclamation icon notification"></i>
</ng-template>
