import { EventDialog } from '../../../../../../../core/interfaces/event-dialog.interface';
import { MQaFinishComponent } from '../../../base/core/dialogs/qa-finish/qa-finish.component';
import { EVENT_DIALOGS_NAMES_QA } from './event-names.const';
import { MQaDetailsComponent } from '../../../base/core/dialogs/qa-details/qa-details.component';
import { MQaListComponent } from '../../../base/core/dialogs/qa-list/qa-list.component';

export const EVENT_DIALOGS_QA: EventDialog = {
  [EVENT_DIALOGS_NAMES_QA.QA_LIST]: MQaListComponent,
  [EVENT_DIALOGS_NAMES_QA.QA_DETAILS]: MQaDetailsComponent,
  [EVENT_DIALOGS_NAMES_QA.QA_FINISH]: MQaFinishComponent,
};
