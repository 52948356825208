import { Component, HostBinding } from '@angular/core';
import { GuiService } from '../../../game/services/gui.service';
import { GameService } from '../../../game/services/game.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent {
  @HostBinding('class.active') isShow: boolean;

  constructor(
    public guiService: GuiService,
    public gameService: GameService,
  ) {
    this.guiService.isSplashShow.subscribe(value => this.isShow = value);
  }
}
