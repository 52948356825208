import { COLOR_BASE, PHASER_CAMERA_ZOOM, TILE_TOOLTIP_DEPTH, TILE_TOOLTIP_UPGRADE_TEXT_STYLES, TileTooltipType } from '../../../constants';
import { BoardTile } from '../custom/BoardTile.class';
import { GameService } from '../../../services/game.service';
import { isoToScreen, keepScale1 } from '../../utils/utils';
import { getTileTooltipPositionOffset } from '../../utils/board.helper';
import { isAssetLoadedToPhaserCache } from '../../utils/game.helper';
import { MyScene } from './MyScene';
import { isAutoproduction } from '../../../game-gui/helpers/buildings.helper';
import { TILE_MENU_ATLAS } from '../../scenes-main/main.constants';
import { TileTooltipConfig } from '../../interfaces/TileTooltipConfig.interface';
import { customTileTooltipConfig } from '../../config/custom-tile-tooltip.config';
import { timer } from 'rxjs';
import * as moment from 'moment';
import { Moment } from 'moment';

export class TileTooltipCore extends Phaser.GameObjects.Container {

  boardTile: BoardTile;
  tooltipImage: Phaser.GameObjects.Sprite;
  animationTween;
  tooltipType: TileTooltipType;
  destroying: boolean;

  counter: TooltipCounter;

  gameService: GameService;
  noTween: boolean;

  scaleFactor = 0.5;
  maxZoomScaleValue = 5;

  collectValueText: Phaser.GameObjects.Text;

  upgradeTextStyles: Phaser.Types.GameObjects.Text.TextStyle;
  baseColor: string;

  tileTooltipConfig: TileTooltipConfig;

  constructor(scene: MyScene, tile: BoardTile, type: TileTooltipType) {
    super(scene, 0, 0);

    this.beforeCreate();

    this.setTextStylesConfig(customTileTooltipConfig);

    this.gameService = scene.gameService;
    this.boardTile = tile;
    this.scene.add.existing(this);
    this.setDepth(TILE_TOOLTIP_DEPTH);
    this.setType(type);

    this.listenCameraZoom();
  }

  listenCameraZoom() {
    keepScale1(this, this.scene.cameras.main.zoom, this.maxZoomScaleValue);
    (this.scene as MyScene).phaserEvents.on(PHASER_CAMERA_ZOOM, (zoom: number) => {
      keepScale1(this, zoom, this.maxZoomScaleValue);
    });
  }

  setTextStylesConfig(config?: TileTooltipConfig) {
    this.baseColor = COLOR_BASE;
    this.upgradeTextStyles = TILE_TOOLTIP_UPGRADE_TEXT_STYLES;

    if (!config) {
      return;
    }

    this.tileTooltipConfig = config;
    if (config.upgradeTextStyles) {
      this.upgradeTextStyles = config.upgradeTextStyles;
    }
    if (config.baseColor) {
      this.baseColor = config.baseColor;
    }
  }

  beforeCreate() {
  }

  setType(type: TileTooltipType) {
    this.tooltipType = type;
    if (!this.boardTile.isBuildInProgress() && (this.isProductionInProgress() || this.isProductionFinished() || this.isAutoProduction()) || this.boardTile.hasMission()) {
      const bg = this.scene.add.image(0, 0, TILE_MENU_ATLAS, 'take.png');
      bg.setOrigin(0.5);
      bg.setScale(this.scaleFactor);
      this.add(bg);
      this.tooltipImage = this.scene.add.sprite(0, 0, TILE_MENU_ATLAS, 'production-spiner.png');
      this.tooltipImage.setScale(this.scaleFactor);
      this.tooltipImage.setOrigin(0.5, 0.5);

      let icon = null;
      if (this.boardTile.hasMission()) {
        icon = this.gameService.assetsService.getAsset(
          `missions/board-main/${this.boardTile.playerBuildingData.player_mission.icon}.png`
        );
        this.setJumpAnim([this.tooltipImage, bg]);
        if (icon) {
          this.setTooltipTexture(icon.path);
        }

        if (this.boardTile.tileData.player_building.player_mission.expires_at) {
          const missionTime = this.scene.add.text(0, 10, '00:00:00', {
            ...this.upgradeTextStyles,
          });
          missionTime.setShadow(0, 2, 'rgba(0, 0, 0, 1)', 3, true, true);
          missionTime.setOrigin(0.5, -1);

          this.counter = {
            percent: 0,
            intervalRef: null,
            stringTime: '',
            startTime: this.gameService.synchronizeTimeService.getActualLocalTime().getTime(),
            finishTime: moment(this.boardTile.tileData.player_building.player_mission.expires_at),
          };
          this.setTimer(missionTime);
          this.add(missionTime);
        }

      } else if (this.isProductionFinished()) {
        const currencyPrize = this.boardTile.tileData.production.currency_prizes[0];
        const productPrize = this.boardTile.tileData.production.product_prizes[0];

        let iconKey;
        if (currencyPrize) {
          const currency = this.gameService.currencyService.getCurrencyDefinition(currencyPrize);
          iconKey = currency.key;
          icon = currency.iconUrlBig;
        } else if (productPrize) {
          iconKey = productPrize.icon + '-small';
          const product = this.gameService.productionService.productsService.getProduct(productPrize);
          icon = product.iconUrl;
        }

        this.setTooltipTexture(icon, iconKey);
        this.setJumpAnim([this.tooltipImage, bg]);
      } else if (this.isAutoProduction()) {
        const currencyPrize = this.boardTile.tileData.player_building.automatic_currency;
        const productPrize = this.boardTile.tileData.player_building.automatic_product;
        let iconKey;
        if (currencyPrize) {
          const currency = this.gameService.currencyService.getCurrencyDefinition(currencyPrize);
          iconKey = currency.key;
          icon = currency.iconUrlBig;
        } else if (productPrize) {
          iconKey = productPrize.icon + '-small';
          const product = this.gameService.productionService.productsService.getProduct(productPrize);
          icon = product.iconUrl;
        }

        this.setTooltipTexture(icon, iconKey);
        this.setJumpAnim([this.tooltipImage, bg]);
      } else {
        // this.noTween = true;
        // this.tooltipImage.y = -20;
        this.setRotationAnim(this.tooltipImage);
      }
    } else {

      /**
       * Refactored to allow collect auto production while building build is in progress.
       * This change is made just after another change, that checked if tile should be update silent (without re-rendering),
       * so it's a little mess and redundant now. @todo: need to refactor/rewrite all Tooltip logic. Maybe allow multiple tooltips?
       */
      let icon = null;
      if (this.isAutoProduction() && this.boardTile.playerBuildingData.auto_production_amount > 0) {

        const bg = this.scene.add.image(0, 0, TILE_MENU_ATLAS, 'take.png');
        bg.setOrigin(0.5);
        bg.setScale(this.scaleFactor);
        this.add(bg);
        this.tooltipImage = this.scene.add.sprite(0, 0, TILE_MENU_ATLAS, 'production-spiner.png');
        this.tooltipImage.setScale(this.scaleFactor);
        this.tooltipImage.setOrigin(0.5, 0.5);

        const currencyPrize = this.boardTile.tileData.player_building.automatic_currency;
        const productPrize = this.boardTile.tileData.player_building.automatic_product;
        if (currencyPrize) {
          const currency = this.gameService.currencyService.getCurrencyDefinition(currencyPrize);
          icon = currency.iconUrlBig;
        } else if (productPrize) {
          const product = this.gameService.productionService.productsService.getProduct(productPrize);
          icon = product.iconUrl;
        }
        this.type = TileTooltipType.COLLECT;
        this.setTooltipTexture(icon);
        this.setJumpAnim([this.tooltipImage, bg]);
      } else {
        this.tooltipImage = this.scene.add.sprite(0, 0, TILE_MENU_ATLAS, `tooltip-${type}.png`);
        this.tooltipImage.setScale(this.scaleFactor);
        this.setLevitationAnim(this.tooltipImage);
      }
    }

    this.add(this.tooltipImage);
    const screenPos = isoToScreen(this.boardTile.isoX, this.boardTile.isoY);
    const tooltipOffset = getTileTooltipPositionOffset(this.boardTile.tileData.tile_type);
    this.x = this.boardTile.x + tooltipOffset.x;
    this.y = this.boardTile.y + tooltipOffset.y;

    this.handleTooltipExtensions();
    this.afterCreate();
  }

  setTooltipTexture(tileImage: string, iconFrame?: string) {
    const atlas = this.scene.cache.json.get('icons-atlas');
    if (atlas && iconFrame && atlas.frames.hasOwnProperty(`${iconFrame}.png`)) {
      this.tooltipImage.setTexture('icons-atlas', `${iconFrame}.png`);
    } else if (isAssetLoadedToPhaserCache(tileImage, this.scene.game.textures.getTextureKeys())) {
      if (!this.destroying) {
        this.tooltipImage.setTexture(tileImage);
      }
    } else {
      this.gameService.loadGameImages(this.scene as MyScene, [tileImage])
        .subscribe(() => {
          // Timeout fix to wait till Phaser prepare texture from image.
          setTimeout(() => {
            if (!this.destroying) {
              this.tooltipImage.setTexture(tileImage);
            }
          }, 500);
        });
    }
  }

  handleTooltipExtensions() {
    switch (this.tooltipType) {

      case TileTooltipType.UPGRADE:
        this.counter = {
          percent: 0,
          intervalRef: null,
          stringTime: '',
          startTime: this.gameService.synchronizeTimeService.getActualLocalTime().getTime(),
          finishTime: moment(this.boardTile.tileData.player_building.built_at),
        };

        const buildTime = this.scene.add.text(0, 10, '', {
          ...this.upgradeTextStyles,
        });
        buildTime.setShadow(0, 2, 'rgba(0, 0, 0, 1)', 3, true, true);
        buildTime.setOrigin(0.5, -1);
        this.setTimer(buildTime);
        this.add(buildTime);
        break;

      case TileTooltipType.PRODUCTION:
        this.counter = {
          percent: 0,
          intervalRef: null,
          stringTime: '',
          startTime: new Date(this.boardTile.tileData.production.created_at).getTime(),
          finishTime: moment(this.boardTile.tileData.production.finishes_at),
        };
        const productionTime = this.scene.add.text(0, 10, '', {
          ...this.upgradeTextStyles,
          fill: this.baseColor,
        });
        productionTime.setShadow(0, 2, 'rgba(0, 0, 0, 0.75)', 3, true, true);
        productionTime.setOrigin(0.5, -1);
        this.setTimer(productionTime);
        this.add(productionTime);
        break;

      case TileTooltipType.PRODUCTION_FINISHED:
        break;

      case TileTooltipType.COLLECT:
        const collectValue = this.scene.add.text(0, 10, `${this.boardTile.playerBuildingData.auto_production_amount}`, {
          ...this.upgradeTextStyles,
          fill: this.baseColor,
        });
        collectValue.setShadow(0, 2, 'rgba(0, 0, 0, 0.75)', 3, true, true);
        collectValue.setOrigin(0.5, -1);
        this.add(collectValue);
        this.collectValueText = collectValue;
        break;
    }
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  isProductionInProgress() {
    return this.tooltipType === TileTooltipType.PRODUCTION;
  }

  isProductionFinished() {
    return this.tooltipType === TileTooltipType.PRODUCTION_FINISHED;
  }

  setLevitationAnim(target: Phaser.GameObjects.GameObject) {
    this.scene.add.tween({
      targets: [target],
      repeat: -1,
      yoyo: true,
      duration: 400,
      y: -20,
      ease: Phaser.Math.Easing.Quadratic.InOut
    });
  }

  setJumpAnim(target: Phaser.GameObjects.GameObject[]) {
    this.scene.add.tween({
      targets: target,
      repeat: -1,
      yoyo: true,
      duration: 400,
      y: -20,
      ease: Phaser.Math.Easing.Quadratic.Out
    });
  }

  setRotationAnim(target: Phaser.GameObjects.GameObject) {
    this.scene.add.tween({
      targets: [target],
      repeat: -1,
      duration: 1000,
      angle: 360,
      ease: Phaser.Math.Easing.Quadratic.InOut
    });
  }

  stopAnimation() {
    this.animationTween && this.animationTween.stop(true);
  }

  niceDestroy() {
    this.destroying = true;
    this.stopAnimation();
    this.destroy();
    // this.game.add.tween(this).to({y: -1000, alpha: 0}, 1000, Quadratic.In, true);
    // this.game.add.tween(this.scale).to({x: 2, y: 2}, 1000, Quadratic.In, true);
    // const tween = this.game.add.tween(this.scale).to({x: 3, y: 3}, 1000, Quadratic.In, true);
    // tween.onComplete.add(() => {
    //   this.destroy();
    // });
  }

  destroy(fromScene?: boolean) {
    if (this.counter) {
      clearInterval(this.counter.intervalRef);
    }
    super.destroy(fromScene);
  }

  isType(tooltipType: TileTooltipType) {
    return tooltipType === this.tooltipType;
  }

  protected isAutoProduction() {
    return isAutoproduction(this.boardTile.playerBuildingData.group_type);
  }

  refreshAutoproductionValue(value: number) {
    this.collectValueText.setText(`${value}`);
  }

  afterCreate() {
  }

  setTimer(counter: Phaser.GameObjects.Text) {
    const timer$ = timer(0, 450).subscribe(res => {
      if (!this.destroying && this.scene) {
        const duration = moment.duration(
          this.counter.finishTime.diff(moment(this.gameService.synchronizeTimeService.getActualLocalTimeWithOffset()))
        ).asSeconds();
        const time = this.gameService.hoursPipe.transform(duration, 'seconds');
        counter.setText(`${time}`);
        if (duration <= 0) {
          timer$.unsubscribe();
        }
      } else {
        timer$.unsubscribe();
      }
    });
  }
}

export interface TooltipCounter {
  secondsLeft?: number;
  intervalRef: any;
  stringTime: string;
  percent?: number;
  startTime?: number;
  finishTime?: Moment;
  secondsTotal?: number;
}
