import { EventEmitter, Injectable, Input, Output } from '@angular/core';
import { OwInject } from '../../../../../core/decorators/ow-inject.decorator';
import { MatDialogRef } from '@angular/material/dialog';
import { AbstractInjectBaseComponent } from '../../../../../core/abstracts/abstract-inject-base.component';
import { CONFIG_BACK_BUTTON } from '../config/core/back-button.config';

@Injectable()
export abstract class AbstractBackButtonComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<any>;
  @Input() preventDefault: boolean;
  @Input() buttonLayout = CONFIG_BACK_BUTTON.BUTTON_LAYOUT;
  @Input() buttonSize = CONFIG_BACK_BUTTON.BUTTON_SIZE;
  @Input() buttonColor = CONFIG_BACK_BUTTON.BUTTON_COLOR;
  @Input() otherClass = CONFIG_BACK_BUTTON.OTHER_CLASS;
  @Input() closeValue: any;
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  click() {
    this.clicked.emit(true);

    if (!this.preventDefault) {
      this.matDialogRef.close(this.closeValue);
    }
  }
}
