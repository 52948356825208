import { AfterViewInit, Component, OnChanges, OnInit } from '@angular/core';
import { AbstractQaSummaryComponent } from '../../../../abstract/core/abstract-qa-summary.component';

@Component({
  selector: 'app-m-qa-summary',
  templateUrl: './qa-summary.component.html',
  styleUrls: ['./qa-summary.component.scss']
})
export class MQaSummaryComponent extends AbstractQaSummaryComponent implements OnInit, OnChanges, AfterViewInit {

  ngOnInit(): void {
    this.afterQa();
  }

  ngAfterViewInit() {
    this.checkAnyElScoreAndPrizes();
  }

  ngOnChanges() {
    this.afterQa();
    this.checkAnyElScoreAndPrizes();
  }
}
