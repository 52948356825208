import { Injectable } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BuildingMoveData } from '../../interfaces/core/dialogs/building-move-data.interface';
import { select, Store } from '@ngrx/store';
import { selectPrimary } from '../../../../../../store/primary/selectors';
import { translate } from '../../../../../../core/helpers/translate.helper';
import { AppState } from '../../../../../../store/state';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { GameService } from '../../../../services/game.service';
import { MyGame } from '../../../../game-engine/classes/core/MyGame';
import { CurrencyBalance } from '../../../../../../core/interfaces/currency';
import { take } from 'rxjs/operators';
import { ProductBalance } from '../../../../../player/interfaces/product.interface';

@Injectable()
export abstract class AbstractBuildingMoveComponent extends AbstractInjectBaseComponent {
  @OwInject(MAT_DIALOG_DATA) data: BuildingMoveData;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(GameService) gameService: GameService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractBuildingMoveComponent>;

  subs = {
    primary: null,
  };
  moveBuildingCurrencyPrice: CurrencyBalance;
  moveBuildingProductPrice: ProductBalance;

  subscribePrimary() {
    this.subs.primary = this.store
      .pipe(
        select(selectPrimary),
        take(1),
      )
      .subscribe((setupData) => {
        this.moveBuildingCurrencyPrice = setupData.move_building_currency_price;
        this.moveBuildingProductPrice = setupData.move_building_product_price;
        this.openDialogConfirm();
      });
  }

  confirmBuildingMove() {
    (this.gameService.game as MyGame).currentScene.board.startBuildingMoveMode(this.data.tileId);
  }

  openDialogConfirm() {
    this.dialogService.openConfirm({
      title: translate('building-move.title'),
      description: translate('building-move.description'),
      costs: {
        separatorTitle: translate('building-move.separator-title'),
        currencies: [this.moveBuildingCurrencyPrice].filter(Boolean),
        products: [this.moveBuildingProductPrice].filter(Boolean),
      }
    }, (confirm) => {
      if (confirm) {
        this.confirmBuildingMove();
      }

      this.close();
    });
  }

  close() {
    this.matDialogRef.close();
  }
}
