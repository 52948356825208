import { Injectable } from '@angular/core';
import { BaseCurrency, Currency, CurrencyBalance, CurrencyBalanceDetails, CurrencyDefinition } from '../interfaces/currency';
import { clone } from 'ramda';
import { AssetsService } from './assets.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  currencyDefinitions: CurrencyDefinition[] = [];

  constructor(
    private assetsService: AssetsService,
  ) {
  }

  setCurrencyDefinitions(currencies: CurrencyDefinition[]) {
    this.currencyDefinitions = currencies;
  }

  getCurrency(currencies: CurrencyDefinition[], currencyKey: string) {
    let currency;

    if (Array.isArray(currencies)) {
      currency = currencies.find((_currency) => {
        return _currency.key === currencyKey;
      });
    }

    return clone(currency);
  }

  getCurrencyDefinition(currency: BaseCurrency): CurrencyDefinition {
    const searchCurrencyDefinition = this.currencyDefinitions.find((item) => {
      return item.currency_id === currency.currency_id;
    });

    return {
      ...currency,
      ...searchCurrencyDefinition,
      ...this.setImage({...searchCurrencyDefinition}),
    };
  }

  getCurrencyBalance(currency: Currency, playerCurrencyBalances: CurrencyBalanceDetails[]): CurrencyBalance {
    let searchCurrencyBalance = playerCurrencyBalances
      .find(playerCurrencyBalance => playerCurrencyBalance.currency_id === currency.currency_id);

    // @todo: store definitions player balances
    if (!searchCurrencyBalance) {
      searchCurrencyBalance = this.getCurrencyDefinition(currency) as CurrencyBalanceDetails;
      searchCurrencyBalance.balance = 0;
    }

    return {
      ...currency,
      ...searchCurrencyBalance,
      balance: searchCurrencyBalance.balance,
      have: currency.amount <= (searchCurrencyBalance ? searchCurrencyBalance.balance : 0)
    };
  }

  getCurrencyDefinitions(currencies: BaseCurrency[]) {
    if (Array.isArray(currencies)) {
      currencies = currencies.map((currency) => {
        return this.getCurrencyDefinition(currency);
      });
    }

    return currencies;
  }

  getCurrencyDefinitionByKey(currencyKey: string): CurrencyDefinition {
    return this.getCurrency(this.currencyDefinitions, currencyKey);
  }

  setImage(currency: CurrencyDefinition): CurrencyDefinition {
    if (currency) {
      const copy = {...currency};

      copy.iconUrlBig = this.assetsService.getAssetPath(`currency/big/${currency.key}.png`);
      copy.iconUrl = this.assetsService.getAssetPath(`currency/basic/${currency.key}.png`);

      return copy;
    }

    return currency;
  }
}
