import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../core/providers/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiChestService {
  constructor(
    private apiService: ApiService,
  ) {
  }

  playerChests() {
    return this.apiService.get(`/player-chests`);
  }

  playerChestsId(id: number) {
    return this.apiService.get(`/player-chests/${id}`);
  }

  playerChestsIdClaim(id: number) {
    return this.apiService.patch(`/player-chests/${id}/claim`);
  }

  playerChestsIdOpen(id: number) {
    return this.apiService.patch(`/player-chests/${id}/open`);
  }

  playerChestsIdOpenAndClaim(id: number) {
    return this.apiService.patch(`/player-chests/${id}/open-and-claim`);
  }
}
