import { IsoLayerConfig, IsoMapLayer } from './IsoMapLayer';
import { findAtlasNameByAssetName, getTilemapAssets } from '../../utils/utils';
import { MyScene } from './MyScene';

import {
  ISO_LAYER_TYPE_OBJECT,
  ISO_LAYER_TYPE_TILE,
  LOADED_TILEMAP_DIMENSIONS,
  TILE_HEIGHT,
  TILE_WIDTH
} from '../../../constants/iso.constants';
import { TILE_MAP_WIDTH } from '../../../constants';
import { showOrHideByBuilding } from '../../utils/board.helper';

export class IsoMap {
  layers: IsoMapLayer[] = [];
  scene: Phaser.Scene;
  hideByBuildingObjects = [];
  showByBuildingObjects = [];
  dimensions: {
    width: number,
    height: number
  };

  constructor(params: IsoMapConfig) {
    this.scene = params.scene;
    if (params && params.layersData) {
      params.layersData.forEach((layer) => {
        this.createLayerFromData(layer);
      });
    }
  }

  /**
   * Create iso map layer from layer config.
   * @param layerData
   */
  createLayerFromData(layerData: IsoLayerConfig): IsoMapLayer {
    const layer = new IsoMapLayer(layerData);
    this.layers.push(layer);
    return layer;
  }

  /**
   * Get map data from Phaser cache.
   * @param key
   */
  loadData(key: string): any {
    return this.scene.cache.json.get(key);
  }

  /**
   * Create iso map layer from cached Tiled json.
   * @param mapDataKey
   */
  createFromData(mapDataKey: string) {
    const mapData = this.loadData(mapDataKey);
    const assets = getTilemapAssets(mapData);
    const atlases = (this.scene as MyScene).gameService.getAtlasFromCache();

    // @todo: think about how to make this better :)
    LOADED_TILEMAP_DIMENSIONS.width = mapData.width;
    LOADED_TILEMAP_DIMENSIONS.height = mapData.height;

    this.dimensions = {
      width: mapData.width * TILE_WIDTH,
      height: mapData.height * TILE_HEIGHT,
    };

    // Loop layers data
    let depthIndex = 0;
    mapData
      .layers
      .filter(layer => layer.visible)
      .forEach((layer: any) => {
          const objects = [];

          switch (layer.type) {

            case ISO_LAYER_TYPE_OBJECT:
              layer
                .objects
                .forEach((elem: any) => {

                  let hideByBuilding;
                  let showByBuilding;
                  let showBuildingGroups;
                  let hideBuildingGroups;

                  // Do not process object if has ignore property set
                  if (elem.properties) {
                    const ignore = elem.properties.find(property => property.name === 'ignore' && property.value);
                    if (ignore) {
                      return;
                    }

                    hideByBuilding = elem.properties.find(property => property.name === 'hide_by_building');
                    if (hideByBuilding) {
                      hideBuildingGroups = showOrHideByBuilding(hideByBuilding.value, hideBuildingGroups);
                    }

                    showByBuilding = elem.properties.find(property => property.name === 'show_by_building');
                    if (showByBuilding) {
                      showBuildingGroups = showOrHideByBuilding(showByBuilding.value, showBuildingGroups);
                    }
                  }

                  // Show error and skip processing object if used asset not found
                  if (!assets.tilesArray[elem.gid]) {
                    // console.error(`No tilemap asset for`, elem);
                    return;
                  }

                  const objectConfig = {
                      x: elem.x / TILE_HEIGHT,
                      y: elem.y / TILE_HEIGHT,
                      name: assets.tilesArray[elem.gid].image,
                      atlas: findAtlasNameByAssetName(assets.tilesArray[elem.gid].image, atlases),
                      properties: {
                        showByBuilding: showBuildingGroups,
                        hideByBuilding: hideBuildingGroups
                      }
                    }
                  ;

                  objects.push(objectConfig);
                });

              const isoTileLayer = this.createLayerFromData({
                scene: this.scene,
                depthIndex,
                objects: objects,
                name: layer.name,
              });
              isoTileLayer.children.entries.forEach(object => {
                const properties = object.getData('properties');
                if (properties.showByBuilding) {
                  object['alpha'] = 0;
                  this.showByBuildingObjects.push(object);
                } else if (properties.hideByBuilding) {
                  this.hideByBuildingObjects.push(object);
                }
              });
              break;

            case ISO_LAYER_TYPE_TILE:
              const layerData = layer.data;
              const tilesCount = layerData.length;

              let tileData;
              let tileOffset;

              for (let t = 0; t < tilesCount; t++) {
                const xt = t % LOADED_TILEMAP_DIMENSIONS.width;
                const yt = Math.floor(t / LOADED_TILEMAP_DIMENSIONS.height);

                tileData = layerData[t];
                tileOffset = assets.tilesOffsets[tileData.toString()];

                if (tileData && assets.tilesArray[tileData]) {
                  const objectConfig = {
                    x: xt * TILE_MAP_WIDTH,
                    y: yt * TILE_MAP_WIDTH,
                    name: assets.tilesArray[tileData].image,
                    atlas: findAtlasNameByAssetName(assets.tilesArray[tileData].image, atlases),
                    offset: assets.tilesOffsets[tileData] ? assets.tilesOffsets[tileData] : {x: 0, y: 0}
                  };
                  objects.push(objectConfig);
                }
              }

              const isoLayer = this.createLayerFromData({
                scene: this.scene,
                depthIndex,
                objects,
                isTileLayer: true,
                name: layer.name
              });

              break;
          }

          depthIndex += 2;
        }
      );
  }

  getLayerByName(layerName: string): IsoMapLayer {
    return this.layers.find(layer => layer.name === layerName);
  }
}

export interface IsoMapConfig {
  layersData?: IsoLayerConfig[];
  scene: Phaser.Scene;
}

export const createAssetsObject = function (tilesets: any): any {

  const assets: any = {};
  Object.entries(tilesets).forEach((element: any, index) => {
    Object.entries(element[1].tiles).forEach((elem: any) => {
      assets[`key${(Number(elem[0]) + element[1].firstgid)}`] = elem[1].image;
    });
  });

  return assets;
};
