import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { MRankListComponent } from './dialogs/rank-list/rank-list.component';
import { SharedUiMobileModule } from '../../shared-ui/mobile/shared-ui-mobile.module';
import { RanksAbstractModule } from '../abstract/ranks-abstract.module';
import { MRankDetailsGameComponent } from './dialogs/rank-details-game/rank-details-game.component';
import { MRankFiltersComponent } from './components/rank-filters/rank-filters.component';
import { MRankDetailsBusinessComponent } from './dialogs/rank-details-business/rank-details-business.component';

@NgModule({
  declarations: [
    MRankListComponent,
    MRankDetailsGameComponent,
    MRankFiltersComponent,
    MRankDetailsBusinessComponent,
  ],
  entryComponents: [
    MRankListComponent,
    MRankDetailsGameComponent,
  ],
  imports: [
    CommonModule,
    SwiperModule,
    SharedUiMobileModule,
    RanksAbstractModule,
  ],
})
export class RanksMobileModule {
}
