<ng-container *ngIf="player">
  <div class="player-bar-container">
    <!-- HUD GAME DATE-->
    <m-ui-game-date
      *ngIf="FEATURE_FLAG.GAME_DATE"
      [date]="'hud.game-date' | myTranslate:{playerStar: player.star}"
    ></m-ui-game-date>

    <!-- AVATAR DEFAULT-->
    <div
      class="avatar default"
      *ngIf="!player.profile_image_url"
    ></div>

    <!-- AVATAR -->
    <div
      class="avatar"
      *ngIf="player.profile_image_url"
      [style.background-image]="'url('+player.profile_image_url+')'"
    >
    </div>

    <div
      class="user-options flex-center"
      (click)="playerService.isActiveMe && openPlayerDetails()"
      *ngIf="allowHover"
    >
      <i class="far fa-ellipsis-h"></i>
    </div>

    <div class="user-options flex-center" *ngIf="changeProfileImage">
      <i class="far fa-image"></i>
      <input
        accept="image/x-png, image/gif, image/jpeg"
        type="file"
        (change)="changeImage($event)"
      >
    </div>

    <!-- LEVEL STAR -->
    <div
      class="level-star flex-center flex-center"
      [matTooltip]="'hud-player.level-tooltip' | myTranslate:[player.points_balance | NumberWithSpaces, player.next_level_points | NumberWithSpaces]"
      matTooltipPosition="right"
      matTooltipShowDelay="300"
    >
      {{ player.level }}
    </div>
  </div>

  <!-- CIRCLE LEVEL -->
  <svg id="circle" width="56" height="56" viewBox="0 0 200 200">
    <circle
      cx="100"
      cy="100"
      fill="transparent"
      id="bar"
      r="84"
      stroke-dasharray="578"
      transform="rotate(22 100,100)"
      [style.strokeDashoffset]="strokeDashOffset"
    ></circle>
  </svg>
</ng-container>
