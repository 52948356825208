import { Injectable } from '@angular/core';
import { AbstractEventDialogsService } from '../../abstracts/abstract-event-dialogs.service';
import { EVENT_DIALOGS_CORE } from '../../consts/core/event-dialogs/event-dialogs.const';
import { EVENT_DIALOGS_CORE_CUSTOM } from '../../consts/custom/event-dialogs/event-dialogs.const';

@Injectable({
  providedIn: 'root'
})
export class CoreEventDialogsService extends AbstractEventDialogsService {
  getEventDialogs() {
    return {
      coreEventDialogs: EVENT_DIALOGS_CORE,
      customEventDialogs: EVENT_DIALOGS_CORE_CUSTOM,
    };
  }
}
