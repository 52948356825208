import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '../../../core/providers/currency.service';
import { CurrencyBalance, CurrencyBalanceDetails } from '../../../core/interfaces/currency';

@Pipe({
  name: 'currencyGameBalance',
})
export class CurrencyGameBalancePipe implements PipeTransform {
  constructor(
    private currencyService: CurrencyService,
  ) {
  }

  transform(currencies: CurrencyBalanceDetails[], searchKey: string): CurrencyBalance {
    if (!searchKey) {
      return;
    }

    const currencyDefinition = <any>this.currencyService.getCurrencyDefinitionByKey(searchKey);
    return this.currencyService.getCurrencyBalance(currencyDefinition, currencies);
  }
}
