import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MBuildingInfoComponent } from '../../base/core/dialogs/building-info/m-building-info.component';
import { SharedModule } from '../../../../../shared/shared.module';
import { BuildingsAbstractModule } from '../../abstract/core/buildings-abstract.module';
import { MBuildingUpgradeComponent } from '../../base/core/dialogs/building-upgrade/m-building-upgrade.component';
import { MBuildingsListComponent } from '../../base/core/dialogs/buildings-list/m-buildings-list.component';
import { MBuildingBuildComponent } from '../../base/core/dialogs/building-build/m-building-build.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { MBuildingProductionComponent } from '../../base/core/dialogs/building-production/m-building-production.component';
import { SharedUiMobileModule } from '../../../shared-ui/mobile/shared-ui-mobile.module';
import { MBuildingThumbnailComponent } from '../../base/core/components/building-thumbnail/building-thumbnail.component';
import { MBuildingUpgradeDiffParameterComponent } from '../../base/core/components/building-upgrade-diff-parameter/building-upgrade-diff-parameter.component';
import { MBuildingUpgradeDiffProductionComponent } from '../../base/core/components/building-upgrade-diff-production/building-upgrade-diff-production.component';
import { MBuildingSpecialComponent } from '../../base/core/dialogs/building-special/building-special.component';
import { MBuildingMoveComponent } from '../../base/core/dialogs/building-move/building-move.component';
import { MTileBuyComponent } from '../../base/core/dialogs/tile-buy/tile-buy.component';
import { MBuildingRowProductionComponent } from '../../base/core/components/building-row-production/building-row-production.component';
import { MBuildingAutoProductionComponent } from '../../base/core/dialogs/building-auto-production/building-auto-production.component';
import { MBuildingAllowProductionComponent } from '../../base/core/components/building-allow-production/building-allow-production.component';
import { MBuildingProductionCyclesComponent } from '../../base/core/components/building-production-cycles/building-production-cycles.component';
import { MBuildingDemolishComponent } from '../../base/core/dialogs/building-demolish/building-demolish.component';
import { CUSTOM_DECLARATIONS, CUSTOM_ENTRY_COMPONENTS } from '../../consts/custom/components.const';
import { CUSTOM_IMPORTS } from '../../consts/custom/imports.const';
import { CUSTOM_EXPORTS } from '../../consts/custom/exports.const';
import { CoreBuildingTimeComponent } from '../../base/core/components/building-time/building-time.component';

@NgModule({
  declarations: [
    MBuildingInfoComponent,
    MBuildingUpgradeComponent,
    MBuildingsListComponent,
    MBuildingBuildComponent,
    MBuildingProductionComponent,
    MBuildingThumbnailComponent,
    MBuildingUpgradeDiffParameterComponent,
    MBuildingUpgradeDiffProductionComponent,
    MBuildingSpecialComponent,
    MBuildingMoveComponent,
    MTileBuyComponent,
    MBuildingRowProductionComponent,
    MBuildingAutoProductionComponent,
    MBuildingAllowProductionComponent,
    MBuildingProductionCyclesComponent,
    MBuildingDemolishComponent,
    CoreBuildingTimeComponent,
    ...CUSTOM_DECLARATIONS,
  ],
  imports: [
    CommonModule,
    SharedModule,
    BuildingsAbstractModule,
    SwiperModule,
    SharedUiMobileModule,
    ...CUSTOM_IMPORTS,
  ],
  exports: [
    MBuildingInfoComponent,
    MBuildingsListComponent,
    MBuildingProductionComponent,
    MBuildingThumbnailComponent,
    MBuildingRowProductionComponent,
    MBuildingAllowProductionComponent,
    MBuildingProductionCyclesComponent,
    ...CUSTOM_EXPORTS,
  ],
  entryComponents: [
    MBuildingInfoComponent,
    MBuildingUpgradeComponent,
    MBuildingsListComponent,
    MBuildingBuildComponent,
    MBuildingProductionComponent,
    MBuildingSpecialComponent,
    MBuildingMoveComponent,
    MTileBuyComponent,
    MBuildingAutoProductionComponent,
    MBuildingDemolishComponent,
    ...CUSTOM_ENTRY_COMPONENTS,
  ]
})
export class BuildingsModule {
}
