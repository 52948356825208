import { NgModule } from '@angular/core';
import { MobileGameEnginePceComponent } from './components/game-engine-pce/game-engine-pce.component';

@NgModule({
  declarations: [
    MobileGameEnginePceComponent
  ],
  exports: [
    MobileGameEnginePceComponent
  ]
})
export class GameEngineMobileModule {
}
