import { ElementRef, Injectable, Input, ViewChild } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { Qa } from '../../interfaces/core/qa.interface';
import * as moment from 'moment';
import { QA_TYPE } from '../../consts/core/qa-type.const';

@Injectable()
export abstract class AbstractQaSummaryComponent extends AbstractInjectBaseComponent {
  @ViewChild('scoreAndPrizes', {static: false}) scoreAndPrizes: ElementRef;
  @Input() qa: Qa;
  @Input() separatorTitle: string;
  STOCK_VIEW = STOCK_VIEW;
  QA_TYPE = QA_TYPE;
  scoreText: string;
  answeringTime: string;
  gotMaxPoint: boolean;
  isShowSeparator: boolean;

  afterQa() {
    this.clear();
    const lastAttempt = this.qa.last_attempt;
    this.scoreText = this.calculateScoreText();
    this.gotMaxPoint = this.gotMaxPoints();
    if (lastAttempt && lastAttempt.finished_at && lastAttempt.started_at) {
      this.answeringTime = this.timeDifference(lastAttempt.finished_at, lastAttempt.started_at);
    }
  }

  checkAnyElScoreAndPrizes() {
    setTimeout(() => {
      if (this.scoreAndPrizes) {
        this.isShowSeparator = this.scoreAndPrizes.nativeElement.lastElementChild;
      }
    });
  }

  clear() {
    this.scoreText = null;
    this.answeringTime = null;
    this.gotMaxPoint = null;
  }

  timeDifference(a, b) {
    const time = moment(a).diff(moment(b));
    return moment(time).format('mm:ss');
  }

  calculateScoreText(): string {
    const lastAttempt = this.qa.last_attempt;
    if (!lastAttempt || (lastAttempt && !lastAttempt.finished_at || this.qa.type === QA_TYPE.SURVEY)) {
      return null;
    } else {
      const score = lastAttempt.player_points;
      const maxScore = lastAttempt.max_points;

      if (score === maxScore) {
        return `${score}/${maxScore} (100%)`;
      } else if (score < maxScore) {
        return `${score}/${maxScore} (${Math.round(score / maxScore * 100)}%)`;
      }
    }
  }

  gotMaxPoints(): boolean {
    const lastAttempt = this.qa.last_attempt;
    if (lastAttempt && lastAttempt.max_points !== 0) {
      return lastAttempt.player_points === lastAttempt.max_points;
    }
    return false;
  }
}
