import { PlayGameComponent } from '../../basic/core/components/play-game/play-game.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const gameRoutes: Routes = [
  {
    path: '', component: PlayGameComponent,
  },

];

@NgModule({
  imports: [RouterModule.forChild(gameRoutes)],
  exports: [RouterModule]
})
export class GameRoutingModule {
}
