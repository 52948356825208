import { Injectable, Injector } from '@angular/core';
import { OwInjector } from '../helpers/injector.helper';

@Injectable()
export abstract class AbstractInjectBaseComponent {
  di: any[];
  owInjector: OwInjector;

  constructor(
    protected injector: Injector,
  ) {
    this.owInjector = new OwInjector(this.injector, this);
    this.owInjector.getInjectorsFromDI();
    this.afterConstructor();
  }

  afterConstructor() {
  }
}
