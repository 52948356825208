import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '../helpers/translate.helper';

@Pipe({
  name: 'myTranslate'
})
export class MyTranslatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return translate(value, args);
  }
}
