import { Component, OnInit } from '@angular/core';
import { AbstractUserSettingsComponent } from '../../../abstract/core/abstract-user-settings.component';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent extends AbstractUserSettingsComponent implements OnInit {

  ngOnInit() {
    this.createForm();
  }
}
