import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChestComponent } from '../../base/core/dialogs/chest/chest.component';
import { ChestAbstractModule } from '../../abstract/chest-abstract.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { ChestService } from '../../services/core/chest.service';
import { CUSTOM_DECLARATIONS, CUSTOM_ENTRY_COMPONENTS } from '../../consts/custom/components.const';
import { SharedUiMobileModule } from '../../../shared-ui/mobile/shared-ui-mobile.module';
import { CUSTOM_IMPORTS } from '../../consts/custom/imports.const';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ChestAbstractModule,
    SharedUiMobileModule,
    ...CUSTOM_IMPORTS,
  ],
  declarations: [
    ChestComponent,
    ...CUSTOM_DECLARATIONS,
  ],
  entryComponents: [
    ChestComponent,
    ...CUSTOM_ENTRY_COMPONENTS,
  ],
  providers: [
    ChestService,
  ]
})
export class ChestModule {
}
