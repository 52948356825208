<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- TOP BAR BASE -->
  <div class="top-bar base" *ngIf="activeStorage">
    <div class="absolute-left"
         [matTooltip]="'warehouse.tooltip' | myTranslate"
    >
      <i class="fas fa-warehouse-alt"></i>
      <span>{{ activeStorage.capacity | NumberWithSpaces }}</span>
    </div>

    <!-- CATEGORY NAME -->
    <div class="title" *ngIf="activeCategory">
      {{ activeCategory.name }}
    </div>
  </div>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <ng-container *ngIf="!productDetailsView">
    <!-- NAVIGATION CENTER -->
    <m-ui-navigation-center
      [hidePrev]="sliderIndex <= 0"
      [hideNext]="sliderIndex >= productsSlider.pages.length - 1"
      (prevClicked)="prevSlide()"
      (nextClicked)="nextSlide()"
    ></m-ui-navigation-center>
  </ng-container>

  <!-- BACK BAR -->
  <m-ui-back-button *ngIf="productDetailsView"></m-ui-back-button>

  <!-- PRODUCTS -->
  <div class="products-container loading-opacity">
    <swiper #sliderProductsRef [(index)]="sliderIndex">
      <div class="products" *ngFor="let page of productsSlider.pages">
        <div class="product-in-glow-box-big"
             *ngFor="let item of page"
             (click)="openTradeDialog(item)"
             [class.empty-placeholder]="item.product_id == null"
        >
          <ng-container *ngIf="item.product_id">
            <!-- PRODUCT NAME -->
            <span class="product-name">
                  {{ item.name }}
                </span>
            <!-- PRODUCT ICON -->
            <img class="product-icon" [src]="item.iconUrlBig"/>
            <!-- PRODUCT BALANCE -->
            <span class="product-balance">
                  {{ item.balance | NumberWithSpaces }}
              </span>
          </ng-container>

          <div class="clip-path-big"></div>
        </div>
      </div>
    </swiper>
  </div>

  <!-- CATEGORIES -->
  <div class="categories" *ngIf="storages.length > 0">
    <ng-container *ngFor="let storage of storages">
      <div class="category" *ngFor="let category of storage.categories" (click)="changeCategory(category)">
          <span
            [class.active]="category == activeCategory"
            [class.new-products]="category.hasNewProducts"
          >
            {{ category.name }}
          </span>
      </div>
    </ng-container>
  </div>
</div>
