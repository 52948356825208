import { Type } from '@angular/core';
import { AbstractInjectBaseComponent } from '../abstracts/abstract-inject-base.component';
import { MatDialogConfig } from '@angular/material';

export class CustomDialogEvent {
  callback: (MatDialogConfig) => Type<AbstractInjectBaseComponent>;

  constructor(callback) {
    if (typeof callback !== 'function') {
      console.error(`${callback} is not a function`);
      return;
    }

    this.callback = callback;
  }

  getComponent(matDialogConfig: MatDialogConfig): Type<AbstractInjectBaseComponent> {
    return this.callback(matDialogConfig);
  }
}
