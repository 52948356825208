import { Injectable } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../core/abstracts/abstract-inject-base.component';
import { SocketActions, SocketState } from '../../../store/socket';
import { SOCKET_STATUS } from '../../../core/consts/core/socket.constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state';
import { GlobalService } from '../../../core/providers/global.service';
import { OwInject } from '../../../core/decorators/ow-inject.decorator';
import { GlobalEvent } from '../../../core/interfaces/shared.interfaces';
import { EVENTS } from '../../../core/consts/core/events';
import { LoginActions } from '../../../store/auth/login';
import { AuthService } from '../../auth/providers/auth.service';
import { GuiService } from '../../game/services/gui.service';

@Injectable()
export abstract class AbstractInfoMessageScreenComponent extends AbstractInjectBaseComponent {
  SOCKET_STATUS = SOCKET_STATUS;
  socketState: SocketState;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(GuiService) guiService: GuiService;

  eventData: any;

  subs = {
    socket: null
  };

  afterConstructor() {
    this.globalService.globalEvents.subscribe(this.handleGameEvents.bind(this));
  }

  handleGameEvents(event: GlobalEvent) {
    this.eventData = event.value;

    switch (event.name) {
      case EVENTS.GLOBAL.CONNECT:
        this.store.dispatch(new SocketActions.SocketAuthorized());
        break;

      case EVENTS.GLOBAL.UNAUTHORIZED:
        this.store.dispatch(new LoginActions.AuthLogout());
        break;

      case EVENTS.GLOBAL.OTHER_SESSION:
        this.store.dispatch(new SocketActions.SocketOtherSession());
        break;

      case EVENTS.GLOBAL.SOCKET_CONNECTION_ERROR:
        this.store.dispatch(new SocketActions.SocketConnectionError());
        break;

      case EVENTS.GLOBAL.DIFFERENT_VERSION_GAME:
        this.store.dispatch(new SocketActions.SocketDifferentVersionGame());
        break;

      case EVENTS.GLOBAL.DISCONNECT:
        this.store.dispatch(new SocketActions.SocketDisconnected());
        break;

      case EVENTS.GLOBAL.AUTHENTICATING:
        this.store.dispatch(new SocketActions.SocketAuthenticating());
        break;
    }
  }

  reloadApp() {
    location.reload(true);
  }

  logout() {
    this.authService.logoutApi()
      .subscribe(() => {
        this.guiService.isSplashShow.next(true);
        this.store.dispatch(new LoginActions.AuthLogout());
      });
  }
}
