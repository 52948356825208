import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractVirtualKeyboardComponent } from './abstract-virtual-keyboard.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AbstractVirtualKeyboardComponent,
  ],
})
export class VirtualKeyboardAbstractModule {
}
