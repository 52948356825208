import {createSelector} from '@ngrx/store';
import {AppState} from '../state';
import {PlayerState} from './state';
import {Player} from '../../modules/player/interfaces/player';

export const selectState = (state: AppState) => state.application.player;

const getIsLoading = (state: PlayerState): boolean => state.isLoading;
export const selectIsLoading = createSelector<AppState, object, boolean>(
  selectState,
  getIsLoading
);

const getPlayer = (state: PlayerState): Player => state.player;
export const selectPlayer = createSelector<AppState, object, Player>(
  selectState,
  getPlayer
);