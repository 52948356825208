import { Injectable } from '@angular/core';
import { AbstractMessageDetailsBaseComponent } from './abstract-message-details-base.component';
import { ProductPlayerService } from '../../../../../player/providers/product-player.service';
import { CurrencyDetails } from '../../../../../../core/interfaces/currency';
import { MessageDetailsChestSuccess, ProductChest } from '../../interfaces/core/message-details-chest-success.interface';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';

@Injectable()
export abstract class AbstractMessageDetailsChestSuccessComponent extends AbstractMessageDetailsBaseComponent {
  message: MessageDetailsChestSuccess;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;

  currencyDetails(value): CurrencyDetails {
    return value;
  }

  getProducts() {
    this.message.parameters.product_rewards = <ProductChest[]>this.productPlayerService.getProducts(this.message.parameters.product_rewards || []);
  }
}
