import { Component, OnInit } from '@angular/core';
import { AbstractRankListComponent } from '../../../abstract/abstract-rank-list.component';
import { MRankDetailsGameComponent } from '../rank-details-game/rank-details-game.component';
import { MRankDetailsBusinessComponent } from '../rank-details-business/rank-details-business.component';

@Component({
  selector: 'app-m-rank-list',
  templateUrl: './rank-list.component.html',
  styleUrls: ['./rank-list.component.scss']
})
export class MRankListComponent extends AbstractRankListComponent implements OnInit {
  rankDetailsGameComponent = MRankDetailsGameComponent;
  rankDetailsBusinessComponent = MRankDetailsBusinessComponent;

  ngOnInit() {
    this.baseInit();
  }
}
