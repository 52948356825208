import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractStockBase } from '../../../../abstract/abstract-stock-base.component';
import { STOCK_VIEW } from '../../../consts/stock-view.const';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { CurrencyService } from '../../../../../../../../core/providers/currency.service';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';
import { CurrencyBalanceDetails } from '../../../../../../../../core/interfaces/currency';
import { map, withLatestFrom } from 'rxjs/operators';
import { select } from '@ngrx/store';
import { UserSelectors } from '../../../../../../../../store/user';
import { PlayerSelectors } from '../../../../../../../../store/player';

@Component({
  selector: 'm-ui-currency',
  templateUrl: '../stock.html',
  styleUrls: ['../stock.scss']
})
export class MUiCurrencyComponent extends AbstractStockBase implements OnInit, OnDestroy, OnChanges {
  @OwInject(CurrencyService) currencyService: CurrencyService;
  defaultStockView = STOCK_VIEW.A;
  currenciesBalanceDetails: CurrencyBalanceDetails[];

  subs = {
    currencyBalances: null
  };

  ngOnInit() {
    this.setDefaultStockView();

    if (this.observable) {
      this.subscribeCurrenciesBalance();
    } else {
      this.parseItem();
    }
  }

  ngOnChanges() {
    this.parseItem();
  }

  subscribeCurrenciesBalance() {
    this.subs.currencyBalances = this.store
      .pipe(
        withLatestFrom(
          this.store.pipe(select(UserSelectors.selectUser)),
          this.store.pipe(select(PlayerSelectors.selectPlayer)),
        ),
        map(([_, user, player]) => {
          return [...user.currency_balances, ...player.currency_balances];
        })
      )
      .subscribe((currenciesBalanceDetails: CurrencyBalanceDetails[]) => {
        this.currenciesBalanceDetails = currenciesBalanceDetails;
        this.parseItem();
      });
  }

  parseItem() {
    this.item = this.currencyService.getCurrencyDefinition(this.item);

    if (this.currenciesBalanceDetails) {
      this.item = this.currencyService.getCurrencyBalance(this.item, this.currenciesBalanceDetails);
    }

    super.parseItem();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
