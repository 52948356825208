import { MESSAGE_DETAILS_TYPE } from './message-details-type.const';
import { CUSTOM_MAPPED_TYPES_TO_COMPONENT } from '../custom/mapped-types-to-component.const';
import { EVENT_DIALOGS_NAMES_MESSAGE } from './event-dialogs/event-names.const';

export const MAPPED_TYPES_TO_COMPONENT = {
  [MESSAGE_DETAILS_TYPE.NORMAL]: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_NORMAL,
  [MESSAGE_DETAILS_TYPE.GALLERY]: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_COMICS,
  [MESSAGE_DETAILS_TYPE.LEVEL_UP]: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_LEVEL_UP,
  [MESSAGE_DETAILS_TYPE.HIDDEN_REWARD]: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_HIDDEN_REWARD,
  [MESSAGE_DETAILS_TYPE.CHEST_SUCCESS]: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_CHEST_SUCCESS,
  [MESSAGE_DETAILS_TYPE.CHEST_FAILED]: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_CHEST_FAILED,
  [MESSAGE_DETAILS_TYPE.PROBABILITY_COMPENSATION]: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_PROBABILITY_COMPENSATION,
  ...CUSTOM_MAPPED_TYPES_TO_COMPONENT,
};
