<div class="top-left-container">
  <!-- HUD PLAYER -->
  <app-hud-player
    [player]="player"
  ></app-hud-player>

  <!-- HUD PARAMETERS -->
  <div class="hud-parameters">
    <template #hudParametersTpl></template>
  </div>
</div>

<!-- HUD RESOURCES -->
<app-hud-resources
  [player]="player"
  [user]="user"
></app-hud-resources>

<div style="position: fixed; z-index: 9; margin-left: 50%; display: none">
  <button mat-raised-button (click)="startMainScene()">Go to main scene</button>
  <button mat-raised-button (click)="startWorldScene()">Go to world scene</button>
  <button mat-raised-button (click)="showWorldInfo()">Regions</button>
</div>

<!-- HUD OFFSET TIME -->
<app-hud-offset-time [player]="player"></app-hud-offset-time>

<!-- HUD MENU -->
<div class="hud-menu">
  <template #hudMenuTpl></template>
</div>

<app-to-iso *ngIf="gameService?.game?.debug"></app-to-iso>

<!-- OTHER DYNAMICS COMPONENT -->
<template #dynamicsComponentTpl></template>
