import { BootScene } from '../core/Boot.scene';
import { MyGame } from '../../classes/core/MyGame';
import { MAIN_MAIN_SCENE, MAIN_PRELOAD_SCENE } from '../main.constants';
import { CustomPreloadScene } from './CustomPreload.scene';
import { CustomMainScene } from './CustomMain.scene';

export class CustomBootScene extends BootScene {
  preload(): void {
    this.gameService = (this.game as MyGame).gameService;
    this.gameService.guiService.isSplashShow.next(true);
    this.gameService.prepareGameData(this.gameService.playerService.player.id);

    if (!this.scene.get(MAIN_PRELOAD_SCENE)) {
      this.scene.add(MAIN_PRELOAD_SCENE, CustomPreloadScene, false);
      this.scene.add(MAIN_MAIN_SCENE, CustomMainScene, false);
    }
  }
}
