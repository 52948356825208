import { TileMenuConfig } from '../classes/core/TileMenuCore';
import { GAME_EVENTS } from '../../constants';
import { CUSTOM_TILE_MENU_CONFIG } from './custom-tile-menu.config';
import { BoardTile } from '../classes/custom/BoardTile.class';

export const TILE_MENU_CONFIG: TileMenuConfig = {
  buttons: [
    {
      x: 61,
      y: -59,
      name: 'tile-menu.info',
      icon: '\uf129',
      frame: 'info.png',
      hoverFrame: 'info.png',
      activeFrame: 'info.png',
      eventName: GAME_EVENTS.BUILDING_INFO,
      playerTileId: null,
    },
    {
      x: 0,
      y: -60,
      name: 'tile-menu.production',
      icon: '\uf04b',
      frame: 'play.png',
      hoverFrame: 'play.png',
      activeFrame: 'play.png',
      eventName: GAME_EVENTS.BUILDING_PRODUCTION,
      playerTileId: null,
    },
    {
      x: 0,
      y: -60,
      name: 'tile-menu.auto-production',
      icon: '\uf04b',
      frame: 'play.png',
      hoverFrame: 'play.png',
      activeFrame: 'play.png',
      eventName: GAME_EVENTS.BUILDING_AUTO_PRODUCTION,
      playerTileId: null,
    },
    {
      x: -63,
      y: 53,
      name: 'tile-menu.upgrade',
      icon: '\uf346',
      frame: 'up.png',
      hoverFrame: 'up.png',
      activeFrame: 'up.png',
      eventName: GAME_EVENTS.BUILDING_UPGRADE,
      playerTileId: null,
    },
    {
      x: 61,
      y: 53,
      name: 'tile-menu.move',
      icon: '\uf0b2',
      frame: 'move.png',
      hoverFrame: 'move.png',
      activeFrame: 'move.png',
      eventName: GAME_EVENTS.BUILDING_MOVE,
      playerTileId: null,
    },
    {
      x: 0,
      y: 54,
      name: 'tile-menu.delete',
      icon: '\uf2ed',
      frame: 'delete.png',
      hoverFrame: 'delete.png',
      activeFrame: 'delete.png',
      eventName: GAME_EVENTS.BUILDING_DELETE,
      playerTileId: null,
    },
  ],
  ...CUSTOM_TILE_MENU_CONFIG
};

export function checkAdditionalBoardDataButtonActive(boardTile: BoardTile) {
  return boardTile.playerBuildingData.additional_board_data.hasOwnProperty(this.additionalBoardData);
}
