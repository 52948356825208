import { GameObjects } from 'phaser';

export class Wave extends GameObjects.Sprite {

  isRipple?: boolean;
  targetX: number;
  targetY: number;

  constructor(params) {
    super(params.scene, params.x, params.y, params.key);
    this.targetX = params.targetX;
    this.targetY = params.targetY;

    const targetPos = {
      x: this.targetX,
      y: this.targetY,
    };

    const scaleFactor = Phaser.Math.Between(0, 1) ? 1 : -1;
    this.scaleX = scaleFactor;

    this.originX = 0.5;
    this.originY = 0.5;
    this.rotation = Phaser.Math.Angle.Between(0, 0, this.x - targetPos.x, this.y - targetPos.y) - 1.75;

    this.scene.add.existing(this);
    this.alpha = 0;
    // this.setBlendMode(Phaser.BlendModes.ADD);

    const timeline = this.scene.tweens.timeline(
      {
        ease: 'Back.easeOut',
        duration: 9000,
        tweens: [
          {
            targets: this,
            scaleX: 1.5 * scaleFactor,
            scaleY: 1.5,
            x: targetPos.x,
            y: targetPos.y,
            offset: 0,
            duration: 8000
          },
          {
            targets: this,
            alpha: 0.3,
            duration: 3000,
            offset: 0,
          },
          {
            targets: this,
            alpha: 0,
            duration: 3000,
            offset: 6000,
            onComplete: () => {
              this.destroy();
            }
          }
        ]
      }
    );
  }
}

