<div class="player-resources" *ngIf="player && visible">
  <ng-container *ngFor="let parameterBalance of parameterBalances">
    <div
      class="bar"
      [ngbTooltip]="parameterBalance.tooltip && tooltipTpl"
      container="body"
      tooltipClass="delayed"
      placement="bottom-left"
      #tooltip="ngbTooltip"
      (mousedown)="tooltip.open()"
    >
      <div class="icon-container">
        <div
          class="ow-icon"
          [style.background-image]="'url('+(parameterBalance.key + '.png' | asset:'parameters/hud') +')'"
        ></div>
      </div>

      <ng-container [ngSwitch]="parameterBalance.type">
        <!-- TYPE 1 -->
        <ng-container
          *ngSwitchCase="'population_delta'"
          [ngTemplateOutlet]="parameterType1"
          [ngTemplateOutletContext]="{parameter: parameterBalance}"
        ></ng-container>

        <!-- TYPE OTHER -->
        <ng-container
          *ngSwitchDefault
          [ngTemplateOutlet]="parameterTypeOther"
          [ngTemplateOutletContext]="{parameter: parameterBalance}"
        ></ng-container>
      </ng-container>
    </div>

    <!-- TOOLTIP -->
    <ng-template #tooltipTpl>
      <div
        style="font-weight: normal"
        [innerHTML]="parameterBalance.tooltip"
      ></div>
    </ng-template>
  </ng-container>
</div>

<!-- TYPE 1 -->
<ng-template #parameterType1 let-parameter="parameter">
  {{ parameter.displayHud | NumberWithSpaces }}

  <div
    class="delta"
    *ngIf="player.population_delta as delta"
    [ngClass]="delta > 0 ? 'green' : 'red'"
  >
      <span>
        {{ delta > 0 ? '+' : '' }}<!--
        -->{{ delta }}
      </span>
    <i class="fas" [ngClass]="delta > 0 ? 'fa-arrow-alt-up' : 'fa-arrow-alt-down'"></i>
  </div>
</ng-template>

<!-- TYPE 4 -->
<ng-template #parameterTypeOther let-parameter="parameter">
  {{ parameter.displayHud }}%
</ng-template>
