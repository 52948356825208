import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ActionTypes, FetchFailure, FetchSuccess } from './actions';
import { Primary } from '../../core/interfaces/primary';
import { PrimaryService } from '../../core/providers/primary.service';
import { AppState } from '../state';
import { select, Store } from '@ngrx/store';
import { selectPrimary } from './selectors';
import { CurrencyService } from '../../core/providers/currency.service';
import { ParametersService } from '../../core/providers/parameters.service';
import { ProductsService } from '../../modules/game/services/products.service';
import { ProductPlayerService } from '../../modules/player/providers/product-player.service';

@Injectable()
export class PrimaryEffects {

  constructor(
    private actions$: Actions,
    private primaryService: PrimaryService,
    private store: Store<AppState>,
    private currencyService: CurrencyService,
    private parametersService: ParametersService,
    private productService: ProductsService,
    private productPlayerService: ProductPlayerService,
  ) {
  }

  @Effect()
  $fetchStart: Observable<FetchSuccess | FetchFailure> = this.actions$
    .pipe(
      ofType(ActionTypes.FETCH_PRIMARY_START),
      mergeMap(() => {
        return this.fetchPrimary();
      })
    );


  fetchPrimary(): Observable<FetchSuccess | FetchFailure> {
    return this.primaryService.primary()
      .pipe(
        map((primary: Primary) => {
          return new FetchSuccess(primary);
        }),
        catchError((error: any) => {
          return of(new FetchFailure(error));
        })
      );
  }

  @Effect({dispatch: false})
  $fetchSuccess = this.actions$
    .pipe(
      ofType(ActionTypes.FETCH_PRIMARY_SUCCESS),
      withLatestFrom(
        this.store.pipe(select(selectPrimary))
      ),
      map(([action, primary]) => primary),
      tap((primary) => {
        this.currencyService.setCurrencyDefinitions(primary.currencies);
        this.parametersService.setParametersDefinitions(primary.parameters);
        this.productPlayerService.setProductsDefinitions(primary.products);
        // @TODO:gutyo [remove productService after clean product definitions]
        this.productService.setProductsDefinitions(primary.products);
      })
    );
}
