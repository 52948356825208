import { HudMenuComponent } from '../../../../basic/components/hud-menu/hud-menu.component';
import { HudParametersComponent } from '../../../../basic/components/hud-parameters/hud-parameters.component';

export const CORE_DYNAMIC_COMPONENTS = {
  HUD_MENU: {
    component: HudMenuComponent,
    viewContainerRef: 'hudMenuTpl',
    data: (context) => {
      return {
        player: context['player'],
      };
    }
  },
  HUD_PARAMETERS: {
    component: HudParametersComponent,
    viewContainerRef: 'hudParametersTpl',
  },
};
