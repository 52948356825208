export const SOCKET_STATUS = {
  CONNECT: 1,
  CONNECTED: 2,
  AUTHORIZED: 3,
  AUTHENTICATING: 4,
  OTHER_SESSION: 10,
  DISCONNECTED: 99,
  CONNECTION_ERROR: 100,
  DIFFERENT_VERSION_GAME: 999,
};
