import { Injectable } from '@angular/core';
import { ParameterDefinition, ParameterValue, PlayerParameterBalance } from '../../modules/game/interfaces/parameters';
import { BehaviorSubject } from 'rxjs';
import { getParameterIcon } from '../../modules/game/game-gui/helpers/parameters.helper';
import * as R from 'ramda';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {

  playerParametersBalances: PlayerParameterBalance[] = [];
  playerParametersBalances$: BehaviorSubject<PlayerParameterBalance[]> = new BehaviorSubject([]);

  parametersDefinitions: ParameterDefinition[] = [];

  setParametersDefinitions(parametersDefinitions: ParameterDefinition[]) {
    this.parametersDefinitions = [];
    parametersDefinitions.forEach((parameterDefinition) => {
      this.parametersDefinitions.push({
        ...parameterDefinition,
        icon: getParameterIcon(parameterDefinition.key),
      });
    });
  }

  setParametersBalances(playerParametersBalances: PlayerParameterBalance[]) {
    this.playerParametersBalances = playerParametersBalances.map(playerParameterBalance => {
      const parameterDefinition = this.getParameterDefinition(playerParameterBalance.parameter_id);
      return {
        ...parameterDefinition,
        ...playerParameterBalance,
      };
    });
    this.playerParametersBalances$.next(this.playerParametersBalances);
  }

  getParameterDefinition(parameterId: number) {
    return R.clone(this.parametersDefinitions.find(def => def.parameter_id === parameterId));
  }

  getParameterBalanceBy({key, value}: { key: string, value: any }) {
    return this.getParametersFromValues(this.playerParametersBalances)
      .find((parameterValue) => {
        return parameterValue[key] === value;
      });
  }

  getParameterBalancesBy({key, value}: { key: string, value: any }) {
    return this.getParametersFromValues(this.playerParametersBalances)
      .filter((parameterValue) => {
        return parameterValue[key] === value;
      });
  }

  getParameterDefinitionByType(type: string) {
    return R.clone(this.parametersDefinitions.find(def => def.type === type));
  }

  getParametersFromValues(parameterValues: ParameterValue[]) {
    return parameterValues.map(parameterValue => {
      const parameterDefinition = this.getParameterDefinition(parameterValue.parameter_id);
      return {
        ...parameterDefinition,
        ...parameterValue,
      };
    });
  }
}
