export function generateEachPages(options: { items: any[], pages: any[], config: any }) {
  let currentIndex = 1;
  let page = [];

  for (let index in options.items) {
    let item = options.items[index];
    page.push(item);

    if (currentIndex == options.config.itemPerPage || parseInt(index) == options.items.length - 1) {
      options.pages.push(page);
      currentIndex = 1;
      page = [];
    } else {
      currentIndex++;
    }
  }

  return options;
}
