import { Injectable } from '@angular/core';
import { AbstractInjectBaseComponent } from './abstract-inject-base.component';
import { OwInject } from '../decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Alert } from '../interfaces/alert';

@Injectable()
export abstract class AbstractAlertComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractAlertComponent>;
  @OwInject(MAT_DIALOG_DATA) data: Alert;

  close(confirm?: boolean) {
    this.matDialogRef.close(confirm);
  }
}
