
    <div class="screentoiso" [class.open]="isOpen" *ngIf="gameService.debugUI && globalService.isDevDomain">
      <p class="desc">Screen to Iso</p>
      <div class="container">
        <button class="button-toggle" (click)="toggle()">
          <i class="fas fa-arrow-alt-{{!isOpen ? 'up' : 'down'}}"></i>
        </button>
        <p> Współrzędne ekranu (ortho) na Izometryczne</p>

        <input placeholder="x w ortho" [(ngModel)]='screenX' (keyup)='toIso()'>
        <input placeholder="y w ortho" [(ngModel)]='screenY' (keyup)='toIso()'>

        <p>x={{isoX}} y={{isoY}}</p>
      </div>
    </div>
  