const CONFIG_COOKIE = {
  SECURE: window.location.protocol === 'https:',
  PATH: '/main',
  EXPIRES: 0,
  EXPIRES_OLD: 'Thu, 01 Jan 1970 00:00:00 UTC',
};

function addCookieNameValue(name, value): string {
  return `${name}=${encodeURIComponent(value)};`;
}

function addDefaultPath(): string {
  return `path=${CONFIG_COOKIE.PATH};`;
}

function addDefaultSecure(): string {
  return `${CONFIG_COOKIE.SECURE ? 'secure;' : ''}`;
}

function addDefaultExpires(): string {
  return `expires=${CONFIG_COOKIE.EXPIRES};`;
}

function addOldExpires(): string {
  return `expires=${CONFIG_COOKIE.EXPIRES_OLD};`;
}

export const getCookie = (name) => {
  const cookieSrc = canSetCookie ? document.cookie : window['myCookie'];

  return cookieSrc.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');
};

export const setCookie = (name, value) => {
  let cookieValue = '';
  cookieValue += addCookieNameValue(name, value);
  cookieValue += addDefaultPath();
  cookieValue += addDefaultExpires();
  cookieValue += addDefaultSecure();

  setCookieInStorage(cookieValue);
};

export const removeCookie = (name) => {
  let cookieValue = '';
  cookieValue += addCookieNameValue(name, '');
  cookieValue += addDefaultPath();
  cookieValue += addDefaultSecure();
  cookieValue += addOldExpires();

  setCookieInStorage(cookieValue);
};

export const removeOldPathToken = () => {
  let tmpValue = '';
  tmpValue += addCookieNameValue('token', '');
  tmpValue += addDefaultSecure();
  tmpValue += addOldExpires();
  tmpValue += 'path=/;';

  document.cookie = tmpValue;
};

export const checkCanSetCookie = () => {
  const COOKIE_TEST_KEY = 'cookie-set-test';
  const COOKIE_TEST_VALUE = 'true';

  let cookieValue = '';
  cookieValue += addCookieNameValue(COOKIE_TEST_KEY, COOKIE_TEST_VALUE);
  cookieValue += addDefaultPath();
  cookieValue += addDefaultSecure();

  // SET
  document.cookie = cookieValue;
  const isSetCookie = document.cookie.split('; ').find(cookie => cookie === `${COOKIE_TEST_KEY}=${COOKIE_TEST_VALUE}`);

  // DELETE
  cookieValue += addOldExpires();
  document.cookie = cookieValue;
  return isSetCookie;
};

function setCookieInStorage(cookieValue) {
  if (canSetCookie) {
    document.cookie = cookieValue;
  } else {
    const myCookie = window[`myCookie`].split('; ');
    myCookie.push(cookieValue);
    window[`myCookie`] = myCookie.join('; ');
  }
}

removeOldPathToken();
const canSetCookie = checkCanSetCookie();
window['canSetCookie'] = canSetCookie;
if (!canSetCookie) {
  window[`myCookie`] = '';
}
