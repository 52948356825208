export const TILE_WIDTH = 384 / 384;
export const TILE_HEIGHT = 222 / 384;
export const TILE_WIDTH_HALF = TILE_WIDTH / 2;
export const TILE_HEIGHT_HALF = TILE_HEIGHT / 2;
export const ISO_PROJECTION = TILE_HEIGHT / TILE_WIDTH;

export const ISO_LAYER_TYPE_TILE = 'tilelayer';
export const ISO_LAYER_TYPE_OBJECT = 'objectgroup';

export const MAP_TYPE_ISOMETRIC = 'isometric';
export const MAP_TYPE_STAGGERED = 'staggered';

export const ISO_OBJECT_DEPTH_VALUE_FACTOR = 0.000001;

export const LOADED_TILEMAP_DIMENSIONS = {
  width: null,
  height: null
};
