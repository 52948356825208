import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractRequirementsComponent } from '../../../abstract/abstract-requirements.component';
import { unsubscribeObject } from '../../../../../../../core/utility/unsubscribe-array';

@Component({
  selector: 'm-ui-requirements',
  templateUrl: './ui-requirements.component.html',
  styleUrls: ['./ui-requirements.component.scss']
})
export class MUiRequirementsComponent extends AbstractRequirementsComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribePlayer();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
