<div class="global-dialog ow-dialog window-a primary">
  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button
    *ngIf="isOthersView"
    [preventDefault]="true"
    (clicked)="toggleOthersView()"
  ></m-ui-back-button>

  <!-- LOADING API -->
  <loading></loading>

  <div class="player-container loading-opacity" *ngIf="player && !isOthersView">
    <!-- PLAYER BAR -->
    <app-hud-player
      [allowHover]="false"
      [changeProfileImage]="true"
      [player]="player"
    ></app-hud-player>

    <!-- EXPERIENCE -->
    <span class="experience">
        {{ player.points_balance | NumberWithSpaces }} {{ 'player-details.exp' | myTranslate }}
      </span>

    <div class="flex-center nick-container">
      <!-- NICK -->
      <span class="nick">
        {{ player.farm_name || 'Nick' }}
      </span>

      <!-- OPEN SETTINGS -->
      <i
        (click)="openSettings()"
        id="icon-edit"
        class="fas fa-edit"
      ></i>
    </div>

    <m-ui-separator></m-ui-separator>

    <!-- EMAIL -->
    <span class="e-mail" *ngIf="player.email">
       {{ player.email }}
    </span>
  </div>

  <div class="others-container loading-opacity" *ngIf="isOthersView">
    <!-- TERMS -->
    <a
      [href]="LINKS.TERMS"
      target="_blank"
    >
      <button class="base primary">
        {{ 'player-details.terms' | myTranslate }}
      </button>
    </a>

    <!-- CHANGE PLAYER BUTTON -->
    <m-ui-change-player-button (clicked)="close()"></m-ui-change-player-button>

    <!-- CHANGE PASSWORD BUTTON -->
    <m-ui-change-password-button (clicked)="close()"></m-ui-change-password-button>

    <!-- OTHER DYNAMICS OPTIONS -->
    <ng-template #otherViewTpl></ng-template>

    <!-- PUSH MOBILE -->
    <form [formGroup]="formPush" *ngIf="userService.me.has_installed_mobile_app">
      <div class="control control-checkbox">
        <div class="custom-checkbox">
          <input type="checkbox" formControlName="pushEnabled" (change)="changePush()"/>
          <span class="custom-checkbox-fill">
          <i class="fas fa-check"></i>
        </span>
        </div>

        <label>{{ 'player-details.push' | myTranslate }}</label>
      </div>
    </form>
  </div>

  <!-- OTHERS -->
  <span
    id="others"
    (click)="toggleOthersView()"
    *ngIf="!isOthersView"
  >
    {{ 'player-details.others' | myTranslate }}
  </span>

  <!-- LOGOUT -->
  <span id="logout" (click)="logout()">
    {{ 'player-details.logout' | myTranslate }}
  </span>
</div>
