import { Injectable, Input, Type } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { CardTask } from '../../interfaces/custom/card-task.interface';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { AbstractCardTaskDetailsComponent } from './abstract-card-task-details.component';
import { AbstractChooseTaskComponent } from './abstract-choose-task.component';
import { EVENTS } from '../../../../../../core/consts/core/events';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { GlobalService } from '../../../../../../core/providers/global.service';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';

@Injectable()
export abstract class AbstractCardTaskComponent extends AbstractInjectBaseComponent {
  @OwInject(GlobalService) globalService: GlobalService;
  @Input() cardTask: CardTask;
  @Input() cardTasks: CardTask[];
  abstract dialogService: DialogService;
  abstract cardTaskDetailsComponent: Type<AbstractCardTaskDetailsComponent>;
  abstract chooseTaskComponent: Type<AbstractChooseTaskComponent>;
  STOCK_VIEW = STOCK_VIEW;

  actionCard() {
    if (this.cardTask.selected) {
      this.openCardTask();
    } else if (!this.cardTask.selected && this.cardTask.other_tasks.length) {
      this.openChooseTasks();
    }
  }

  openCardTask() {
    this.dialogService.open(this.cardTaskDetailsComponent, {
      data: {
        cardTask: this.cardTask,
        cardTasks: this.cardTasks,
      }
    }, (data?) => {
      if (data && data.reload) {
        this.globalService.globalEvents.emit({name: EVENTS.GUI.RELOAD_CARDS});
      }
    });
  }

  openChooseTasks() {
    this.dialogService.open(this.chooseTaskComponent, {
      data: {
        cardTask: this.cardTask,
        hideBackButton: true,
      }
    });
  }
}
