import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractPlayGameComponent } from '../../../../abstract/core/abstract-play-game.component';
import { unsubscribeObject } from '../../../../../../core/utility/unsubscribe-array';
import { NewImportantMessageClosed, NewImportantMessageOpened, ResetBoard } from '../../../../../../store/game/actions';
import { select } from '@ngrx/store';
import { selectGameReady, selectImportantMessages } from '../../../../../../store/game/selectors';
import { filter, take } from 'rxjs/operators';
import { ImportantMessagesState } from '../../../../../../store/game/interfaces/ImportantMessages.state';
import { handleMessageDetailsType } from '../../../../../game/game-ui/message/helpers/core/message.helper';
import { UtilitySelectors } from '../../../../../../store/utility';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { MessageOpenDetailsData } from '../../../../../game/game-ui/message/interfaces/core/message-open-details-data.interface';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { MessageEventFromDetails } from '../../../../../game/game-ui/message/interfaces/core/message-callback-from-details.interface';
import { MessageService } from '../../../../../game/game-ui/message/services/core/message.service';
import { FEATURE_FLAG } from '../../../../../../core/config/core/feature-flags.config';
import { orientationModes } from '../../../../../../core/consts/core/orientation-modes.enum';

@Component({
  selector: 'app-play-game',
  templateUrl: './play-game.component.html',
  styleUrls: ['./play-game.component.scss']
})
export class PlayGameComponent extends AbstractPlayGameComponent implements OnInit, OnDestroy {
  @OwInject(MessageService) messagesService: MessageService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;

  ngOnInit() {
    this.androidService.setNativeAppOrientation(orientationModes.Landscape);
    this.setDynamicsComponents();
    this.gameService.guiService.isSplashShow.next(true);
    this.updateUserOnce();
    this.updatePlayerOnce();

    this.subs.importantMessages = this
      .store
      .pipe(
        select(selectImportantMessages),
        filter(state => state.messages.length && !state.importantMessageDisplayed),
        filter(() => !this.guiService.isLockOpenDialog)
      )
      .subscribe((importantMessages: ImportantMessagesState) => {
        const message = importantMessages.messages[0];
        this.store.dispatch(new NewImportantMessageOpened(true));
        const messageType = handleMessageDetailsType(message);
        const eventDialog = this.messagesService.handleMessageDetailsComponent(messageType);

        const messageOpenDetailsData: MessageOpenDetailsData = {
          message: message,
          fromList: false,
        };

        this.eventEmitterDialogsService.emitter.emit({
          name: eventDialog,
          config: {
            data: messageOpenDetailsData,
            disableClose: true,
          },
          callback: (event: MessageEventFromDetails) => {
            this.store.dispatch(new NewImportantMessageClosed(message));
          }
        });
      });

    /**
     * Fetch board without islandId after user fetch complete.
     * Setting flag to fale will allow game scenes to control board load.
     */
    if (FEATURE_FLAG.FETCH_DEFAULT_BOARD_AFTER_USER) {
      this.subs.user = this
        .store
        .pipe(
          select(UtilitySelectors.selectActivePlayerId),
          filter(activePlayerId => !!activePlayerId),
          take(1)
        )
        .subscribe((activePlayerId: number) => {
          this.gameService.prepareGameData(activePlayerId);
        });
    }

    this.gameReady$ = this.store
      .pipe(
        select(selectGameReady)
      );

    this.subs.other = this
      .gameService
      .globalService
      .globalEvents
      .subscribe(this.handleGlobalEvent.bind(this));
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
    clearTimeout(this.inactivityTimeout);
    this.store.dispatch(new ResetBoard());
    this.androidService.setNativeAppOrientation(orientationModes.Auto);
  }
}
