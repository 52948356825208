import { Component, OnInit } from '@angular/core';
import { AbstractCardTaskDetailsComponent } from '../../../../abstract/custom/abstract-card-task-details.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';

@Component({
  selector: 'app-card-task-details',
  templateUrl: './card-task-details.component.html',
  styleUrls: ['./card-task-details.component.scss']
})
export class CardTaskDetailsComponent extends AbstractCardTaskDetailsComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;

  ngOnInit() {
    if (this.data.task) {
      this.setTask(this.data.task);
    } else if (this.data.cardTask.selected) {
      this.setTask(this.data.cardTask.selected);
    }

    this.setCardTask(this.data.cardTask);

    if (this.data.cardTasks) {
      this.notEmptyCardTasks = this.data.cardTasks.filter((cardTask) => {
        return cardTask && cardTask.selected;
      });

      this.setCurrentIndex();
    }
  }
}
