import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { GameEngineModule } from '../../../game/game-engine/game-engine.module';
import { EffectsModule } from '@ngrx/effects';
import { GameEffects } from '../../../../store/game';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GameEngineModule,
    EffectsModule.forFeature([
      GameEffects
    ]),
  ],
  exports: [
    SharedModule,
    GameEngineModule,
    EffectsModule,
  ],
  declarations: [],
})
export class GameAbstractModule {
}
