import { WORLD_SCENE_WORLD_HEIGHT, WORLD_SCENE_WORLD_WIDTH } from './world.constants';
import { SceneConfig } from '../interfaces/scene-config.interface';

export const WORLD_SCENE_CONFIG: SceneConfig = {
  backgroundImages: [
    {
      filename: 'map.jpg',
      key: 'map',
      origin: {
        x: 0,
        y: 0
      },
      displayWidth: WORLD_SCENE_WORLD_WIDTH,
      displayHeight: WORLD_SCENE_WORLD_HEIGHT,
      x: 0,
      y: 0
    }
  ],
  backgroundColor: '#71aee3',
  tiledJsonPrefix: 'island',
  mapDirectoryName: 'world',
  boardStartOffset: {
    x: 0,
    y: 0
  },
  tiledStartOffset: {
    x: 0,
    y: 0
  },
  mapAtlasDirectory: 'map',
  tileMenuAtlasDirectory: 'tile-menu',
  islandGroupIdForBoard: 'B',
};
