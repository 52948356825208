import { Component } from '@angular/core';

@Component({
  selector: 'game-loaded-placeholder',
  template: '',
  styles: [':host{display: block; pointer-events: none; width: 1px; height: 1px;}']
})
export class CoreGameLoadedPlaceholderComponent {
  // puppeteer monitoring component
}
