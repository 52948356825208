import { EventDialog } from '../../../../../../../core/interfaces/event-dialog.interface';
import { EVENT_DIALOGS_NAMES_WAREHOUSE } from './event-names.const';
import { CoreWarehouseComponent } from '../../../base/core/dialogs/warehouse/warehouse.component';
import { CoreWarehouseDetailsComponent } from '../../../base/core/dialogs/warehouse-details/warehouse-details.component';


export const EVENT_DIALOGS_WAREHOUSE: EventDialog = {
  [EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE]: CoreWarehouseComponent,
  [EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE_DETAILS]: CoreWarehouseDetailsComponent,
};
