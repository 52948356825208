import { MessageDetails } from '../../interfaces/core/message-details.interface';
import { MESSAGE_DETAILS_TYPE } from '../../consts/core/message-details-type.const';
import { MessageFilterValue } from '../../interfaces/core/message-filter-value.interface';

export function prepareFiltersValues(values: MessageFilterValue[], nameKey) {
  return values.map(value => {
      return {
        name: value[nameKey],
        id: value.id
      };
    }
  );
}

export function handleMessageDetailsType(messageDetails: MessageDetails) {
  let messageType = messageDetails.generic_type;

  if (messageDetails.gallery && messageDetails.gallery.length) {
    messageType = MESSAGE_DETAILS_TYPE.GALLERY;
  }

  return messageType || MESSAGE_DETAILS_TYPE.NORMAL;
}
