import { Component } from '@angular/core';
import { AbstractButtonOpenQaComponent } from '../../../../abstract/core/abstract-button-open-qa.component';

@Component({
  selector: 'button-open-qa',
  templateUrl: './button-open-qa.component.html',
  styleUrls: ['./button-open-qa.component.scss']
})
export class ButtonOpenQaComponent extends AbstractButtonOpenQaComponent {
}
