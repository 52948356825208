import { Injectable, Type, ViewChild } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../core/decorators/ow-inject.decorator';
import { MatDialogRef } from '@angular/material';
import { DialogService } from '../../../../shared/providers/dialog.service';
import { SwiperComponent } from 'ngx-swiper-wrapper';
import { RANK_TYPE } from '../consts/rank-type.const';
import { RANK_LOCATION } from '../consts/rank-location.const';
import { KeyValue } from '@angular/common';
import { OwDate } from '../../../../../core/utility/ow-date.class';
import { ApiGameRanksService } from '../api/services/api-game-ranks.service';
import { ApiBusinessRanksService } from '../api/services/api-business-ranks.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractRankDetailsGameComponent } from './abstract-rank-details-game.component';
import { translate } from '../../../../../core/helpers/translate.helper';
import { RankBase, RankBusiness, RankGame, RankType } from '../interfaces';
import { RankDetailsData } from '../interfaces/dialogs/rank-details-data.interface';
import { AbstractRankDetailsBusinessComponent } from './abstract-rank-details-business.component';
import { RANKS_TYPES } from '../mobile/dialogs/rank-list/custom/rank-types.config';

@Injectable()
export abstract class AbstractRankListComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractRankListComponent>;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiGameRanksService) apiGameRanksService: ApiGameRanksService;
  @OwInject(ApiBusinessRanksService) apiBusinessRanksService: ApiBusinessRanksService;
  @OwInject(MAT_DIALOG_DATA) data: { location: number; };
  abstract rankDetailsGameComponent: Type<AbstractRankDetailsGameComponent>;
  abstract rankDetailsBusinessComponent: Type<AbstractRankDetailsBusinessComponent>;

  @ViewChild('sliderRanks', {static: false}) sliderRanks: SwiperComponent;
  RANK_LOCATION = RANK_LOCATION;
  ranks: RankBase[] = [];
  sliderActiveIndex = 0;
  activeRankType: RankType;

  DEFAULT_RANKS_TYPES = {
    GAMES: {
      label: translate('ranks.rank-type.game'),
      order: 1,
      type: RANK_TYPE.GAME,
      show: true,
    },
    BUSINESS: {
      label: translate('ranks.rank-type.business'),
      order: 2,
      type: RANK_TYPE.BUSINESS,
      show: true,
    },
  };
  RANKS_TYPES;
  config = {
    slidesPerView: 3,
    centeredSlides: false,
  };

  baseInit() {
    this.overrideRanksTypes();
    this.clearSliderActiveIndex();
    this.changeActiveRankType(this.RANKS_TYPES.GAMES);
  }

  overrideRanksTypes() {
    this.RANKS_TYPES = RANKS_TYPES || this.DEFAULT_RANKS_TYPES;
  }

  clearSliderActiveIndex() {
    this.sliderActiveIndex = 0;
  }

  changeActiveRankType(activeRankType: RankType) {
    this.activeRankType = activeRankType;
    this.getRanks();
  }

  getRanks() {
    switch (this.activeRankType) {
      case this.RANKS_TYPES.GAMES:
        this.gameRanks();
        break;

      case this.RANKS_TYPES.BUSINESS:
        this.businessRanks();
        break;
    }
  }

  gameRanks() {
    this.clearSliderActiveIndex();

    this.apiGameRanksService.gameRanks({location: this.data.location})
      .subscribe((resp: RankGame[]) => {
        this.ranks = resp;
        this.checkSwiperOptions();
      }, (errResp) => {
        this.ranks = [];
        this.dialogService.openAlertErrorApi({errResp});
      });
  }

  businessRanks() {
    this.clearSliderActiveIndex();

    const {year, month} = new OwDate();

    this.apiBusinessRanksService.businessRanks({year, month})
      .subscribe((resp: RankBusiness[]) => {
        this.ranks = resp;
        this.checkSwiperOptions();
      }, (errResp) => {
        this.ranks = [];
        this.dialogService.openAlertErrorApi({errResp});
      });
  }

  openRankDetails(rank: RankBase) {
    switch (this.activeRankType.type) {
      case RANK_TYPE.GAME:
        this.openRankDetailsGame(rank);
        break;

      case RANK_TYPE.BUSINESS:
        this.openRankDetailsBusiness(rank);
        break;
    }
  }

  openRankDetailsGame(rank: RankBase) {
    const rankDetailsData: RankDetailsData = {
      rank,
      type: this.activeRankType.type,
      location: this.data.location,
    };

    this.dialogService.open(this.rankDetailsGameComponent, {
      data: rankDetailsData,
    });
  }

  openRankDetailsBusiness(rank: RankBase) {
    const rankDetailsData: RankDetailsData = {
      rank,
      type: this.activeRankType.type,
      location: this.data.location,
    };

    this.dialogService.open(this.rankDetailsBusinessComponent, {
      data: rankDetailsData,
    });
  }

  checkSwiperOptions() {
    this.config.centeredSlides = this.ranks.length <= 2;
  }

  prevSlide() {
    this.sliderRanks.directiveRef.prevSlide();
  }

  nextSlide() {
    this.sliderRanks.directiveRef.nextSlide();
  }

  keyAscOrder = (a: KeyValue<number, RankType>, b: KeyValue<number, RankType>): number => {
    return b.value.order > a.value.order ? -1 : (a.value.order > b.value.order ? 1 : 0);
  }
}
