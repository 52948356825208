import { Injectable } from '@angular/core';
import { AbstractMessageDetailsNormalComponent } from './abstract-message-details-normal.component';

@Injectable()
export abstract class AbstractMessageDetailsComicsComponent extends AbstractMessageDetailsNormalComponent {

  selectedItem = 0;

  loadItem(item) {
    this.selectedItem = item;
  }
}
