import { CARD_TEMPLATE } from '../../consts/custom/card-template.const';
import { Task } from '../../interfaces/custom/task.interface';

export function setTaskClasses(task: Task): string[] {
  const classes: string[] = [];

  if (task.performance !== null) {
    // CARD TEMPLATE
    switch (task.card_template) {
      case CARD_TEMPLATE.INDIVIDUAL:
        classes.push('card-template-1');
        break;

      case CARD_TEMPLATE.SHOP:
        classes.push('card-template-2');
        break;
    }
  }

  return classes;
}
