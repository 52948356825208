import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../core/providers/api.service';
import { ApiOptions } from '../../../../../../../core/interfaces/api-options';
import { GetMissionsRequest, PatchMissionHandOver } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ApiMissionService {

  constructor(private apiService: ApiService) {
  }

  getMissionList({slot}: GetMissionsRequest = {}) {
    const options: ApiOptions = {
      params: {
        slot
      }
    };

    return this.apiService.get('/player-missions', options);
  }

  getMissionDetails(player_mission_id) {
    return this.apiService.get(`/player-missions/${player_mission_id}`);
  }

  missionSeen(player_mission_id) {
    return this.apiService.patch(`/player-missions/${player_mission_id}/seen`);
  }

  missionCollect(player_mission_id) {
    return this.apiService.patch(`player-missions/${player_mission_id}/collect`);
  }

  missionReject(player_mission_id) {
    return this.apiService.patch(`player-missions/${player_mission_id}/reject`);
  }

  missionHandOver({player_mission_condition_id, amount}: PatchMissionHandOver) {
    const options: ApiOptions = {
      body: {
        amount
      }
    };

    return this.apiService.patch(`player-missions-conditions/${player_mission_condition_id}/hand-over`, options);
  }

}
