import { CardTask } from '../../interfaces/custom/card-task.interface';
import { setTaskClasses } from './set-task-classes.helper';
import { setScopePrize } from './set-scope-prize.helper';

export function prepareCardTask(cardTasks: CardTask[], returnArray: CardTask[] = []): CardTask[] {
  cardTasks.forEach((cardTask) => {
    if (cardTask.selected) {
      cardTask.selected.scopesPrizes = setScopePrize(cardTask.selected.scopes);
      cardTask.selected.taskClasses = setTaskClasses(cardTask.selected);
    }

    cardTask.other_tasks.forEach((task) => {
      task.scopesPrizes = setScopePrize(task.scopes);
      task.taskClasses = setTaskClasses(task);
    });

    const cardPosition = cardTask.card_position;

    if (cardPosition) {
      returnArray[cardPosition - 1] = cardTask;
    }
  });

  return returnArray;
}
