import { Component, OnInit } from '@angular/core';
import { AbstractCostsComponent } from '../../../abstract/abstract-costs.component';

@Component({
  selector: 'm-ui-costs',
  templateUrl: './ui-costs.component.html',
  styleUrls: ['./ui-costs.component.scss']
})
export class MUiCostsComponent extends AbstractCostsComponent implements OnInit {
  ngOnInit() {
    this.checkAndSetDefaultValue();
    this.checkCompletedRequired();
  }
}
