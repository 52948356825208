import { Injectable } from '@angular/core';
import { BuildingDetailsConfig } from '../../../../game-engine/interfaces/building-details-config';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../store/state';
import { BuildingsService } from '../../../../services/buildings.service';
import { selectGameBoardTile } from '../../../../../../store/game/selectors';
import { filter } from 'rxjs/operators';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { BuildingData } from '../../interfaces/core/dialogs/building-data.interface';

@Injectable()
export abstract class AbstractBuildingInfoComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractBuildingInfoComponent>;
  @OwInject(MAT_DIALOG_DATA) data: BuildingData;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;

  STOCK_VIEW = STOCK_VIEW;
  buildingDetails: BuildingDetailsConfig;

  subs = {
    board: null,
  };

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, {playerTileId: this.data.playerTileId}),
        filter(state => !!state),
      )
      .subscribe((tile) => {
        this.getBuildingDetails(tile.player_building.building_id);
      });
  }

  getBuildingDetails(buildingId: number) {
    this.buildingsService.getBuildingDetails(buildingId)
      .subscribe((buildingDetails) => {
        this.buildingDetails = buildingDetails;
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }
}
