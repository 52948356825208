import { GameObjects } from 'phaser';
import { MyScene } from './MyScene';
import {
  BUILDING_LEVEL_FONT_STYLES,
  BUILDING_NAME_FONT_STYLES,
  PHASER_CAMERA_ZOOM, TILE_MENU_DEPTH
} from '../../../constants';
import { BoardTile } from '../custom/BoardTile.class';
import { MenuButton } from './MenuButton';
import { getTileMenuPositionOffset, getTileMenuPositionOffsetByBuildingIcon } from '../../utils/board.helper';
import { keepScale1 } from '../../utils/utils';
import { translate } from '../../../../../core/helpers/translate.helper';
import { TILE_MENU_ATLAS } from '../../scenes-main/main.constants';
import { isAutoProduction, isProduction } from '../../../game-gui/helpers/buildings.helper';

export class TileMenuCore extends GameObjects.Container {

  public x: number;
  public y: number;
  myScene: MyScene;
  background: GameObjects.Sprite;
  textObject: GameObjects.Text;
  baseText: string;
  additionalTextObject: GameObjects.Text;
  building;
  config: TileMenuConfig;
  twoLinesTextOffset: number;
  buttons: MenuButton[] = [];
  isDestroyed;
  private altasName = TILE_MENU_ATLAS;

  scaleFactor = 0.5;
  maxZoomScaleValue = 5;

  offset: { x: number; y: number };

  constructor(public targetTile: BoardTile, config: any) {
    super(targetTile.myScene, targetTile.x, targetTile.y);

    this.config = config;
    this.myScene = targetTile.myScene;
    this.offset = this.calculateOffset(targetTile);
    this.x += this.offset.x;
    this.y += this.offset.y;
    this.building = targetTile.playerBuildingData;
    this.depth = targetTile.depth + 100 + TILE_MENU_DEPTH;
    this.twoLinesTextOffset = 8;
    this.isDestroyed = false;

    this.baseText = this.building.name;

    this.generateAll();

    this.setScale(1 / this.myScene.cameras.main.zoom * devicePixelRatio);
    (this.scene as MyScene).phaserEvents.on(PHASER_CAMERA_ZOOM, (zoom: number) => {
      keepScale1(this, zoom, this.maxZoomScaleValue);
    });

    this.myScene.tweens.add({
      targets: [this.background, this.textObject, this.additionalTextObject],
      alpha: {from: 0, to: 1},
      duration: 250,
      ease: Phaser.Math.Easing.Expo.In,
      repeat: 0,
      delay: 10,
    });

    this.myScene.input.on('pointerup', (pointer: Phaser.Input.Pointer) => {
      if (!this.myScene.shouldCancelClickEvent(pointer.position)) {
        this.myScene.board.closeTileMenus();
      }
    });

    this.myScene.add.existing(this);
  }

  beforeGenerate() {}

  generateAll() {
    this.beforeGenerate();
    this.generateBackground('glow-big.png');
    this.generateButtons();
    this.generateText();
    this.rearrangeButtons();
  }

  rearrangeButtons() {
    const bottomButtons = this.buttons.filter(button => button.isActive && button.y > 0);
    const topButtons = this.buttons.filter(button => button.isActive && button.y < 0);

    let startX = 0;
    if (bottomButtons.length) {
      bottomButtons[0].x = 0;
      if (bottomButtons.length > 1) {
        startX = -(bottomButtons[0].displayWidth / 2) * (bottomButtons.length - 1) - 2 * bottomButtons.length;
        bottomButtons.forEach((child, index) => {
          child.x = startX + (bottomButtons[0].displayWidth * index) + 4;
        });
      }
    }

    if (topButtons.length && !isAutoProduction(this.targetTile.playerBuildingData.group_type) && !isProduction(this.targetTile.playerBuildingData.group_type)) {
      startX = 0;
      topButtons[0].x = 0;
      if (topButtons.length > 1) {
        startX = -(topButtons[0].displayWidth / 2) * (topButtons.length - 1) - 2 * topButtons.length;
        topButtons.forEach((child, index) => {
          child.x = startX + (topButtons[0].displayWidth * index) + 4;
        });
      }
    }
  }

  calculateOffset(target: BoardTile) {
    const positionOffsetByBuildingIcon = getTileMenuPositionOffsetByBuildingIcon(target.playerBuildingData.icon);
    return positionOffsetByBuildingIcon ? positionOffsetByBuildingIcon : getTileMenuPositionOffset(target.tileData.tile_type);
  }

  generateBackground(frame = 'schadow.png') {
    this.background = this.myScene.add.sprite(0, 0, this.altasName, frame);
    this.background.alpha = 0;
    this.background.scale = this.scaleFactor;
    this.y -= this.background.height / 2;
    this.add(this.background);

    this.background.setInteractive({cursor: 'pointer'});
    this.background.on('pointerup', () => {
      this.destructor();
    });
  }

  generateText() {
    const textConfig = {
      x: 0,
      y: -this.twoLinesTextOffset,
      text: this.baseText,
      origin: 0.5,
      depth: this.depth + 200,
      style: BUILDING_NAME_FONT_STYLES,
    };

    this.textObject = this.scene.make.text(textConfig);
    this.textObject.setStroke('#000', 3);
    this.textObject.setShadow(2, 2, '#00000011', 2, true, true);
    this.textObject.alpha = 0;
    this.add(this.textObject);

    const additionalTextConfig = {
      x: 0,
      y: this.twoLinesTextOffset,
      text: translate('tile-menu.level-text', [this.building.level]),
      origin: 0.5,
      depth: this.depth + 200,
      style: BUILDING_LEVEL_FONT_STYLES,
    };
    this.additionalTextObject = this.scene.make.text(additionalTextConfig);
    this.additionalTextObject.setStroke('#000', 3);
    this.additionalTextObject.setShadow(2, 2, '#00000011', 3, true, true);
    this.additionalTextObject.alpha = 0;
    this.add(this.additionalTextObject);
  }

  changeMenuTextAndHideSecondLine(text: string) {
    this.textObject.text = text;
    this.textObject.setY(0);
    this.additionalTextObject.alpha = 0;
  }

  setBasicMenuText() {
    this.textObject.text = this.baseText;
    this.textObject.setY(-this.twoLinesTextOffset);
    this.additionalTextObject.alpha = 1;
  }

  generateButtons() {
    for (const buttonConfig of this.config.buttons) {
      const button = new MenuButton(this as any, buttonConfig);
      this.buttons.push(button);
    }
  }

  destructor() {
    this.isDestroyed = true;
    this.destroy();
  }
}

export interface TileMenuConfig {
  buttons: TileMenuButtonConfig[];
}

export interface TileMenuButtonConfig {
  x: number;
  y: number;
  name: string;
  icon: string;
  frame: string;
  hoverFrame: string;
  activeFrame: string;
  eventName: string;
  playerTileId?: number;
  activeCheckFn?: Function;
  additionalBoardData?: string;
}

export interface TileMenuConfig {
  buttons: TileMenuButtonConfig[];
}
