import { AfterContentInit, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { DialogService } from '../providers/dialog.service';
import { WarehouseDetailsData } from '../../game/game-ui/warehouse/interfaces/core/dialogs/warehouse-details-data.interface';
import { EVENT_DIALOGS_NAMES_WAREHOUSE } from '../../game/game-ui/warehouse/consts/core/event-dialogs/event-names.const';
import { EventEmitterDialogsService } from '../../../core/services/core/event-emitter-dialogs.service';

@Directive({selector: '[openStorage]'})
export class OpenStorageDirective implements AfterContentInit {
  @Input('openStorage') private product: any;

  constructor(
    private elementRef: ElementRef,
    private dialogService: DialogService,
    private eventEmitterDialogsService: EventEmitterDialogsService,
  ) {
  }

  @HostListener('click', ['$event']) onClick() {
    if (this.checkProductBlockOpenStorage()) {
      return true;
    }
    event.stopPropagation();

    this.openStorageDetails();
  }

  openStorageDetails() {
    const warehouseDetailsData: WarehouseDetailsData = {
      product_id: this.product.product_id,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE_DETAILS,
      config: {
        data: warehouseDetailsData
      }
    });
  }

  ngAfterContentInit() {
    if (!this.checkProductBlockOpenStorage()) {
      this.elementRef.nativeElement.style.cursor = 'pointer';
    }
  }

  checkProductBlockOpenStorage() {
    return !this.product.product_id;
  }
}
