<div class="global-dialog ow-dialog window-a primary">
  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button *ngIf="!data.hideBackButton"></m-ui-back-button>

  <!-- TITLE -->
  <div class="title">
    {{ 'business.choose-task.title' | myTranslate }}
  </div>

  <!-- DESCRIPTION -->
  <div
    class="description"
    [innerHTML]="'business.choose-task.description' | myTranslate"
    owEllipsis
  ></div>

  <!-- TASKS -->
  <perfect-scrollbar scrollGradient *ngIf="data.cardTask">
    <table>
      <tbody>
      <tr
        *ngFor="let task of data.cardTask.other_tasks"
        (click)="openCardTask(task)"
      >
        <td class="full-width">
          {{ task.task_definition.name }}
        </td>
        <td class="no-wrap">
          <!-- SCOPE PRIZES 100% -->
          <div class="flex-center" *ngFor="let currency of task.scopesPrizes">
            <m-ui-currency
              [item]="{currency_id: currency.currency_id, amount: currency['amount'] * task.multiplier | floor}"
              [stockView]="STOCK_VIEW.H"
              [lack]="false"
            ></m-ui-currency>
          </div>
        </td>
        <td class="no-wrap">
          <!-- BASE CURRENCY PRIZES -->
          <div class="flex-center" *ngFor="let currency of task.base_currency_prizes">
            <m-ui-currency
              [item]="{currency_id: currency.currency_id, amount: currency['amount'] * task.multiplier | floor}"
              [stockView]="STOCK_VIEW.H"
              [lack]="false"
            ></m-ui-currency>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </perfect-scrollbar>
</div>

