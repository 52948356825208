import { Injectable } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { CardTask } from '../../interfaces/custom/card-task.interface';
import { Task } from '../../interfaces/custom/task.interface';
import { VALUE_TYPE } from '../../consts/custom/value-type.const';
import { Player } from '../../../../../player/interfaces/player';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { ApiBusinessService } from '../../api/custom/services/api-business.service';
import { GlobalService } from '../../../../../../core/providers/global.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';

@Injectable()
export abstract class AbstractCardTaskDetailsComponent extends AbstractInjectBaseComponent {
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(MAT_DIALOG_DATA) data: {
    cardTask: CardTask,
    task?: Task,
    cardTasks?: CardTask[],
    showOtherClose?: boolean,
    player?: Player,
    backButton?: boolean,
    hideChooseTasksButtons?: boolean,
  };

  abstract dialogService: DialogService;
  VALUE_TYPE = VALUE_TYPE;
  STOCK_VIEW = STOCK_VIEW;
  task: Task;
  cardTask: CardTask;
  cardTasks: CardTask[];
  notEmptyCardTasks: CardTask[] = [];
  currentIndex: number;

  setCurrentIndex() {
    this.notEmptyCardTasks.forEach((cardTask, index) => {
      if (this.cardTask.card_position === cardTask.card_position) {
        this.currentIndex = index;
      }
    });
  }

  setTask(task: Task) {
    this.task = task;
  }

  setCardTask(cardTask: CardTask) {
    this.cardTask = cardTask;
  }

  changeCardTask(cardTask: CardTask) {
    this.setCardTask(cardTask);
    this.setTask(cardTask.selected);
    this.setCurrentIndex();
  }

  next() {
    let index = 0;
    if (this.currentIndex < this.notEmptyCardTasks.length - 1) {
      index = this.currentIndex + 1;
    }
    this.changeCardTask(this.notEmptyCardTasks[index]);
  }

  prev() {
    let index = this.notEmptyCardTasks.length - 1;
    if (this.currentIndex > 0) {
      index = this.currentIndex - 1;
    }
    this.changeCardTask(this.notEmptyCardTasks[index]);
  }

  openInfo() {
    this.dialogService.openAlert({description: 'Cegły za zadania biznesowe (miesięczne) są naliczane wprost proporcjonalnie do realizacji. Maksymalnie za dany miesiąc możesz otrzymać 130% nagrody bazowej, np. nagroda bazowa wynosi 2000 cegieł, zadanie zostało zrealizowane na 145%, na Twoje konto zostanie naliczone 2600 cegieł.'});
  }

  // taskCancelAlert() {
  //   const description = `Czy napewno chcesz anulować to zadanie?`;
  //
  //   const buttonSuccessLabel = 'TAK';
  //
  //   this.dialogService.openAlert(
  //     {
  //       title: 'UWAGA',
  //       description: description,
  //       confirm: true,
  //       button: {success: buttonSuccessLabel, successClass: 'basic', cancel: 'NIE'},
  //       classNames: ['cancel-production']
  //     }, (data) => {
  //       if (data) {
  //         this.taskCancel();
  //       }
  //     }
  //   );
  // }

  // taskCancel() {
  //   this.tasksService.cancelTask({player_task_id: this.task.player_task_id})
  //     .then(() => {
  //       this.dialogRef.close({reload: true})
  //     }, (errResp) => {
  //       this.alertService.showError({errResp});
  //     }).then(() => {
  //     this.loading = false;
  //   })
  // }

  // cancelEvent(eventData: {cancel: boolean}) {
  //   if (eventData.cancel) {
  //     this.taskCancelAlert();
  //   }
  // }
}
