import { Component } from '@angular/core';
import { AbstractCloseButtonComponent } from '../../../abstract/abstract-close-button.component';

@Component({
  selector: 'm-ui-close-button',
  templateUrl: './ui-close-button.component.html',
  styleUrls: ['./ui-close-button.component.scss']
})
export class MUiCloseButtonComponent extends AbstractCloseButtonComponent {

}
