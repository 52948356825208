import { IsoSprite } from './IsoSprite';
import { ISO_OBJECT_DEPTH_VALUE_FACTOR } from '../../../constants/iso.constants';
import { GameService } from '../../../services/game.service';
import { MyScene } from './MyScene';
import { getMainSceneConfig } from '../../scenes-main/helpers/MainScene.helper';

export class MapObject extends IsoSprite {

  game: Phaser.Game;
  myScene;
  building;
  menu;
  tile;

  gameService: GameService;

  baseShader: Phaser.GameObjects.Shader;

  constructor(params) {
    super(params);
    this.myScene = params.scene as MyScene;
    this.game = this.myScene.sys.game;
    this.gameService = this.myScene.gameService;
    this.tile = params.tile;

    let sceneConfig = getMainSceneConfig();

    if (this.myScene.sceneConfig) {
      sceneConfig = this.myScene.sceneConfig;
    }

    this.x += sceneConfig.tiledStartOffset.x;
    this.y += sceneConfig.tiledStartOffset.y;

    this.depth = (this.x + this.y) * 10000;

    // if (params.glowShader) {
    //   this.baseShader = this.scene.add.shader('glow', this.x, this.y, this.width, this.height);
    //   this.baseShader.setOrigin(0.5, 1);
    //   this.baseShader.setScale(1);
    //   const texture = this.scene.add.renderTexture(0, 0, this.width, this.height);
    //   console.log(this);
    //   texture.drawFrame(MAP_ATLAS_NAME, 'vulcano.png');
    //
    //   this.baseShader.setChannel0(texture.texture.key);
    // }

  }

  calculateDepth() {
    this.setDepth(this.depthIndex + (this.y - this.width / 3) * ISO_OBJECT_DEPTH_VALUE_FACTOR);
  }
}

