import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../core/providers/api.service';
import { Observable } from 'rxjs';
import { ApiOptions } from '../../../../../../../core/interfaces/api-options';
import {
  DeleteMessageRequest,
  GetMessageDetailsRequest,
  GetMessagesFiltersRequest,
  GetMessagesRequest,
  PatchMarkReadMessageRequest
} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ApiMessageService {

  constructor(
    protected apiService: ApiService,
  ) {
  }

  getMessages(getMessagesRequest: GetMessagesRequest): Observable<any> {
    const options: ApiOptions = {
      params: {
        ...getMessagesRequest
      },
      responseWithHeaders: true
    };

    return this.apiService.get('messages', options);
  }

  getMessageDetails({playerMessageId}: GetMessageDetailsRequest) {
    return this.apiService.get(`messages/${playerMessageId}`);
  }

  getMessagesFilters({filter}: GetMessagesFiltersRequest) {
    return this.apiService.get(`messages/${filter}`);
  }

  deleteMessage({messageId}: DeleteMessageRequest) {
    return this.apiService.patch(`messages/${messageId}/trash`);
  }

  markRead({messageId}: PatchMarkReadMessageRequest) {
    const options: ApiOptions = {
      isNotShowLoading: true,
    };

    return this.apiService.patch(`messages/${messageId}/read`, options);
  }
}
