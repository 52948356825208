import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../../shared/shared.module';
import { WarehouseAbstractModule } from '../../abstract/core/warehouse-abstract.module';
import { CoreWarehouseComponent } from '../../base/core/dialogs/warehouse/warehouse.component';
import { CoreWarehouseDetailsComponent, } from '../../base/core/dialogs/warehouse-details/warehouse-details.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ReactiveFormsModule } from '@angular/forms';
import { VirtualKeyboardMobileModule } from '../../../virtual-keyboard/mobile/virtual-keyboard-mobile.module';
import { SharedUiMobileModule } from '../../../shared-ui/mobile/shared-ui-mobile.module';
import { CUSTOM_IMPORTS } from '../../consts/custom/imports.const';
import { CUSTOM_EXPORTS } from '../../consts/custom/exports.const';
import { CUSTOM_DECLARATIONS, CUSTOM_ENTRY_COMPONENTS } from '../../consts/custom/components.const';

@NgModule({
  declarations: [
    CoreWarehouseComponent,
    CoreWarehouseDetailsComponent,
    ...CUSTOM_DECLARATIONS,
  ],
  imports: [
    CommonModule,
    WarehouseAbstractModule,
    SharedModule,
    SwiperModule,
    ReactiveFormsModule,
    VirtualKeyboardMobileModule,
    SharedUiMobileModule,
    ...CUSTOM_IMPORTS,
  ],
  exports: [
    ...CUSTOM_EXPORTS,
  ],
  entryComponents: [
    CoreWarehouseComponent,
    CoreWarehouseDetailsComponent,
    ...CUSTOM_ENTRY_COMPONENTS,
  ]
})
export class WarehouseModule {
}
