import { Component } from '@angular/core';
import { AbstractCardTaskComponent } from '../../../../abstract/custom/abstract-card-task.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';
import { CardTaskDetailsComponent } from '../../dialogs/card-task-details/card-task-details.component';
import { ChooseTaskComponent } from '../../dialogs/choose-task/choose-task.component';

@Component({
  selector: 'card-task',
  templateUrl: './card-task.component.html',
  styleUrls: ['./card-task.component.scss']
})
export class CardTaskComponent extends AbstractCardTaskComponent {
  @OwInject(DialogService) dialogService: DialogService;
  cardTaskDetailsComponent = CardTaskDetailsComponent;
  chooseTaskComponent = ChooseTaskComponent;
}
