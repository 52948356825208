import * as PlayerActions from './actions';
import {playerReducer} from './reducer';
import {PlayerState} from './state';
import * as PlayerSelectors from './selectors';
import {PlayerEffects} from './effects';

export {
  PlayerActions,
  playerReducer,
  PlayerState,
  PlayerSelectors,
  PlayerEffects,
};