import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './providers/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { ChangePasswordComponent } from './basic/components/change-password/change-password.component';
import { ForgotPasswordComponent } from './basic/components/forgot-password/forgot-password.component';
import { RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './basic/components/reset-password/reset-password.component';
import { SharedModule } from '../shared/shared.module';
import { PlayerChooseComponent } from './basic/components/player-choose/player-choose.component';
import { ApiInterceptor } from './interceptor/api.interceptor';
import { AuthRoutingModule } from './auth-routing.module';
import { TempTokenExchangeComponent } from './basic/components/temp-token-exchange/temp-token-exchange.component';
import { RegistrationAComponent } from './basic/components/registration/variant-a/registration-a.component';
import { LoginComponent } from './basic/components/login/login.component';
import { AuthAbstractModule } from './abstract/auth-abstract.module';
import { RegistrationConfirmAComponent } from './basic/components/registration-confirm/variant-a/registration-confirm-a.component';
import { CUSTOM_PROVIDERS } from './consts/custom/providers.const';
import { CUSTOM_COMPONENTS, CUSTOM_ENTRY_COMPONENTS } from './consts/custom/components.const';
import { CUSTOM_IMPORTS } from './consts/custom/imports.const';
import { FingerprintInterceptor } from './interceptor/fingerprint.interceptor';
import { ReauthorizeComponent } from './basic/components/reauthorize/reauthorize.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    AuthAbstractModule,
    AuthRoutingModule,
    ...CUSTOM_IMPORTS,
  ],
  declarations: [
    LoginComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PlayerChooseComponent,
    TempTokenExchangeComponent,
    RegistrationConfirmAComponent,
    RegistrationAComponent,
    ReauthorizeComponent,
    ...CUSTOM_COMPONENTS,
  ],
  entryComponents: [
    ...CUSTOM_ENTRY_COMPONENTS,
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FingerprintInterceptor,
      multi: true
    },
    ...CUSTOM_PROVIDERS,
  ]
})
export class AuthModule {
}
