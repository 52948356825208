import { Component } from '@angular/core';
import { AbstractGroupCountComponent } from '../../../abstract/abstract-group-count.component';

@Component({
  selector: 'm-ui-group-count',
  templateUrl: './ui-group-count.component.html',
  styleUrls: ['./ui-group-count.component.scss']
})
export class MUiGroupCountComponent extends AbstractGroupCountComponent {
}
