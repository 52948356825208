<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- BUTTON EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <ng-container *ngIf="(qa.all_questions || qa.last_attempt.questions) as questions">

    <!--QA COUNTER, QA NAME-->
    <div class="top-bar long">
      <div *ngIf="questions.length > 1" class="absolute-left">
        <span>{{currentQuestionIndex}}/{{questions.length}}</span>
      </div>
      <div class="title">{{qa.name}}</div>
      <div *ngIf="qa.last_attempt && !qa.last_attempt.finished_at" class="absolute-right">
        <i class="fas fa-clock"></i>
        <span>{{ timer | toHour }}</span>
      </div>
    </div>

    <ng-container *ngIf="questions[currentQuestionIndex - 1] as question">

      <!-- BACK BAR -->
      <m-ui-back-button
        *ngIf="qa.all_questions || qa.last_attempt && qa.last_attempt.finished_at"
      ></m-ui-back-button>

      <!-- NAVIGATION CENTER -->
      <ng-container *ngIf="questions.length > 1">
        <m-ui-navigation-center
          (nextClicked)="checkNextQuestion()"
          (prevClicked)="prevQuestion()"
          [disabledNext]="(qa.last_attempt && !qa.last_attempt.finished_at && !(question.selected_answer || question.qa_text_answer || textAnswers[currentQuestionIndex - 1]))"
          [hideNext]="currentQuestionIndex >= questions.length"
          [hidePrev]="currentQuestionIndex - 1 === 0"
        ></m-ui-navigation-center>
      </ng-container>

      <div class="qa-container">
        <!--QUESTION-->
        <div class="elem-flex description">
          <span *ngIf="questions.length > 1" class="question-tag">{{currentQuestionIndex}}</span>

          <p
            [innerHTML]="question.content"
            owEllipsis
          ></p>
        </div>

        <!-- QUESTION IMAGE URL -->
        <div *ngIf="question.image_url">
          <div class="image-container">
            <button
              (click)="openImage(question.image_url)"
              *ngIf="question.image_url"
              class="square small primary image-url"
            >
              <i class="far fa-image"></i>
            </button>
          </div>

          <hr/>
        </div>

        <div class="wrap loading-opacity">
          <perfect-scrollbar [scrollGradient]="currentQuestionIndex">
            <!--ANSWERS-->
            <ng-container *ngIf="qa.all_questions || !question.is_open">
              <ng-container *ngFor="let answer of question.answers">
                <div class="elem-flex question">

                  <!-- ANSWER CHECKBOX WHEN START QA -->
                  <ng-container *ngIf="qa.last_attempt && !qa.last_attempt.finished_at">
                    <button
                      (click)="submitAnswer(question, answer)"
                      *ngIf="!question.is_open"
                      class="square small checkbox no-before-shadow"
                    >
                      <i *ngIf="answer.qa_answer_id === question.selected_answer?.qa_answer_id"
                         class="fas fa-circle"></i>
                    </button>
                  </ng-container>

                  <!-- ANSWER CHECKBOX WHEN SEE THE ANSWERS -->
                  <ng-container *ngIf="qa.all_questions || qa.last_attempt.finished_at">
                    <button class="square small checkbox no-before-shadow b">

                      <i
                        *ngIf="question.selected_answer && answer.is_correct || qa.all_questions && answer.is_correct"
                        [ngClass]="(qa.all_questions || !question.selected_answer) ? 'fa-check all-correct' : 'fa-check selected-correct'"
                        class="fas"
                      ></i>

                      <i
                        *ngIf="question.selected_answer && question.selected_answer.qa_answer_id === answer.qa_answer_id && !answer.is_correct"
                        [ngClass]="(answer.is_correct === null || qa.type === QA_TYPE.SURVEY) ? 'fa-circle' : 'fa-times'"
                        class="fas"
                      ></i>
                    </button>
                  </ng-container>

                  <!-- ANSWER CONTENT -->
                  <p [class.correct]="!question.selected_answer && answer.is_correct && !qa.all_questions">
                    {{answer.content}}
                  </p>
                </div>

                <div class="image-explanation-container" *ngIf="answer.image_url || answer.explanation">
                  <!-- ANSWER IMAGE URL -->
                  <button
                    (click)="openImage(answer.image_url)"
                    *ngIf="answer.image_url"
                    class="square small primary image-url"
                  >
                    <i class="far fa-image"></i>
                  </button>

                  <!-- ANSWER IMAGE URL -->
                  <button
                    (click)="openExplanation(answer)"
                    *ngIf="answer.explanation"
                    class="square small primary image-url"
                  >
                    <i class="fas fa-info"></i>
                  </button>
                </div>
              </ng-container>
            </ng-container>

            <!-- ANSWER TEXTAREA -->
            <div *ngIf="qa.last_attempt && question.is_open" class="md-form">
                <textarea [(ngModel)]="textAnswers[currentQuestionIndex - 1]"
                          [attr.disabled]="qa.last_attempt.finished_at"
                          [placeholder]="'qa.qa-details.form.placeholder' | myTranslate"
                          class="md-textarea form-control"
                          id="md-7"
                >{{ textAnswers[currentQuestionIndex - 1] }}</textarea>
            </div>
          </perfect-scrollbar>
        </div>
      </div>

      <!--BOTTOM BAR-->
      <div *ngIf="qa.last_attempt" class="bottom-bar">
        <div class="bar-button base">

          <!-- FINISH QA -->
          <ng-container *ngIf="!qa.last_attempt.finished_at">
            <button
              (click)="checkFinishQa()"
              [disabled]="!(currentQuestionIndex === questions.length) || !(question.selected_answer || question.qa_text_answer || textAnswers[currentQuestionIndex - 1])"
              class="base primary"
            >
              {{ (qa.type === QA_TYPE.QUIZ ? 'qa.qa-details.end-quiz' : 'qa.qa-details.end-survey') | myTranslate }}
            </button>
          </ng-container>

          <!-- SEE THE SCORE -->
          <ng-container *ngIf="qa.last_attempt.finished_at">
            <button
              (click)="seeScore(false)"
              class="base primary"
            >
              {{ 'qa.qa-details.see-score' | myTranslate }}
            </button>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
