import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ActionTypes, SocketDisconnected } from "./actions";
import { WebSocketService } from "../../core/providers/websocket.service";
import { map, tap } from "rxjs/operators";

@Injectable()
export class SocketEffects {

  constructor(
    private actions$: Actions,
    private webSocketService: WebSocketService,
  ) {
  }

  @Effect({dispatch: false})
  socketConnect = this.actions$
    .pipe(
      ofType(ActionTypes.SOCKET_CONNECT),
      tap(() => {
        this.webSocketService.connect().subscribe();
      })
    );

  @Effect({dispatch: false})
  socketDisconnected = this.actions$
    .pipe(
      ofType(ActionTypes.SOCKET_DISCONNECTED),
      tap(() => {
        this.webSocketService.disconnect()
      })
    );

  @Effect()
  socketOtherSession = this.actions$
    .pipe(
      ofType(ActionTypes.SOCKET_OTHER_SESSION),
      map(() => {
        return new SocketDisconnected();
      })
    );
}
