import { Injectable, Input } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { Player } from '../../../../../player/interfaces/player';
import { User } from '../../../../../user/interfaces/user';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { AppState } from '../../../../../../store/state';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { CurrencyBalance, CurrencyDefinition } from '../../../../../../core/interfaces/currency';
import * as R from 'ramda';
import { PrimarySelectors } from '../../../../../../store/primary';
import { PlayerService } from '../../../../../player/providers/player.service';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY } from '../../../transaction-history/consts/core/event-dialogs/event-names.const';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export abstract class AbstractHudResourcesComponent extends AbstractInjectBaseComponent {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(Store) store: Store<AppState>;

  currencyDefinitions: CurrencyDefinition[] = [];
  @Input() player: Player;
  @Input() user: User;

  subs = {
    currencies: null,
  };

  openHistoryTransaction(currency: CurrencyBalance, tooltip?: NgbTooltip) {
    if (currency.show_currency_history) {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY.HISTORY,
        config: {
          data: {currency}
        },
        callback: () => {
          if (tooltip) {
            tooltip.close();
          }
        }
      });
    }
  }

  subscribeCurrencies() {
    this.subs.currencies = this.store
      .pipe(
        select(PrimarySelectors.selectCurrencies),
        take(1),
      )
      .subscribe((currencyDefinitions) => {
        this.currencyDefinitions = currencyDefinitions;
        this.filterHiddenResource();
        this.sortCurrency();
      });
  }

  filterHiddenResource() {
    this.currencyDefinitions = this.currencyDefinitions.filter(currency => currency.position >= 0);
  }

  sortCurrency() {
    this.currencyDefinitions = R.sortBy(R.prop('position'), this.currencyDefinitions);
  }
}
