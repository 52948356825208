import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { Injectable } from '@angular/core';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { ApiMessageService } from '../../api/core/services/api-message.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageDetails } from '../../interfaces/core/message-details.interface';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { MESSAGE_EVENTS } from '../../consts/core/message-events.const';
import { MessageOpenDetailsData } from '../../interfaces/core/message-open-details-data.interface';
import { MESSAGE_TYPE_ID } from '../../consts/core/message-type.const';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';

@Injectable()
export abstract class AbstractMessageDetailsBaseComponent extends AbstractInjectBaseComponent {
  @OwInject(ApiMessageService) apiMessageService: ApiMessageService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractMessageDetailsBaseComponent>;
  @OwInject(MAT_DIALOG_DATA) messageOpenDetailsData: MessageOpenDetailsData;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  MESSAGE_TYPE_ID = MESSAGE_TYPE_ID;
  message: MessageDetails = this.messageOpenDetailsData.message;
  closeEvent: any;

  afterInit() {
    this.setReaded();
  }

  moveToTrashRequest() {
    return this.apiMessageService.deleteMessage({messageId: this.message.player_message_id});
  }

  markReadRequest() {
    return this.apiMessageService.markRead({messageId: this.message.player_message_id});
  }

  moveToTrash() {
    this.moveToTrashRequest()
      .subscribe(() => {
        this.closeEvent = {action: MESSAGE_EVENTS.MOVED_TO_TRASH};
        this.close();
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }

  setReaded() {
    if (!this.message.read) {
      this.markReadRequest()
        .subscribe(() => {
          this.closeEvent = {action: MESSAGE_EVENTS.MARKED_READ};
        }, (errResp) => {
          if (errResp.status !== 409) {
            this.dialogService.openAlertErrorApi({errResp});
          }
        });
    }
  }

  close() {
    this.matDialogRef.close(this.closeEvent);
  }

  closeOrCloseAll() {
    if (this.messageOpenDetailsData.fromList) {
      this.dialogService.closeAll();
    } else {
      this.close();
    }
  }
}
