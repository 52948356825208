import { Injectable } from '@angular/core';
import { AbstractRankDetailsBase } from './abstract-rank-details-base.component';
import { OwInject } from '../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FILTER_TYPE } from '../consts/filter-type.const';
import { RankBusiness, RankItemBusiness } from '../interfaces';
import { RankDetailsData } from '../interfaces/dialogs/rank-details-data.interface';
import { ApiBusinessRanksService } from '../api/services/api-business-ranks.service';

@Injectable()
export abstract class AbstractRankDetailsBusinessComponent extends AbstractRankDetailsBase {
  @OwInject(ApiBusinessRanksService) apiBusinessRanksService: ApiBusinessRanksService;
  @OwInject(MAT_DIALOG_DATA) data: RankDetailsData & { rank: RankBusiness };
  rankDetails: { my_position: RankItemBusiness; results: RankItemBusiness[]; is_rank_with_min: boolean };

  getRank() {
    this.getBusinessRank();
  }

  getBusinessRank() {
    const rank = this.data.rank;

    const requestOptions = {
      taskDefinitionId: rank.task_definition_id,
      year: rank.year,
      month: rank.month,
      limit: this.limit,
      branch: this.filters[FILTER_TYPE.A],
      region: this.filters[FILTER_TYPE.B],
      makroregion: this.filters[FILTER_TYPE.C],
    };

    let apiRequest;
    if (rank.is_branch_rank) {
      apiRequest = this.apiBusinessRanksService.businessRanksBranchResults(requestOptions);
    } else {
      apiRequest = this.apiBusinessRanksService.businessRanksPlayerResults(requestOptions);
    }

    apiRequest
      .subscribe((resp) => {
        this.rankDetails = resp;
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }
}
