import {createSelector} from '@ngrx/store';
import {PasswordState} from './state';
import {AuthState} from '../state';
import {selectAuthState} from '../selectors';

export const selectChangePasswordState = createSelector(
  selectAuthState,
  (state: AuthState): PasswordState => state.changePassword,
);

const getIsChanging = (state: PasswordState): boolean => state.isChanging;
export const selectIsChanging = createSelector(
  selectChangePasswordState,
  getIsChanging
);