<ng-container *ngIf="playerService.player as player">
  <!-- QUIZ -->
  <button
    *ngIf="FEATURE_FLAG.HUD.QUIZ"
    class="wipe"
    (click)="openQaList()"
    [matTooltip]="'hud-menu.qa.tooltip' | myTranslate"
    matTooltipPosition="right"
    matTooltipShowDelay="300"
  >
    <ow-object-svg [svgObject]="OW_ASSETS_SVG['/assets/ui/quiz.svg']"></ow-object-svg>
  </button>

  <!-- WORLD MAP -->
  <button
    *ngIf="player['show_world_map'] && sceneKey !== WORLD_BOOT_SCENE"
    class="wipe"
    (click)="changeScene()"
    [matTooltip]="'hud-menu.world.tooltip' | myTranslate"
    matTooltipPosition="right"
    matTooltipShowDelay="300"
  >
    <ow-object-svg [svgObject]="OW_ASSETS_SVG['/assets/ui/world.svg']"></ow-object-svg>
  </button>

  <!-- CITY MAP -->
  <button
    *ngIf="sceneKey !== MAIN_BOOT_SCENE"
    class="wipe"
    (click)="changeScene()"
    [matTooltip]="'hud-menu.city.tooltip' | myTranslate"
    matTooltipPosition="right"
    matTooltipShowDelay="300"
  >
    <ow-object-svg [svgObject]="OW_ASSETS_SVG['/assets/ui/city.svg']"></ow-object-svg>
  </button>
</ng-container>

<ng-template #notificationTpl>
  <i class="fas fa-exclamation icon notification"></i>
</ng-template>
