import { Injectable, Input } from '@angular/core';
import { SwiperComponent } from 'ngx-swiper-wrapper';

@Injectable()
export abstract class AbstractSliderDotsComponent {
  @Input() sliderRef: SwiperComponent;
  @Input() stroke = true;
  currentIndex = 0;
  elements: number[];

  compareElements() {
    setTimeout(() => {
      if (!this.sliderRef.directiveRef.swiper()) {
        return;
      }

      if (this.elements !== this.sliderRef.directiveRef.swiper().slides.length) {
        this.sliderRef.S_INIT.emit();
      }
      this.compareElements();
    }, 500);
  }

  setElements() {
    this.elements = Array(this.sliderRef.directiveRef.swiper().slides.length).fill(1);
  }

  setCurrentIndex() {
    this.currentIndex = this.sliderRef.directiveRef.swiper().realIndex;
  }
}
