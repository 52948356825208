import { Component } from '@angular/core';
import { AbstractBuildingProductionCyclesComponent } from '../../../../abstract/core/abstract-building-production-cycles.component';

@Component({
  selector: 'm-building-production-cycles',
  templateUrl: './building-production-cycles.component.html',
  styleUrls: ['./building-production-cycles.component.scss']
})
export class MBuildingProductionCyclesComponent extends AbstractBuildingProductionCyclesComponent {
}
