import { ComponentFactoryResolver, Injectable, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { AppState } from '../../../../../../store/state';
import { select, Store } from '@ngrx/store';
import { unsubscribeObject } from '../../../../../../core/utility/unsubscribe-array';
import { PlayerSelectors } from '../../../../../../store/player';
import { UserSelectors } from '../../../../../../store/user';
import { User } from '../../../../../user/interfaces/user';
import { Player } from '../../../../../player/interfaces/player';
import { ChestService } from '../../../chest/services/core/chest.service';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { BuildingsService } from '../../../../services/buildings.service';
import { selectGameBoardTile } from '../../../../../../store/game/selectors';
import { take } from 'rxjs/operators';
import { GameService } from '../../../../services/game.service';
import { BoardService } from '../../../../services/board.service';
import { EVENTS } from '../../../../../../core/consts/core/events';
import { TryUpdatePlayer } from '../../../../../../store/player/actions';
import { UtilityActions } from '../../../../../../store/utility';
import { GAME_EVENTS } from '../../../../constants';
import { isTileActive, isTileBuyable } from '../../../../game-engine/utils/game.helper';
import { GlobalEvent } from '../../../../../../core/interfaces/shared.interfaces';
import { GlobalService } from '../../../../../../core/providers/global.service';
import { MISSION_TYPE } from '../../../mission/consts/core';
import { BuildingData } from '../../../buildings/interfaces/core/dialogs/building-data.interface';
import { BuildingMoveData } from '../../../buildings/interfaces/core/dialogs/building-move-data.interface';
import { TileBuyData } from '../../../buildings/interfaces/core/dialogs/tile-buy-data.interface';
import { CORE_DYNAMIC_COMPONENTS } from '../../const/core/dynamic-components/hud/dynamic-components.const';
import { CUSTOM_DYNAMIC_COMPONENTS } from '../../const/custom/dynamic-components/hud/dynamic-components.const';
import { setDynamicsComponents } from '../../../../../../core/helpers/dynamic-component.helper';
import { FEATURE_FLAG } from '../../../../../../core/config/core/feature-flags.config';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { EVENT_DIALOGS_NAMES_BUILDINGS } from '../../../buildings/consts/core/event-dialogs/event-names.const';
import { EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE } from '../../../currency-exchange/consts/core/event-dialogs/event-names.const';
import { ADDITIONAL_BOARD_DATA } from '../../../../game-engine/config/additional-board-data.config';
import { EVENT_DIALOGS_NAMES_WAREHOUSE } from '../../../warehouse/consts/core/event-dialogs/event-names.const';
import { EVENT_DIALOGS_NAMES_MISSION } from '../../../mission/consts/core/event-dialogs/event-names';
import { EVENT_DIALOGS_NAMES_QA } from '../../../qa/consts/core/event-dialogs/event-names.const';

@Injectable()
export abstract class AbstractHudComponent extends AbstractInjectBaseComponent implements OnDestroy {
  @OwInject(Store) store: Store<AppState>;
  @OwInject(ChestService) chestService: ChestService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(GameService) gameService: GameService;
  @OwInject(BoardService) boardService: BoardService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(ComponentFactoryResolver) componentFactoryResolver: ComponentFactoryResolver;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @ViewChild('hudMenuTpl', {static: true, read: ViewContainerRef}) hudMenuTpl: ViewContainerRef;
  @ViewChild('hudParametersTpl', {static: true, read: ViewContainerRef}) hudParametersTpl: ViewContainerRef;
  @ViewChild('dynamicsComponentTpl', {static: true, read: ViewContainerRef}) dynamicsComponentTpl: ViewContainerRef;

  FEATURE_FLAG = FEATURE_FLAG;

  subs = {
    player: null,
    user: null,
    globalEmitter: null,
  };

  player: Player;
  user: User;

  setDynamicsComponents() {
    setDynamicsComponents.bind(this)({
      coreDynamicComponents: CORE_DYNAMIC_COMPONENTS,
      customDynamicComponents: CUSTOM_DYNAMIC_COMPONENTS
    });
  }

  subscribePlayer() {
    this.subs.player = this.store
      .pipe(
        select(PlayerSelectors.selectPlayer),
      )
      .subscribe((player) => {
        this.player = player;
        this.chestService.checkChestsAndOpen(player);
      });
  }

  subscribeUser() {
    this.subs.user = this.store
      .pipe(
        select(UserSelectors.selectUser),
      )
      .subscribe((user) => {
        this.user = user;
      });
  }

  /**
   * Handle Game Events from global emitter.
   * @param event
   */
  async handleGameEvents(event: GlobalEvent) {
    switch (event.name) {

      case GAME_EVENTS.BUILDING_INFO:
        this.openBuildingInfo(event);
        break;

      case GAME_EVENTS.BUILDING_UPGRADE:
        this.openBuildingUpgrade(event);
        break;

      case GAME_EVENTS.BUILDING_SPECIAL_UPGRADE:
        this.openBuildingSpecialUpgrade(event);
        break;

      case GAME_EVENTS.BUILDING_UPGRADE_WITH_PROBABILITY:
        this.openBuildingProbabilityUpgrade(event);
        break;

      case GAME_EVENTS.BUILDING_UPGRADE_SPECIAL_INCREASE:
        this.openBuildingSpecialIncrease(event);
        break;

      case GAME_EVENTS.BUILDING_PRODUCTION:
        this.openBuildingProduction(event);
        break;

      case GAME_EVENTS.OPEN_PLAYER_MISSION:
        this.openPlayerMission(event);
        break;

      case GAME_EVENTS.BUILDING_AUTO_PRODUCTION:
        this.openBuildingAutoProduction(event);
        break;

      case GAME_EVENTS.OPEN_TILE_MENU:
        if (this.gameService.game.currentScene.board) {
          this.gameService.game.currentScene.board.closeTileMenus();
        }
        event.value.openTileMenu();
        break;

      case GAME_EVENTS.TILE_CLICK:
        const tile = await this.store
          .pipe(
            select(selectGameBoardTile, {tileId: event.value}),
            take(1)
          ).toPromise();

        if (isTileBuyable(tile.state)) {
          const tileBuyData: TileBuyData = {
            tile
          };

          this.eventEmitterDialogsService.emitter.emit({
            name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_TILE_BUY,
            config: {
              data: tileBuyData,
            }
          });
        } else if (isTileActive(tile.state)) {
          const buildingData: BuildingData = {
            playerTileId: tile.player_tile_id
          };

          this.eventEmitterDialogsService.emitter.emit({
            name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_LIST,
            config: {
              data: buildingData,
            }
          });
        }

        break;

      case GAME_EVENTS.BUILDING_DELETE:
        this.openBuildingDelete(event);
        break;

      case GAME_EVENTS.BUILDING_MOVE:
        this.openBuildingMove(event);
        break;

      case EVENTS.GAME.BUILDING_MENU_CLICK_ACTION.OPEN_FUNCTIONAL:
        this.openFunctional(event);
        break;

      case EVENTS.GAME.BUILDING_MENU_CLICK_ACTION.OPEN_BHP:
        alert('BHP COMPONENT HERE');
        break;

      case EVENTS.GLOBAL.UPDATE_PLAYER:
        this.store.dispatch(new TryUpdatePlayer(event.value));
        break;

      case EVENTS.GLOBAL.MISSION_COMPLETED:
        if (event.value.mission_type === MISSION_TYPE.ONE) {
          this.store.dispatch(new UtilityActions.SetHasMissionsToCollect(true));

          this.store.dispatch(new UtilityActions.AddMissionToOpen({
            player_mission_id: event.value.player_mission_id,
            slot: {
              mission_slot_id: event.value.mission_slot_id,
              type: event.value.mission_type,
            }
          }));
        }
        break;

      case EVENTS.GLOBAL.PLAYER_MISSION_SHOW_DETAILS:
        this.store.dispatch(new UtilityActions.AddMissionToOpen({
          player_mission_id: event.value.player_mission_id,
          slot: {
            mission_slot_id: event.value.mission_slot_id,
            type: event.value.mission_type,
          }
        }));
        break;

      case EVENTS.GLOBAL.NEW_PRODUCTS_AT_LEVEL:
        this.store.dispatch(new UtilityActions.AddNewProductsInStorage(event.value.products));
        break;
    }
  }

  openFunctional(event: GlobalEvent) {
    this.openExchange(event);
    this.openStorage(event);
    this.openQaLocation(event);
  }

  openStorage(event: GlobalEvent) {
    if (event.data.player_building.additional_board_data.hasOwnProperty(ADDITIONAL_BOARD_DATA.OPEN_STORAGE_ID)) {
      const storageId = event.data.player_building.additional_board_data[ADDITIONAL_BOARD_DATA.OPEN_STORAGE_ID];

      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE,
        config: {
          data: {
            storageId,
          },
        }
      });
    }
  }

  openExchange(event: GlobalEvent) {
    if (event.data.player_building.additional_board_data.hasOwnProperty(ADDITIONAL_BOARD_DATA.OPEN_EXCHANGE)) {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE.CURRENCY_EXCHANGE,
      });
    }

    if (event.data.player_building.group === 'bank') {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE.CURRENCY_EXCHANGE,
      });
    }
  }

  openQaLocation(event: GlobalEvent) {
    if (event.data.player_building.additional_board_data.hasOwnProperty(ADDITIONAL_BOARD_DATA.OPEN_QA_LOCATION_ID)) {
      const qaLocationId = event.data.player_building.additional_board_data[ADDITIONAL_BOARD_DATA.OPEN_QA_LOCATION_ID];

      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_QA.QA_LIST,
        config: {
          data: {
            location: qaLocationId,
          }
        }
      });
    }
  }

  openBuildingDelete(event: GlobalEvent) {
    const buildingDemolishData: BuildingData = {
      playerTileId: event.value
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_DEMOLISH,
      config: {
        data: buildingDemolishData,
      }
    });
  }

  openBuildingMove(event: GlobalEvent) {
    const buildingMoveData: BuildingMoveData = {
      tileId: event.value
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_MOVE,
      config: {
        data: buildingMoveData,
      }
    });
  }

  openBuildingAutoProduction(event: GlobalEvent) {
    const buildingAutoProductionData: BuildingData = {
      playerTileId: event.value,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_AUTO_PRODUCTION,
      config: {
        data: buildingAutoProductionData,
      }
    });
  }

  openBuildingProduction(event: GlobalEvent) {
    const buildingProductionData: BuildingData = {
      playerTileId: event.value,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_PRODUCTION,
      config: {
        data: buildingProductionData,
      }
    });
  }

  openBuildingSpecialUpgrade(event: GlobalEvent) {
    const buildingSpecialData: BuildingData = {
      playerTileId: event.value,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_SPECIAL_UPGRADE,
      config: {
        data: buildingSpecialData,
      }
    });
  }

  openBuildingProbabilityUpgrade(event: GlobalEvent) {
    const buildingProbabilityData: BuildingData = {
      playerTileId: event.value,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_PROBABILITY_UPGRADE,
      config: {
        data: buildingProbabilityData,
      }
    });
  }

  openBuildingSpecialIncrease(event: GlobalEvent) {
    const buildingSpecialIncreaseData: BuildingData = {
      playerTileId: event.value,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_SPECIAL_INCREASE,
      config: {
        data: buildingSpecialIncreaseData,
      }
    });
  }

  openBuildingUpgrade(event: GlobalEvent) {
    const buildingUpgradeData: BuildingData = {
      playerTileId: event.value,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_UPGRADE,
      config: {
        data: buildingUpgradeData,
      }
    });
  }

  openBuildingInfo(event: GlobalEvent) {
    const buildingInfoData: BuildingData = {
      playerTileId: event.value,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_INFO,
      config: {
        data: buildingInfoData,
      }
    });
  }

  openPlayerMission(event: GlobalEvent) {
    const missionDetailsData = {
      mission: {
        player_mission_id: event.value,
      }
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MISSION.MISSION_DETAILS,
      config: {
        data: missionDetailsData,
      }
    });
  }

  ngOnDestroy(): void {
    unsubscribeObject(this.subs);
  }
}
