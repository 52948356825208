<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- BUTTON EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button *ngIf="data.isShowBackButton"></m-ui-back-button>

  <ng-container *ngIf="allQa">

    <!-- NAVIGATION CENTER -->
    <m-ui-navigation-center
      [hidePrev]="currentPage === 0"
      [hideNext]="currentPage >= allQa.length - 1"
      (prevClicked)="changePage(currentPage-1)"
      (nextClicked)="changePage(currentPage+1)"
    ></m-ui-navigation-center>

    <!-- ALL QA LIST-->
    <ng-container *ngIf="activeQa">
      <div class="wrapper loading-opacity">

        <!-- QA NAME -->
        <div class="title quiz" [innerHTML]="activeQa.name"></div>

        <!-- QA DESCRIPTION -->
        <div
          *ngIf="activeQa.description"
          class="inner-html-description"
          owEllipsis
          [innerText]="activeQa.description"
          [textAlign]="'initial'"
        ></div>

        <div *ngIf="activeQa.can_answer_from && activeQa.can_answer_to" class="duration">
          <p>{{ (activeQa.type === QA_TYPE.QUIZ ? 'qa.qa-list.expiry-date-quiz' : 'qa.qa-list.expiry-date-survey') | myTranslate:[canAnswerFrom, canAnswerTo] }}</p>
        </div>

        <!-- SCORE, TIME AND PRIZES -->
        <app-m-qa-summary
          [qa]="activeQa"
        ></app-m-qa-summary>
      </div>

      <!-- BOTTOM BAR -->
      <div class="bottom-bar">

        <!-- SEE THE ANSWERS WHEN QA EXPIRED AND DONE ATTEMPT = 0 -->
        <div class="bar-button base" *ngIf="activeQa.all_questions">
          <button class="base primary" (click)="openQaDetails()">
            {{ 'qa.qa-list.check-answers' | myTranslate }}
          </button>
        </div>

        <!-- SEE THE ANSWERS / RESUME QA-->
        <div class="bar-button base" *ngIf="activeQa.last_attempt as qa">
          <button
            class="base primary"
            (click)="openQaDetails()"
            [disabled]="qa.finished_at && activeQa.type === QA_TYPE.QUIZ && activeQa.hide_answers_until_quiz_finish && !isExpiredQa"
          >
            <ng-container *ngIf="qa.finished_at">
              {{ 'qa.qa-list.check-answers' | myTranslate }}
            </ng-container>
            <ng-container *ngIf="qa.started_at && !qa.finished_at">
              {{ (activeQa.type === QA_TYPE.QUIZ ? 'qa.qa-list.resume-quiz' : 'qa.qa-list.resume-survey') | myTranslate }}
            </ng-container>
          </button>
        </div>

        <!-- START QA-->
        <div class="bar-button base" *ngIf="!activeQa.last_attempt && !isExpiredQa">
          <button class="base primary" (click)="startQa()">
            {{ (activeQa.type === QA_TYPE.QUIZ ? 'qa.qa-list.start-quiz' : 'qa.qa-list.start-survey') | myTranslate }}
          </button>
        </div>

        <!-- IMPROVE QA-->
        <div
          class="bar-button base"
          *ngIf="activeQa.done_attempts != 0 && activeQa.done_attempts < activeQa.max_attempts && activeQa.last_attempt?.finished_at && activeQa.last_attempt?.player_points < activeQa.last_attempt?.max_points">
          <button class="base primary" (click)="startQa()">
            {{ (activeQa.type === QA_TYPE.QUIZ ? 'qa.qa-list.improve-quiz' : 'qa.qa-list.improve-survey') | myTranslate }}
          </button>
        </div>
      </div>
    </ng-container>

    <!-- NO QA AVAILABLE-->
    <ng-container *ngIf="allQa.length === 0">
      <div class="title margin-top">{{ 'qa.qa-list.no-qa-available' | myTranslate }}</div>
    </ng-container>
  </ng-container>
</div>
