import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardTaskComponent } from '../../basic/custom/components/card-task/card-task.component';
import { CardTaskDetailsComponent } from '../../basic/custom/dialogs/card-task-details/card-task-details.component';
import { BusinessCardsComponent } from '../../basic/custom/components/business-cards/business-cards.component';
import { ChooseTaskComponent } from '../../basic/custom/dialogs/choose-task/choose-task.component';
import { SharedUiMobileModule } from '../../../shared-ui/mobile/shared-ui-mobile.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { BasicActionsComponent } from '../../basic/custom/components/basic-actions/basic-actions.component';

@NgModule({
  imports: [
    CommonModule,
    SharedUiMobileModule,
    SharedModule,
  ],
  declarations: [
    CardTaskComponent,
    CardTaskDetailsComponent,
    BusinessCardsComponent,
    ChooseTaskComponent,
    BasicActionsComponent,
  ],
  entryComponents: [
    BusinessCardsComponent,
    CardTaskDetailsComponent,
    ChooseTaskComponent,
    BasicActionsComponent,
  ],
  exports: [
    BusinessCardsComponent,
  ]
})
export class BusinessModule {
}
