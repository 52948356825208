<div class="recipe" [class.blur]="isActiveMultiView">
  <div class="production-container arrow"></div>

  <!-- SINGLE PRODUCT PRIZE CONDITION -->
  <ng-container *ngIf="production.product_prizes.length === 1 && !production.currency_prizes.length">
    <m-ui-product
      [item]="production.product_prizes[0]"
      [other]="{value: 'x'}"
      [stockView]="STOCK_VIEW.C"
      [lack]="false"
    ></m-ui-product>
  </ng-container>

  <!-- SINGLE CURRENCY PRIZE CONDITION -->
  <ng-container *ngIf="production.currency_prizes.length === 1 && !production.product_prizes.length">
    <m-ui-currency
      [item]="production.currency_prizes[0]"
      [other]="{value: 'x'}"
      [stockView]="STOCK_VIEW.C"
      [lack]="false"
    ></m-ui-currency>
  </ng-container>

  <!-- MULTIPLE PRODUCT PRIZES CONDITION -->
  <ng-container
    *ngIf="production.product_prizes.length > 1 || production.currency_prizes.length > 1 || (production.product_prizes.length === 1 && production.currency_prizes.length === 1)">
    <!-- MULTIPLE PRIZES ICON -->
    <m-ui-multi-stock
      [items]="production"
      [stockView]="STOCK_VIEW.C"
      (isActiveMultiView)="isActiveMultiView = $event "
    ></m-ui-multi-stock>
  </ng-container>

  <!-- EQUAL ICON -->
  <i class="far fa-equals" *ngIf="requiredStatusCount > 0"></i>

  <!-- CURRENCY PRICES -->
  <m-ui-currency
    *ngFor="let currency of production.production_currency_prices"
    [item]="currency"
    [stockView]="STOCK_VIEW.A"
    (itemChanged)="checkCompleted($event)"
    [observable]="true"
    class="ingredient"
  >
  </m-ui-currency>

  <!-- PRODUCT PRICES -->
  <m-ui-product
    *ngFor="let product of production.production_product_prices"
    [item]="product"
    [stockView]="STOCK_VIEW.A"
    (itemChanged)="checkCompleted($event)"
    [observable]="true"
    class="ingredient"
  >
  </m-ui-product>
</div>
