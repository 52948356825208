export const OrientationManager = (function () {
  "use strict";

  var supportsOrientationChange = window.hasOwnProperty("onorientationchange")
    , orientationEvent = supportsOrientationChange ? "orientationchange" : "resize"
    , currentOrientation = window.orientation || 0
    , currentWidth = window.innerWidth


  function orientationChanged() {
    var newWidth = window.innerWidth
      , newOrientation = window.orientation || 0;

    console.log(currentWidth, newWidth, currentOrientation, newOrientation)
    // only change orientation and call provided callback if necessary
    if (newOrientation !== currentOrientation || newWidth !== currentWidth) {
      currentOrientation = newOrientation;
      currentWidth = newWidth;
      return true;
    }
    return false;
  }

  // exports
  return {
    supportsOrientationChange: supportsOrientationChange
    , orientation: currentOrientation
    , orientationEvent: orientationEvent
    , orientationChanged: orientationChanged
  };
} ());
