import { Component } from '@angular/core';
import { AbstractNavigationComponent } from '../../../abstract/abstract-navigation.component';

@Component({
  selector: 'm-ui-navigation',
  templateUrl: './ui-navigation.component.html',
  styleUrls: ['./ui-navigation.component.scss']
})
export class MUiNavigationComponent extends AbstractNavigationComponent {
}
