import { Component, OnInit } from '@angular/core';
import { AbstractRankDetailsGameComponent } from '../../../abstract/abstract-rank-details-game.component';

@Component({
  selector: 'm-rank-details-game',
  templateUrl: './rank-details-game.component.html',
  styleUrls: ['./rank-details-game.component.scss']
})
export class MRankDetailsGameComponent extends AbstractRankDetailsGameComponent implements OnInit {
  ngOnInit() {
    this.changeFilter(this.defaultFilter);
  }
}
