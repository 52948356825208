import { Injectable } from '@angular/core';
import { getToken } from '../../../../core/utility/token';
import * as moment from 'moment';
import { OwDate } from '../../../../core/utility/ow-date.class';
import { Router } from '@angular/router';
import { AUTH_ROUTING_ABSOLUTE_PATH } from '../../consts/core/routing-auth-absolute.const';
import { AuthService } from '../../providers/auth.service';
import { GlobalService } from '../../../../core/providers/global.service';
import { EVENTS } from '../../../../core/consts/core/events';

@Injectable({
  providedIn: 'root'
})
export class ReauthorizeService {
  isAllowReauthorize: boolean;
  reconnectSocket: boolean;
  timeReconnectedSecondsToReauthorize = 5;

  constructor(
    private router: Router,
    private authService: AuthService,
    private globalService: GlobalService,
  ) {
    this.subscribeGlobalEmitter();
  }

  subscribeGlobalEmitter() {
    this.globalService.globalEvents.subscribe((event) => {
      switch (event.name) {
        case EVENTS.GLOBAL.RECONNECTED:
          const {lastDisconnectedDate, lastConnectedDate} = event.value;

          if (lastDisconnectedDate) {
            const diffTime = moment(lastConnectedDate).diff(moment(lastDisconnectedDate), 's');

            if (diffTime > this.timeReconnectedSecondsToReauthorize) {
              this.reconnectSocket = true;
              this.checkReauthorizeAfter();
            }
          }
          break;
      }
    });
  }

  checkReauthorizeAfter() {
    const reauthorizeAfterDate = getToken().reauthorize_after;

    if (reauthorizeAfterDate) {
      const diffTime = moment(reauthorizeAfterDate).diff(new OwDate().momentDate);
      this.isAllowReauthorize = diffTime < 0;
    }

    return this.checkLogicReauthorize();
  }

  checkLogicReauthorize() {
    if (this.reconnectSocket && this.isAllowReauthorize) {
      return this.redirectToReauthorize();
    } else if (this.isAllowReauthorize) {
      return this.reauthorize();
    }

    return true;
  }

  redirectToReauthorize() {
    this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.REAUTHORIZE]);
    return false;
  }

  reauthorize(): Promise<boolean> {
    return new Promise((resolve) => {
      this.authService.reauthorize().subscribe(() => {
        resolve(true);
      }, () => {
        resolve(false);
      });
    });
  }
}
