import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { PlayerService } from '../../../../../player/providers/player.service';
import { Player } from '../../../../../player/interfaces/player';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { MatDialogRef } from '@angular/material';

export abstract class AbstractUserSettingsComponent extends AbstractInjectBaseComponent {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractUserSettingsComponent>;
  @OwInject(FormBuilder) formBuilder: FormBuilder;

  player: Player = this.playerService.player;
  nickForm: FormGroup;

  createForm() {
    this.nickForm = this.formBuilder.group({
      'farm_name': [this.player.farm_name, [Validators.required, Validators.maxLength(23)]],
    });
  }

  changeNick() {
    const farm_name = this.nickForm.controls.farm_name.value;

    this.playerService.patchPlayer({farm_name})
      .subscribe((resp) => {
        this.matDialogRef.close();
        this.dialogService.openAlert({description: 'Nick został zmieniony'});
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }
}
