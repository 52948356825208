<div
  [ngSwitch]="stockView"
  [matTooltip]="item.name"
  [matTooltipShowDelay]="400"
  [openStorage]="!lockOpenStorage && item"
>

  <!-- A -->
  <div
    *ngSwitchCase="STOCK_VIEW.A"
    [ngClass]="STOCK_VIEW.A"
    [class.lack]="lack && !item.have && !item.showBalance"
  >
    <i
      class="icon"
      [style.background-image]="'url('+item.iconUrl+')'"
    >
      <ng-container *ngTemplateOutlet="iconLackTpl"></ng-container>
    </i>
    <ng-container *ngTemplateOutlet="valueTpl"></ng-container>
  </div>

  <!-- B -->
  <div
    *ngSwitchCase="STOCK_VIEW.B"
    [ngClass]="STOCK_VIEW.B"
    [class.lack]="lack && !item.have && !item.showBalance"
  >
    <i
      class="icon"
      [style.background-image]="'url('+item.iconUrlBig+')'"
    >
      <ng-container *ngTemplateOutlet="iconLackTpl"></ng-container>
    </i>
    <ng-container *ngTemplateOutlet="valueTpl"></ng-container>
  </div>

  <!-- C -->
  <div
    *ngSwitchCase="STOCK_VIEW.C"
    [ngClass]="STOCK_VIEW.C"
    [class.lack]="lack && !item.have && !item.showBalance"
  >
    <i
      class="icon"
      [style.background-image]="'url('+item.iconUrlBig+')'"
    >
      <ng-container *ngTemplateOutlet="iconLackTpl"></ng-container>
      <ng-container *ngTemplateOutlet="valueTpl"></ng-container>
    </i>
  </div>

  <!-- D -->
  <div
    *ngSwitchCase="STOCK_VIEW.D"
    [ngClass]="STOCK_VIEW.D"
    [class.lack]="lack && !item.have && !item.showBalance"
  >
    <i
      class="icon"
      [style.background-image]="'url('+item.iconUrl+')'"
    >
      <ng-container *ngTemplateOutlet="iconLackTpl"></ng-container>
    </i>
    <div class="box">
      <ng-container *ngTemplateOutlet="valueTpl"></ng-container>
    </div>
  </div>

  <!-- E -->
  <div
    *ngSwitchCase="STOCK_VIEW.E"
    [ngClass]="STOCK_VIEW.E"
    [class.lack]="lack && !item.have && !item.showBalance"
  >
    <i
      class="icon"
      [style.background-image]="'url('+item.iconUrl+')'"
    >
      <ng-container *ngTemplateOutlet="iconLackTpl"></ng-container>
      <ng-container *ngTemplateOutlet="valueTpl"></ng-container>
    </i>
  </div>

  <!-- F -->
  <div
    *ngSwitchCase="STOCK_VIEW.F"
    [ngClass]="STOCK_VIEW.F"
    [class.lack]="lack && !item.have && !item.showBalance"
  >
    <i
      class="icon"
      [style.background-image]="'url('+item.iconUrlBig+')'"
    >
      <ng-container *ngTemplateOutlet="iconLackTpl"></ng-container>
      <ng-container *ngTemplateOutlet="valueTpl"></ng-container>
    </i>
  </div>

  <!-- G -->
  <div
    *ngSwitchCase="STOCK_VIEW.G"
    [ngClass]="STOCK_VIEW.G"
    [class.lack]="lack && !item.have && !item.showBalance"
  >
    <i
      class="icon"
      [style.background-image]="'url('+item.iconUrl+')'"
    >
      <ng-container *ngTemplateOutlet="iconLackTpl"></ng-container>
    </i>
    <ng-container *ngTemplateOutlet="valueTpl"></ng-container>
  </div>

  <!-- H -->
  <div
    *ngSwitchCase="STOCK_VIEW.H"
    [ngClass]="STOCK_VIEW.H"
    [class.lack]="lack && !item.have && !item.showBalance"
  >
    <i
      class="icon"
      [style.background-image]="'url('+item.iconUrl+')'"
    >
      <ng-container *ngTemplateOutlet="iconLackTpl"></ng-container>
    </i>
    <ng-container *ngTemplateOutlet="valueTpl"></ng-container>
  </div>

  <!-- I -->
  <div
    *ngSwitchCase="STOCK_VIEW.I"
    [ngClass]="STOCK_VIEW.I"
    [class.lack]="lack && !item.have && !item.showBalance"
  >
    <i
      class="icon"
      [style.background-image]="'url('+item.iconUrlBig+')'"
    >
      <ng-container *ngTemplateOutlet="iconLackTpl"></ng-container>
    </i>
    <ng-container *ngTemplateOutlet="valueTpl"></ng-container>
  </div>

  <!-- J -->
  <div
    *ngSwitchCase="STOCK_VIEW.J"
    [ngClass]="STOCK_VIEW.J"
    [class.lack]="lack && !item.have && !item.showBalance"
  >
    <ng-container *ngTemplateOutlet="valueTpl"></ng-container>
    <i
      class="icon"
      [style.background-image]="'url('+item.iconUrl+')'"
    >
      <ng-container *ngTemplateOutlet="iconLackTpl"></ng-container>
    </i>
  </div>

  <!-- CUSTOM -->
  <div
    *ngSwitchCase="STOCK_VIEW.CUSTOM"
    [ngClass]="customClassStock"
    [class.lack]="lack && !item.have && !item.showBalance"
  >
    <i
      class="icon"
      [style.background-image]="'url('+(customIconSize == 'big' ? item.iconUrlBig : item.iconUrl)+')'"
    >
      <ng-container *ngTemplateOutlet="iconLackTpl"></ng-container>
    </i>
    <ng-container *ngTemplateOutlet="valueTpl"></ng-container>
  </div>
</div>


<!--VALUE TEMPLATE-->
<ng-template #valueTpl>
  <span class="value" *ngIf="!hideValue && !showBalance">
    {{ other.value }}{{ (item.amount || item.quantity || other.amount || 0) | NumberWithSpaces }}{{ other.afterValue }}
  </span>

  <span class="value" *ngIf="showBalance">
    {{ item.balance }}
  </span>
</ng-template>

<!--EXCLAMATION ICON TEMPLATE-->
<ng-template #iconLackTpl>
  <i class="fas fa-exclamation icon-lack"></i>
</ng-template>

