import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApiService } from '../core/providers/api.service';
import { ShopConfirmationsService } from '../providers/shop-confirmations.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { getToken } from '../core/utility/token';
import { TokenObject } from '../modules/auth/interfaces/token-object';


@Injectable({
  providedIn: 'root'
})
export class ConfirmationDeliveryGuard implements CanActivate, CanLoad {
  constructor(
    private apiService: ApiService,
    private shopConfirmationsService: ShopConfirmationsService,
    private router: Router,
  ) {
  }

  checkConfrimationDelivery() {
    const tokenObject: TokenObject = getToken();

    if (tokenObject.is_impersonate) {
      return of(true);
    }

    return this.shopConfirmationsService.shopConfirmations()
      .pipe(
        mergeMap((resp) => {
          if (resp.length) {
            this.router.navigate(['/auth/confirmation-delivery']);
          } else {
            return of(true);
          }
        }),
        catchError((error) => {
          if (error.status === 404) {
            return of(true);
          } else {
            return of(error);
          }
        })
      );
  }

  canActivate(): Observable<boolean> {
    return this.checkConfrimationDelivery();
  }

  canLoad(): Observable<boolean> {
    return this.checkConfrimationDelivery();
  }
}
