<!-- LOGO -->
<div class="logo"></div>

<loading></loading>

<div class="container loading-opacity">
  <p class="title">{{ 'auth.reset-password.title' | myTranslate }}:</p>

  <form [formGroup]="form">
    <!-- PASSWORD FORM -->
    <div class="control"
         [class.error]="form.get('password').invalid && form.get('password').dirty && form.get('password').errors">
      <div class="label-flex">
        <label>{{ 'auth.reset-password.form.password' | myTranslate }}:</label>
      </div>
      <input class="control-input" formControlName="password" type="password"/>
    </div>

    <!-- PASSWORD REPEAT FORM -->
    <div class="control"
         [class.error]="form.get('passwordRepeat').invalid && form.get('passwordRepeat').dirty && form.get('passwordRepeat').errors">
      <div class="label-flex">
        <label>{{ 'auth.reset-password.form.password-repeat' | myTranslate }}:</label>
      </div>
      <input class="control-input" formControlName="passwordRepeat" type="password"/>
    </div>

    <p
      class="description invalid-form error"
      [class.opacity-1]="form.get('password').value && form.get('passwordRepeat').value && form.hasError('notSame')"
    >
      {{ 'auth.registration-confirm-a.form.invalid-password-the-same' | myTranslate }}
    </p>
  </form>

  <div class="buttons">
    <button
      class="base primary"
      (click)="onFormSubmit()"
      [disabled]="form.invalid"
    >
      {{ 'auth.reset-password.form.button-send' | myTranslate }}
    </button>

    <button
      class="base primary"
      [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.LOGIN"
    >
      {{ 'global.cancel' | myTranslate }}
    </button>
  </div>
</div>
