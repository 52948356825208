export const STOCK_VIEW = {
  A: 'stock-a',
  B: 'stock-b',
  C: 'stock-c',
  D: 'stock-d',
  E: 'stock-e',
  F: 'stock-f',
  G: 'stock-g',
  H: 'stock-h',
  I: 'stock-i',
  J: 'stock-j',
  CUSTOM: 'stock-custom',
};
