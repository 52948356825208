<ng-container *ngFor="let currency of currencyDefinitions">
  <ng-container
    [ngTemplateOutlet]="hudCurrencyTpl"
    [ngTemplateOutletContext]="{currency: currency}"
    *ngIf="currency.type === 'user' ? playerService.isActiveMe : true"
  ></ng-container>
</ng-container>

<!-- CURRENCY BUTTON -->
<ng-template #hudCurrencyTpl let-currency="currency">
  <button
    class="bar"
    *ngIf="(this[currency.type].currency_balances | currencyGameBalance:currency.key) as currencyBalance"
    (click)="openHistoryTransaction(currencyBalance, tooltip)"
    (mousedown)="tooltip.open()"
    [ngbTooltip]="currency.tooltip && tooltipTpl"
    [class.initial-cursor]="!currency.show_currency_history"
    container="body"
    tooltipClass="delayed"
    placement="left"
    #tooltip="ngbTooltip"
  >
    <div
      class="icon big"
      [style.background-image]="'url('+(currencyBalance.key + '.png' | asset:'currency/hud') +')'"
    ></div>
    {{ (currencyBalance.balance || 0) | NumberWithSpaces }}
  </button>

  <!-- TOOLTIP -->
  <ng-template #tooltipTpl>
    <div
      style="font-weight: normal"
      [innerHTML]="currency.tooltip"
    ></div>
  </ng-template>
</ng-template>
