import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractBuildingDemolishComponent } from '../../../../abstract/core/abstract-building-demolish.component';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';

@Component({
  selector: 'm-building-demolish',
  templateUrl: './building-demolish.component.html',
  styleUrls: ['./building-demolish.component.scss']
})
export class MBuildingDemolishComponent extends AbstractBuildingDemolishComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribeBoardTile();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
