import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ApiService } from '../providers/api.service';
import { AppState } from '../../store/state';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { PrimaryActions, PrimarySelectors } from '../../store/primary';


@Injectable({
  providedIn: 'root'
})
export class PrimaryGuard implements CanActivate, CanLoad {
  constructor(
    private apiService: ApiService,
    public store: Store<AppState>
  ) {
  }

  getState(): Observable<any> {
    return this.store
      .pipe(
        withLatestFrom(
          this.store.pipe(select(PrimarySelectors.selectState)),
        ),
        map(([state, primaryState]) => primaryState),
        tap((primaryState) => {
          if (!primaryState.primary && !primaryState.isLoading && !primaryState.error) {
            this.store.dispatch(new PrimaryActions.FetchStart());
          }
        }),
        filter((primaryState) => {
          return primaryState.primary !== null;
        }),
        take(1)
      );
  }

  checkPrimary() {
    return this.getState()
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
      );
  }

  canActivate(): Observable<boolean> {
    return this.checkPrimary();
  }

  canLoad(): Observable<boolean> {
    return this.checkPrimary();
  }
}
