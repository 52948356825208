import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatTooltipModule } from '@angular/material';
import { DialogService } from './providers/dialog.service';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NumberWithSpacesPipe } from './pipes/number-with-spaces/number-with-spaces.pipe';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LoadingComponent } from './components/loading/loading.component';
import { NumberLengthPipe } from './pipes/number-length.pipe';
import { MomentModule } from 'ngx-moment';
import * as moment from 'moment';
import { ScrollGradientDirective } from './directives/scroll-gradient.directive';
import { OdometerDirective } from './directives/odometer.directive';
import { CloseAllDirective } from './directives/close-all.directive';
import { CloseDirective } from './directives/close.directive';
import { FloorPipe } from './pipes/floor.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { AssetPipe } from './pipes/asset.pipe';
import { ToHourPipe } from '../game/game-gui/directives/to-hour.pipe';
import { CurrencyGamePipe } from './pipes/currency-game.pipe';
import { SafePipe } from '../game/game-gui/pipes/safe.pipe';
import { BuildingIconPipe } from '../game/game-gui/pipes/building-icon.pipe';
import { AnalyticsService } from './providers/analytics.service';
import { MyTranslatePipe } from '../../core/pipes/my-translate.pipe';
import { SingleCurrencyPipe } from './pipes/currency.pipe';
import { DownloadTokenDirective } from './directives/download-token.directive';
import { OWDurationPipe } from './pipes/owduration.pipe';
import { ProgressbarComponent } from './components/progressbar/progressbar.component';
import { CurrencyGameBalancePipe } from './pipes/currency-game-balance.pipe';
import { OWDuration2Pipe } from './pipes/owduration2.pipe';
import { IsSpecialBuildingDirective } from './directives/is-special-building.directive';
import { BuildingImagePipe } from '../game/game-gui/pipes/building-image.pipe';
import { DownloadTokenPipe } from './pipes/download-token.pipe';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { OwEllipsisDirective } from './directives/ow-ellipsis.directive';
import { OwObjectSvgComponent } from './components/ow-object-svg/ow-object-svg.component';
import { OpenStorageDirective } from './directives/open-storage-directive';
import { NgSelectModule } from 'ng-custom-select';
import { DeviceRotateComponent } from './components/device-rotate/device-rotate.component';
import { VideoComponent } from './components/video/video.component';
import { CUSTOM_IMPORTS } from './consts/custom/imports.const';
import { CUSTOM_DECLARATIONS, CUSTOM_ENTRY_COMPONENTS } from './consts/custom/components.const';
import { CUSTOM_PROVIDERS } from './consts/custom/providers.const';
import { CUSTOM_EXPORTS } from './consts/custom/exports.const';
import { ResourceFromStringPipe } from './pipes/resource-from-string.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  minScrollbarLength: 50,
};

moment.locale('pl');

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    NgbTooltipModule,
    NgbDropdownModule,
    PerfectScrollbarModule,
    MomentModule,
    MatTooltipModule,
    NgSelectModule,
    ...CUSTOM_IMPORTS,
  ],
  declarations: [
    NumberWithSpacesPipe,
    LoadingComponent,
    NumberLengthPipe,
    ScrollGradientDirective,
    OdometerDirective,
    CloseAllDirective,
    CloseDirective,
    FloorPipe,
    ReplacePipe,
    AssetPipe,
    ToHourPipe,
    CurrencyGamePipe,
    SafePipe,
    BuildingIconPipe,
    BuildingImagePipe,
    MyTranslatePipe,
    SingleCurrencyPipe,
    DownloadTokenDirective,
    OWDurationPipe,
    ProgressbarComponent,
    CurrencyGameBalancePipe,
    OWDuration2Pipe,
    IsSpecialBuildingDirective,
    DownloadTokenPipe,
    SplashScreenComponent,
    OwEllipsisDirective,
    OwObjectSvgComponent,
    OpenStorageDirective,
    DeviceRotateComponent,
    VideoComponent,
    ResourceFromStringPipe,
    ...CUSTOM_DECLARATIONS,
  ],
  providers: [
    DialogService,
    NumberWithSpacesPipe,
    NumberLengthPipe,
    ToHourPipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    AnalyticsService,
    MyTranslatePipe,
    AssetPipe,
    ...CUSTOM_PROVIDERS,
  ],
  entryComponents: [
    VideoComponent,
    ...CUSTOM_ENTRY_COMPONENTS,
  ],
  exports: [
    CommonModule,
    MatDialogModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NumberWithSpacesPipe,
    PerfectScrollbarModule,
    LoadingComponent,
    NumberLengthPipe,
    MomentModule,
    ScrollGradientDirective,
    OdometerDirective,
    CloseAllDirective,
    CloseDirective,
    FloorPipe,
    ReplacePipe,
    AssetPipe,
    MatTooltipModule,
    ToHourPipe,
    CurrencyGamePipe,
    SafePipe,
    BuildingIconPipe,
    MyTranslatePipe,
    SingleCurrencyPipe,
    DownloadTokenDirective,
    OWDurationPipe,
    ProgressbarComponent,
    CurrencyGameBalancePipe,
    OWDuration2Pipe,
    IsSpecialBuildingDirective,
    BuildingImagePipe,
    DownloadTokenPipe,
    SplashScreenComponent,
    OwEllipsisDirective,
    OwObjectSvgComponent,
    OpenStorageDirective,
    NgSelectModule,
    DeviceRotateComponent,
    ResourceFromStringPipe,
    ...CUSTOM_EXPORTS,
  ]
})
export class SharedModule {
}
