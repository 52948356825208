import { Component } from '@angular/core';
import { AbstractBuildingUpgradeDiffProductionComponent } from '../../../../abstract/core/abstract-building-upgrade-diff-production.component';

@Component({
  selector: 'm-building-upgrade-diff-production',
  templateUrl: './building-upgrade-diff-production.component.html',
  styleUrls: ['./building-upgrade-diff-production.component.scss']
})
export class MBuildingUpgradeDiffProductionComponent extends AbstractBuildingUpgradeDiffProductionComponent {
}
