<!-- LOGO -->
<div class="logo"></div>

<loading></loading>

<div *ngIf="enabledPlayers" class="container loading-opacity">
  <form>
    <p class="title">{{ 'auth.player-choose.title' | myTranslate }}:</p>
    <div *ngFor="let player of enabledPlayers" class="control control-checkbox">
      <div class="custom-checkbox">
        <input
          type="checkbox"
          (click)="changePlayer(player)"
          [checked]="currentPlayer == player"
        />
        <span class="custom-checkbox-fill">
          <i class="fas fa-check"></i>
        </span>
      </div>

      <label>
        {{ 'auth.player-choose.form.value' | myTranslate:player }}
      </label>
    </div>
  </form>

  <div class="buttons">
    <button
      class="base primary"
      (click)="setDefaultPlayer()"
      [disabled]="!currentPlayer"
    >
      {{ 'auth.player-choose.form.button-send' | myTranslate }}
    </button>
  </div>
</div>


