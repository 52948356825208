import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ApiService } from '../../../../core/providers/api.service';
import { ApiOptions } from '../../../../core/interfaces/api-options';

@Component({
  selector: 'ow-object-svg',
  templateUrl: './ow-object-svg.component.html',
  styleUrls: ['./ow-object-svg.component.scss'],
})
export class OwObjectSvgComponent implements OnInit {
  @Input() svgObject: { path: string };
  @Input() svg: string;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private apiService: ApiService,
  ) {
  }

  ngOnInit() {
    this.getSvgIcon();
  }

  getSvgIcon() {
    if (!this.svgObject) {
      return;
    }

    const options: ApiOptions = {
      sendRequestWithoutToken: true,
      useBaseUrl: true,
      contentTypeAuto: true,
      responseType: 'text'
    };

    this.apiService.get(this.svgObject.path, options)
      .subscribe((resp) => {
        this.svg = resp;
        this.renderSvg();
      });
  }

  renderSvg() {
    setTimeout(() => {
      const element = document.createElement('div');
      element.innerHTML = this.svg.trim();
      this.renderer.appendChild(this.elementRef.nativeElement, element.firstChild);
    });
  }
}
