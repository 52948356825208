import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SyncGuard implements CanActivate {
  public constructor(public injector: Injector) {
  }

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (!(activatedRouteSnapshot.data && activatedRouteSnapshot.data.syncGuards)) {
      return of(false);
    }

    return from(activatedRouteSnapshot.data.syncGuards)
      .pipe(
        concatMap((value) => {
          const guard = this.injector.get(value);
          const result = guard.canActivate(activatedRouteSnapshot, routerStateSnapshot);

          if (result instanceof Observable) {
            return result;
          } else if (result instanceof Promise) {
            return from(result);
          } else {
            return of(result);
          }
        }),
        first((x) => {
          return x === false || x instanceof UrlTree;
        }, true)
      );
  }
}
