<ng-container>
  <div class="options">
    <div class="left">
      <button
        class="square small primary hide"
        [class.is-hide]="hideCards"
        (click)="toggleVisibleCards()"
      >
        <i class="fas" [ngClass]="hideCards ? 'fa-arrow-alt-to-top' : 'fa-arrow-alt-to-bottom'"></i>
      </button>
    </div>

    <div class="right">
      <!-- BASIC ACTION -->
      <button
        *ngIf="FEATURE_FLAG.BUSINESS.BASIC_ACTION"
        class="square small primary margin"
        (click)="openBasicActions()"
        [matTooltip]="'business.basic-actions.tooltip' | myTranslate"
        matTooltipPosition="above"
        matTooltipShowDelay="300"
      >
        <i class="fas fa-list-alt"></i>
      </button>

      <!-- CURRENCY EXCHANGE -->
      <button
        *ngIf="FEATURE_FLAG.HUD.CANTOR"
        class="square small primary margin"
        (click)="openCurrencyExchange()"
        [matTooltip]="'currency-exchange.tooltip' | myTranslate"
        matTooltipPosition="above"
        matTooltipShowDelay="300"
      >
        <i class="fas fa-coins"></i>
      </button>

      <!-- NAVIGATION -->
      <ng-container *ngIf="owPaginationDate">
        <!-- PREV -->
        <button
          class="square small primary prev"
          (click)="prev()"
          [disabled]="!owPaginationDate.allowPrev.month"
        >
          <i class="fas fa-chevron-left"></i>
        </button>

        <!-- CURRENT -->
        <span class="month">
          <loading
            [classNames]="['white', 'small']"
            [customLogicShow]="customLoading"
          ></loading>
          <div *ngIf="!customLoading">
            {{ owPaginationDate.current.month_text_roman }}
          </div>
        </span>

        <!-- NEXT -->
        <button
          class="square small primary next"
          (click)="next()"
          [disabled]="!owPaginationDate.allowNext.month"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </ng-container>
    </div>
  </div>

  <div class="cards" [class.hide]="hideCards">
    <card-task
      *ngFor="let cardTask of cardTasks"
      [cardTasks]="cardTasks"
      [cardTask]="cardTask"
    ></card-task>
  </div>
</ng-container>
