<div class="building-thumbnail-container" *ngIf="building">
  <img
    [src]="building | buildingIcon: false"
    ngbTooltip="{{ building.name }}"
    placement="bottom"
    tooltipClass="delayed"
    container="body"
  />
  <span class="level">
    lvl {{ building.level }}
  </span>
</div>
