import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractHudResourcesComponent } from '../../../abstract/core/abstract-hud-resources.component';
import { unsubscribeObject } from '../../../../../../../core/utility/unsubscribe-array';

@Component({
  selector: 'app-hud-resources',
  templateUrl: './hud-resources.component.html',
  styleUrls: ['./hud-resources.component.scss']
})
export class HudResourcesComponent extends AbstractHudResourcesComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribeCurrencies();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
