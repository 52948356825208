import { ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { PlayerModule } from './modules/player/player.module';
import { rootReducer } from './store/reducer';
import { CoreModule } from './core/core.module';
import { MomentModule } from 'ngx-moment';
import { PrimaryEffects } from './store/primary';
import { UtilityEffects } from './store/utility';
import { AuthEffects } from './store/auth';
import { storeFreeze } from 'ngrx-store-freeze';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SharedModule } from './modules/shared/shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UtilityMobileModule } from './modules/utility/mobile/utility-mobile.module';
import { GameModule } from './modules/new-game/module/core/game.module';
import { CUSTOM_DECLARATIONS, CUSTOM_ENTRY_COMPONENTS } from './consts/custom/components.const';
import { CUSTOM_IMPORTS } from './consts/custom/imports.const';
import { CUSTOM_PROVIDERS } from './consts/custom/providers.const';
import { AngularErrorHandler } from './handlers/angular-error.handler';

export const metaReducers = !environment.production ? [storeFreeze] : [];

@NgModule({
  declarations: [
    AppComponent,
    ...CUSTOM_DECLARATIONS,
  ],
  entryComponents: [
    ...CUSTOM_ENTRY_COMPONENTS,
  ],
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    MomentModule,
    AppRoutingModule,
    PlayerModule,
    StoreModule.forRoot({
      'application': rootReducer,
    }, {metaReducers}),
    EffectsModule.forRoot([
      ...AuthEffects,
      UtilityEffects,
      PrimaryEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !environment.production
    }),
    DeviceDetectorModule.forRoot(),
    SharedModule,
    UtilityMobileModule,
    GameModule,
    ...CUSTOM_IMPORTS,
  ],
  providers: [
    {provide: ErrorHandler, useClass: AngularErrorHandler},
    ...CUSTOM_PROVIDERS,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
