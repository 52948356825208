<div class="costs-container" [ngClass]="stockView">
  <!-- CURRENCIES -->
  <ng-container *ngFor="let currency of currencies">
    <m-ui-currency
      [item]="currency"
      (itemChanged)="itemChanged($event, TYPE.CURRENCY)"
      [stockView]="stockView"
      [observable]="observable"
      [lack]="lack"
      [customIconSize]="customIconSize"
      [customClassStock]="customClassStock"
    ></m-ui-currency>
  </ng-container>

  <!-- PRODUCTS -->
  <ng-container *ngFor="let product of products">
    <m-ui-product
      [item]="product"
      (itemChanged)="itemChanged($event, TYPE.PRODUCT)"
      [stockView]="stockView"
      [observable]="observable"
      [lack]="lack"
      [customIconSize]="customIconSize"
      [customClassStock]="customClassStock"
    ></m-ui-product>
  </ng-container>
</div>
