import { Action } from '@ngrx/store';

export enum ActionTypes {
  SOCKET_CONNECT = '[socket] connect socket',
  SOCKET_CONNECTED = '[socket] socket connected',
  SOCKET_AUTHORIZED = '[socket] socket authorized',
  SOCKET_AUTHENTICATING = '[socket] socket authenticating',
  SOCKET_DISCONNECTED = '[socket] socket disconnected',
  SOCKET_OTHER_SESSION = '[socket] socket other session',
  SOCKET_CONNECTION_ERROR = '[socket] socket connection error',
  SOCKET_DIFFERENT_VERSION_GAME = '[socket] socket different version game',
}

export class SocketConnect implements Action {
  readonly type = ActionTypes.SOCKET_CONNECT;
}

export class SocketConnected implements Action {
  readonly type = ActionTypes.SOCKET_CONNECTED;
}

export class SocketAuthorized implements Action {
  readonly type = ActionTypes.SOCKET_AUTHORIZED;
}

export class SocketAuthenticating implements Action {
  readonly type = ActionTypes.SOCKET_AUTHENTICATING;
}

export class SocketDisconnected implements Action {
  readonly type = ActionTypes.SOCKET_DISCONNECTED;
}

export class SocketOtherSession implements Action {
  readonly type = ActionTypes.SOCKET_OTHER_SESSION;
}

export class SocketConnectionError implements Action {
  readonly type = ActionTypes.SOCKET_CONNECTION_ERROR;
}

export class SocketDifferentVersionGame implements Action {
  readonly type = ActionTypes.SOCKET_DIFFERENT_VERSION_GAME;
}

export type Actions =
  SocketConnect
  | SocketConnected
  | SocketAuthorized
  | SocketAuthenticating
  | SocketDisconnected
  | SocketOtherSession
  | SocketConnectionError
  | SocketDifferentVersionGame
  ;
