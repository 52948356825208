import * as PrimaryActions from './actions';
import {primaryReducer} from './reducer';
import * as PrimarySelectors from './selectors';
import {PrimaryState} from './state';
import {PrimaryEffects} from './effects';

export {
  PrimaryActions,
  primaryReducer,
  PrimaryState,
  PrimarySelectors,
  PrimaryEffects,
};