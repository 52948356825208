import { Component } from '@angular/core';
import { AbstractChangePlayerButtonComponent } from '../../../abstract/abstract-change-player-button.component';

@Component({
  selector: 'm-ui-change-player-button',
  templateUrl: './ui-change-player-button.component.html',
  styleUrls: ['./ui-change-player-button.component.scss']
})
export class MUiChangePlayerButtonComponent extends AbstractChangePlayerButtonComponent {
}
