import {Action} from '@ngrx/store';

export enum ApplicationActionTypes {
  CLEAR_STATE = '[application] clear state',
}

export class ClearState implements Action {
  readonly type = ApplicationActionTypes.CLEAR_STATE;

  constructor() {
  }
}

export type ApplicationActions =
  ClearState
  ;
