import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AUTH_ROUTING_PATH } from './consts/core/routing-auth.const';
import { TempTokenExchangeComponent } from './basic/components/temp-token-exchange/temp-token-exchange.component';
import { AUTH_ROUTING_COMPONENTS } from './consts/core/routing-auth-components.const';
import { CUSTOM_ROUTING_AUTH_CHILDREN } from './consts/custom/routing-auth-children.const';
import { AuthGuard } from '../../core/guards/auth.guard';
import { CUSTOM_ROUTING_AUTH_PATH } from './consts/custom/routing-auth-path.const';

const routes: Routes = [
  ...CUSTOM_ROUTING_AUTH_PATH,
  {
    path: '',
    children: [
      ...CUSTOM_ROUTING_AUTH_CHILDREN,
      {
        path: AUTH_ROUTING_PATH.LOGIN, pathMatch: 'full', component: AUTH_ROUTING_COMPONENTS.LOGIN,
      },
    ]
  },
  {
    path: AUTH_ROUTING_PATH.CHANGE_PASSWORD, component: AUTH_ROUTING_COMPONENTS.CHANGE_PASSWORD,
  },
  {
    path: AUTH_ROUTING_PATH.FORGOT_PASSWORD, component: AUTH_ROUTING_COMPONENTS.FORGOT_PASSWORD,
  },
  {
    path: AUTH_ROUTING_PATH.PASSWORD_RESET, component: AUTH_ROUTING_COMPONENTS.RESET_PASSWORD,
  },
  {
    path: AUTH_ROUTING_PATH.REAUTHORIZE, component: AUTH_ROUTING_COMPONENTS.REAUTHORIZE, canActivate: [AuthGuard],
  },
  {
    path: AUTH_ROUTING_PATH.AUTHORIZE, component: TempTokenExchangeComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
