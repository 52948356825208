import { Component, OnInit } from '@angular/core';
import { AbstractMessageDetailsLevelUpComponent } from '../../../../abstract/core/abstract-message-details-level-up.component';

@Component({
  selector: 'app-message-details-level-up',
  templateUrl: './message-details-level-up.component.html',
  styleUrls: ['./message-details-level-up.component.scss']
})
export class MessageDetailsLevelUpComponent extends AbstractMessageDetailsLevelUpComponent implements OnInit {
  ngOnInit() {
    this.getUnlocks();
    this.sliderActiveIndex = 0;
    this.afterInit();
  }
}
