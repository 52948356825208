import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { GlobalService } from './global.service';
import { ApiService } from './api.service';
import { EVENTS } from '../consts/core/events';
import { SynchronizeTimeService } from './synchronize-time.service';
import { environment } from '../../../environments/environment';
import { GlobalEvent } from '../interfaces/shared.interfaces';
import { PlayerService } from '../../modules/player/providers/player.service';
import { SocketActions } from '../../store/socket';
import { AppState } from '../../store/state';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

const config = {
  'reconnection': true,
  'reconnectionDelay': 500,
  'reconnectionAttempts': 1000,
};

@Injectable()
export class WebSocketService {
  public socket;
  private token;
  private timeout;
  isOtherSession: boolean;
  dateLastTimeSync: string;
  currentConnectionAttempt = 0;
  lastConnectedDate: Date;
  lastDisconnectedDate: Date;
  isConfirmedReconnect: boolean;

  globalEvents: EventEmitter<GlobalEvent> = this.globalService.globalEvents;

  constructor(
    private globalService: GlobalService,
    private apiService: ApiService,
    private synchronizeTimeService: SynchronizeTimeService,
    private playerService: PlayerService,
    private store: Store<AppState>,
  ) {
  }

  public connect() {
    this.token = this.apiService.getToken().token;

    return new Observable(observer => {
      this.socket = io(environment.wsUrl, config);

      const onEvent = this.socket.onevent;
      this.socket.onevent = function (packet) {
        const args = packet.data || [];
        onEvent.call(this, packet);    // original call
        packet.data = ['*'].concat(args);
        onEvent.call(this, packet);      // additional call to catch-all
      };

      this.socket.on('timesync', (data) => {
        this.dateLastTimeSync = data.date;
        this.synchronizeTimeService.setActualServerTime(data);
      });

      this.socket.on(EVENTS.GLOBAL.RELOAD_GAME, (data) => {
        this.globalEvents.emit({name: EVENTS.GLOBAL.RELOAD_GAME});
        this.disconnect();
      });

      this.socket.on(EVENTS.GLOBAL.UNAUTHORIZED, (data) => {
        this.globalService.globalEvents.emit({name: EVENTS.GLOBAL.UNAUTHORIZED});
      });

      this.socket.on(EVENTS.GLOBAL.OTHER_SESSION, (event, data) => {
        this.isOtherSession = true;
        this.globalEvents.emit({name: EVENTS.GLOBAL.OTHER_SESSION});
      });

      this.socket.on(EVENTS.GLOBAL.DISCONNECT, (event, data) => {
        this.lastDisconnectedDate = this.synchronizeTimeService.getActualLocalTime();
        this.globalEvents.emit({name: EVENTS.GLOBAL.DISCONNECT});
      });

      this.socket.on('*', (event, data) => {
        if (data && this.playerService.checkIsActiveMyById(data.player_id)) {
          const eventData = data.data;
          console.log(data);
          console.log(event);
          this.emitSignal(event, eventData);
        }
      });

      const socket = this.socket;
      const token = this.token;

      this.socket.on(EVENTS.GLOBAL.CONNECT, () => {
        this.lastConnectedDate = this.synchronizeTimeService.getActualLocalTime();
        this.reconnectEmit();
        this.currentConnectionAttempt = 0;
        observer.complete();
        this.globalEvents.emit({name: EVENTS.GLOBAL.AUTHENTICATING});
        socket.emit(EVENTS.GLOBAL.AUTHENTICATE, {token: token}, ({error}: { error: boolean }) => {
          if (!error) {
            this.globalEvents.emit({name: EVENTS.GLOBAL.CONNECT});
          }
        });
        this.checkTimeSyncAndTryReconnect();
      });

      this.socket.on('connect_error', () => {
        this.currentConnectionAttempt++;
        this.globalEvents.emit({
          name: EVENTS.GLOBAL.SOCKET_CONNECTION_ERROR,
          value: {
            currentConnectionAttempt: this.currentConnectionAttempt
          }
        });
      });
    });
  }

  reconnectEmit() {
    this.globalEvents.emit({
      name: EVENTS.GLOBAL.RECONNECTED,
      value: {
        lastDisconnectedDate: this.lastDisconnectedDate,
        lastConnectedDate: this.lastConnectedDate,
      }
    });
  }

  disconnect() {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    }
  }

  emitSignal(signal, data) {

    const allowed = [
      EVENTS.GLOBAL.TILE_CHANGED,
      EVENTS.GLOBAL.UNREAD_COUNT,
      EVENTS.GLOBAL.NEW_IMPORTANT_MESSAGE,
      EVENTS.GLOBAL.SET_PLAYER, // 'player'
      'reload_player',
      EVENTS.GLOBAL.MISSION_COMPLETED,
      'production_started',
      EVENTS.GAME.REGION_UPDATE,
      EVENTS.GLOBAL.SHIP_UPDATE,
      EVENTS.GLOBAL.PLAYER_MISSION_SHOW_DETAILS,
      EVENTS.GLOBAL.NEW_PRODUCTS_AT_LEVEL,
    ];

    const aliases = {
      reload_player: 'player'
    };

    if (allowed.indexOf(signal) > -1) {
      if (aliases.hasOwnProperty(signal)) {
        signal = aliases[signal];
      }
      if (!data) {
        data = {};
      }
      this.globalEvents.emit({name: signal, value: data});
    } else {
      console.log('Signal not allowed: ' + signal);
    }
  }

  checkTimeSyncAndTryReconnect() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      if (this.allowReconnect()) {
        if (this.isConfirmedReconnect) {
          this.reconnect();
        } else {
          setTimeout(() => {
            this.isConfirmedReconnect = true;
            this.checkTimeSyncAndTryReconnect();
          }, 3000);
        }
      } else if (!this.isOtherSession) {
        this.isConfirmedReconnect = false;
        this.checkTimeSyncAndTryReconnect();
      }
    }, 10000);
  }

  allowReconnect() {
    const reconnectToSeconds = 61;
    const isOutOfDate = moment(this.synchronizeTimeService.getActualLocalTime()).diff(moment(this.dateLastTimeSync), 'seconds') > reconnectToSeconds;

    return !this.isOtherSession && isOutOfDate;
  }

  reconnect() {
    this.disconnect();
    this.store.dispatch(new SocketActions.SocketConnect());
  }
}
