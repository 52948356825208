import { Injectable, Type } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { CardTask } from '../../interfaces/custom/card-task.interface';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { ApiBusinessService } from '../../api/custom/services/api-business.service';
import { GlobalService } from '../../../../../../core/providers/global.service';
import { EVENTS } from '../../../../../../core/consts/core/events';
import { GetPlayerTasksRequest } from '../../api/custom/interfaces/get-player-tasks-request.interface';
import { OwPaginationDate } from '../../../../../../core/utility/ow-pagination.class';
import { OwDate } from '../../../../../../core/utility/ow-date.class';
import { prepareCardTask } from '../../helpers/custom';
import { AbstractBasicActionsComponent } from './abstract-basic-actions.component';
import { FEATURE_FLAG } from '../../../../../../core/config/core/feature-flags.config';
import { EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE } from '../../../currency-exchange/consts/core/event-dialogs/event-names.const';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { Primary } from '../../../../../../core/interfaces/primary';

@Injectable()
export abstract class AbstractBusinessCards extends AbstractInjectBaseComponent {
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  abstract dialogService: DialogService;
  abstract basicActionsComponent: Type<AbstractBasicActionsComponent>;
  abstract NUMBER_CARDS: number;

  FEATURE_FLAG = FEATURE_FLAG;
  subs = {
    global: null,
  };
  hideCards: boolean;
  cardTasks: CardTask[];
  owPaginationDate: OwPaginationDate;
  _owDate: OwDate = new OwDate();
  customLoading: boolean;
  primary: Primary;

  set owDate(owDate: OwDate) {
    this._owDate = owDate;
    this.setPagination();
  }

  get owDate(): OwDate {
    return this._owDate;
  }

  subscribeGlobalEvents() {
    this.subs.global = this.globalService.globalEvents.subscribe((event) => {
      switch (event.action) {
        case EVENTS.GUI.RELOAD_CARDS:
          this.getTasks();
          break;
      }
    });
  }

  getTasks() {
    this.customLoading = true;
    this.setPagination();
    const getPlayerTasksRequest: GetPlayerTasksRequest = {
      month: this.owDate.month,
      year: this.owDate.year,
    };

    this.apiBusinessService.playerTasks(getPlayerTasksRequest)
      .subscribe((resp: CardTask[]) => {
        this.clearCardTasks();
        this.cardTasks = prepareCardTask(resp, this.cardTasks);
        this.customLoading = false;
      }, (errResp) => {
        this.clearCardTasks();
        this.dialogService.openAlertErrorApi({errResp});
        this.customLoading = false;
      });
  }

  clearCardTasks() {
    this.cardTasks = [];

    Array.from({length: this.NUMBER_CARDS}).forEach((x, index) => {
      this.cardTasks[index] = null;
    });
  }

  toggleVisibleCards() {
    this.hideCards = !this.hideCards;
  }

  setPagination() {
    this.owPaginationDate = new OwPaginationDate({
      current: this.owDate
    });
  }

  next() {
    this.owDate.add({month: 1});
    this.getTasks();
  }

  prev() {
    this.owDate.subtract({month: 1});
    this.getTasks();
  }

  openCurrencyExchange() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE.CURRENCY_EXCHANGE,
    });
  }

  openBasicActions() {
    this.dialogService.open(this.basicActionsComponent);
  }
}
