import { Component } from '@angular/core';
import { AbstractMessageDetailsChestFailedComponent } from '../../../../abstract/core/abstract-message-details-chest-failed.component';

@Component({
  selector: 'app-message-details-chest-failed',
  templateUrl: './message-details-chest-failed.component.html',
  styleUrls: ['./message-details-chest-failed.component.scss']
})
export class MessageDetailsChestFailedComponent extends AbstractMessageDetailsChestFailedComponent {

}
