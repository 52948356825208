<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TOP BAR BASE -->
  <ng-container *ngIf="buildingDetails">
    <div class="top-bar">
      <!-- BUILDING NAME AND LEVEL -->
      <div class="title">
        {{ buildingDetails.name }} - {{ 'global.level' | myTranslate }} {{ buildingDetails.level }}
      </div>

      <!-- GROUP COUNT -->
      <div class="absolute-right">
        <m-ui-group-count [building]="buildingDetails"></m-ui-group-count>
      </div>
    </div>

    <!-- BUILDING ICON -->
    <div class="building-icon-container">
      <img [src]="buildingDetails | buildingIcon:false"/>
    </div>

    <!-- BUILDING DESCRIPTION -->
    <div
      class="building-desc"
      owEllipsis
      [innerHTML]="buildingDetails.group_description"
    ></div>

    <!-- PARAMETERS -->
    <div class="parameters">
      <m-ui-parameter
        *ngFor="let parameter of buildingDetails.parameters"
        [item]="parameter"
        [other]="{amount: parameter.income}"
        [stockView]="STOCK_VIEW.B"
        [lack]="false"
      ></m-ui-parameter>
    </div>

    <!-- ADDITIONAL DETAILS -->
    <div
      class="building-additional-desc"
      owEllipsis
      [innerHTML]="buildingDetails.additional_details"
    ></div>
  </ng-container>
</div>
