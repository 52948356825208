import { Injectable } from '@angular/core';
import { AppState } from '../../../store/state';
import { Store } from '@ngrx/store';
import { TokenObject } from '../../../modules/auth/interfaces/token-object';
import { getToken } from '../../utility/token';
import { AUTH_ROUTING_ABSOLUTE_PATH } from '../../../modules/auth/consts/core/routing-auth-absolute.const';
import { Router } from '@angular/router';
import { LoginActions } from '../../../store/auth/login';
import { AuthService } from '../../../modules/auth/providers/auth.service';
import { GuiService } from '../../../modules/game/services/gui.service';

@Injectable({
  providedIn: 'root'
})
export class InactiveMeasureService {
  timeInMinutes = 15;
  inactivityTimeout: number;
  events: string[] = [
    'click',
    'touchstart',
  ];

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private authService: AuthService,
    private guiService: GuiService,
  ) {
  }

  start() {
    const tokenObject: TokenObject = getToken();

    if (tokenObject.reauthorize_after) {
      return;
    }

    this.resetMeasure();
    this.startEventListeners();
  }

  startEventListeners() {
    this.events.forEach(event => this.addEventListener(event));
  }

  addEventListener(event: string) {
    document.body.addEventListener(event, () => {
      this.resetMeasure();
    });
  }

  resetMeasure() {
    this.clearTimeout();
    this.inactivityTimeout = setTimeout(() => {
      this.actionTimeEndInactive();
    }, this.timeInMinutes * 60 * 1000);
  }

  actionTimeEndInactive() {
    if (getToken().reauthorize_after) {
      this.redirectToReauthorize();
    } else {
      this.authService.logoutApi()
        .subscribe(() => {
          this.guiService.isSplashShow.next(true);
          this.store.dispatch(new LoginActions.AuthLogout());
        });
    }
  }

  clearTimeout() {
    clearTimeout(this.inactivityTimeout);
  }

  redirectToReauthorize() {
    this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.REAUTHORIZE]);
  }
}
