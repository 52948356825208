<!-- LOGO -->
<div class="logo"></div>

<loading></loading>

<div class="container loading-opacity">
  <form [formGroup]="form" autocomplete="off">
    <!-- LOGIN FORM -->
    <div class="control"
         [class.error]="form.get('username').invalid && form.get('username').dirty && form.get('username').errors">
      <div class="label-flex">
        <label>{{ 'auth.username' | myTranslate }}:</label>
      </div>
      <input class="control-input no-ow-input-directive" formControlName="username" type="text" autocomplete="off"/>
    </div>

    <div class="control"
         [class.error]="form.get('password').invalid && form.get('password').dirty && form.get('password').errors">
      <div class="label-flex">
        <label>{{ 'auth.password' | myTranslate }}:</label>
      </div>
      <input class="control-input no-ow-input-directive" formControlName="password" type="password" autocomplete="off"/>
    </div>

    <div
      *ngIf="GAME_CONFIG.FLAGS.AUTH.LOGIN.REMEMBER_ME"
      class="control control-checkbox remember-me-container"
    >
      <div class="custom-checkbox">
        <input
          type="checkbox"
          formControlName="remember_me"
          id="remember_me"
          autocomplete="off"
        />
        <span class="custom-checkbox-fill">
          <i class="fas fa-check"></i>
        </span>
      </div>

      <label for="remember_me">
        {{ 'auth.remember-me' | myTranslate }}
      </label>
    </div>

    <div class="buttons flex-center">
      <button
        class="base primary"
        (click)="login()"
        [disabled]="form.invalid"
      >
        {{ 'auth.login.button-send' | myTranslate }}
      </button>
    </div>

    <!-- LOST PASSWORD -->
    <a
      class="description text-center margin-v2"
      [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.FORGOT_PASSWORD"
    >
      <p>{{ 'auth.login.forgot-password' | myTranslate }}</p>
    </a>

    <!-- HELPDESK -->
    <p class="description text-center margin-v1">
      {{ 'global.helpdesk-email' | myTranslate }}
    </p>
  </form>
</div>


