import { Injectable, Input } from '@angular/core';
import { AbstractInjectBaseComponent } from 'src/app/core/abstracts/abstract-inject-base.component';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { QaInfo } from '../../interfaces/core/qa-info.interface';
import { EVENT_DIALOGS_NAMES_QA } from '../../../qa/consts/core/event-dialogs/event-names.const';

@Injectable()
export class AbstractButtonOpenQaComponent extends AbstractInjectBaseComponent {
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;

  @Input() qaInfo: QaInfo | null;

  openQaList() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA.QA_LIST,
      config: {
        data: {
          eachQaId: this.qaInfo.qa_id,
          isShowBackButton: true,
        }
      }
    });
  }
}
