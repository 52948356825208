import { CUSTOM_MESSAGE_DETAILS_TYPE } from '../custom/message-details-type.const';

export const MESSAGE_DETAILS_TYPE = {
  NORMAL: 'normal',
  GALLERY: 'gallery',
  LEVEL_UP: 'level_up',
  BADGE_RECEIVED: 'badge_received',
  BASIC_ACTION: 'basic_action',
  CHEST_FAILED: 'chest_rewards_claim_failed',
  CHEST_SUCCESS: 'chest_rewards_claim_successive',
  HIDDEN_REWARD: 'hidden_reward',
  PROBABILITY_COMPENSATION: 'probability_compensation',
  ...CUSTOM_MESSAGE_DETAILS_TYPE,
};
