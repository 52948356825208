export const PLURAL_FUNCTIONS = {
  hash: (value) => {
    try {
      value = JSON.parse(value);
    } catch (e) {

    }

    if (typeof value === 'object') {
      return Object.keys(value).reduce((string, key) => {
        return string + ' ' + hiddenString(value[key]);
      }, '');
    }

    return value;
  }
};

function hiddenString(string: string) {
  return string
    .split('')
    .map((char, index) => {
      return index > 0 && !(/[.@]/.test(char)) ? '*' : char;
    })
    .join('');
}
