<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <!-- RANK NAME -->
    <div class="title">
      {{ data.rank.name }}
    </div>
  </div>

  <!-- RANK DETAILS -->
  <div
    class="rank-details-container loading-opacity"
    *ngIf="rankDetails"
  >
    <table>
      <thead>
      <tr>
        <th class="column-position"></th>
        <th class="column-name">{{ 'ranks.rank-details-game.column-name' | myTranslate }}</th>
        <th class="column-lastname">Nazwisko</th>
        <!--        <th class="column-shop"></th>-->
        <th
          class="column-value">{{ data.rank.value1_label || ('ranks.rank-details-game.column.value' | myTranslate) }}</th>

        <ng-container *ngIf="data.rank.value2_label as header2; else columnPrizesTpl">
          <th class="column-value">{{ header2 }}</th>
        </ng-container>
        <ng-template #columnPrizesTpl>
          <th class="column-prizes"></th>
        </ng-template>
      </tr>
      </thead>
    </table>

    <!-- RANK RESULTS -->
    <perfect-scrollbar [scrollGradient]="rankDetails">
      <table>
        <tbody>
        <tr *ngFor="let item of rankDetails.results">
          <td class="column-position">{{ item.position }}</td>
          <td class="column-name">{{ item.first_name }}</td>
          <td class="column-lastname">{{ item['last_name'] }}</td>
          <!--<td class="column-shop">{{ item.branch_name }}</td>-->
          <td class="column-value">
            <span
              [matTooltip]="item.value2 | NumberWithSpaces"
              [matTooltipPosition]="'left'"
            >
              {{ item.value1 }}
            </span>
          </td>

          <ng-container *ngIf="data.rank.value2_label; else columnPrizesTpl">
            <td class="column-value">{{ item.value2 }}</td>
          </ng-container>
          <ng-template #columnPrizesTpl>
            <td class="column-prizes">
              <div class="prizes">
                <!-- CURRENCY PRIZES -->
                <m-ui-currency
                  *ngFor="let currency of item.currency_prizes"
                  [item]="currency"
                  [stockView]="STOCK_VIEW.H"
                  [lack]="false"
                ></m-ui-currency>

                <!-- PRODUCT PRIZES -->
                <m-ui-product
                  *ngFor="let product of item.product_prizes"
                  [item]="product"
                  [stockView]="STOCK_VIEW.H"
                  [hideValue]="true"
                  [lack]="false"
                ></m-ui-product>
              </div>
            </td>
          </ng-template>
        </tr>
        </tbody>
      </table>
    </perfect-scrollbar>

    <!-- MY POSITION -->
    <div class="my-position-container" *ngIf="rankDetails.my_position as item">

      <!-- LABEL -->
      <label>{{ 'ranks.rank-details-game.my-position' | myTranslate }}</label>

      <!-- MY POSITION BOX -->
      <div class="my-position">
        <!-- POSITION -->
        <span class="column-position">{{ item.position }}</span>
        <!-- NAME -->
        <span class="column-name">{{ item.first_name }}</span>
        <!-- LASTNAME -->
        <span class="column-lastname">{{ item['last_name'] }}</span>
        <!-- VALUE1 -->
        <span class="column-value">
          <span
            [matTooltip]="item.value2 | NumberWithSpaces"
            [matTooltipPosition]="'left'">
              {{ item.value1 }}
            </span>
        </span>
        <!-- VALUE2 -->
        <!--        <span class="column-value"></span>-->
        <span class="column-prizes">
          <div class="prizes">
            <!-- CURRENCY PRIZES -->
            <m-ui-currency
              *ngFor="let currency of item.currency_prizes"
              [item]="currency"
              [stockView]="STOCK_VIEW.H"
              [lack]="false"
            ></m-ui-currency>

            <!-- PRODUCT PRIZES -->
            <m-ui-product
              *ngFor="let product of item.product_prizes"
              [item]="product"
              [stockView]="STOCK_VIEW.H"
              [hideValue]="true"
              [lack]="false"
            ></m-ui-product>
          </div>
        </span>
      </div>
    </div>
  </div>
</div>
