import { Injectable } from '@angular/core';
import { BuildingDetailsConfig } from '../../../../game-engine/interfaces/building-details-config';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../store/state';
import { BuildingsService } from '../../../../services/buildings.service';
import { selectGameBoardTile } from '../../../../../../store/game/selectors';
import { filter } from 'rxjs/operators';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { BuildingData } from '../../interfaces/core/dialogs/building-data.interface';
import { PlayerBuilding } from '../../../../game-engine/interfaces/player-building.config';
import { ProductionService } from '../../../../services/production.service';
import { upgradeDifferenceParameters, upgradeDifferenceProduction } from '../../helpers/core/upgrade.helpers';
import { generateEachPages } from '../../../../../shared/helpers/generate-pages.helper';
import { BUILDING_TYPES } from '../../consts/core/buidling-types.const';
import { translate } from '../../../../../../core/helpers/translate.helper';
import * as moment from 'moment';

@Injectable()
export abstract class AbstractBuildingSpecialComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractBuildingSpecialComponent>;
  @OwInject(MAT_DIALOG_DATA) data: BuildingData;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ProductionService) productionService: ProductionService;
  @OwInject(Store) store: Store<AppState>;

  BUILDING_TYPES = BUILDING_TYPES;
  STOCK_VIEW = STOCK_VIEW;
  buildingDetails: PlayerBuilding;
  upgradeBuildingDetails: BuildingDetailsConfig;
  unlockedBuildings: any[];
  differentGroup: boolean;
  requirementsStatus: { valid: boolean, requirementsList: any[] };

  subs = {
    board: null,
  };

  diffProductionsSlider = {
    config: {
      itemPerPage: 6
    },
    pages: [],
    items: []
  };

  diffParametersSlider = {
    config: {
      itemPerPage: 6
    },
    pages: [],
    items: []
  };

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, {playerTileId: this.data.playerTileId}),
        filter(state => !!state),
      )
      .subscribe((tile) => {
        this.buildingDetails = tile.player_building;
        if (this.buildingDetails.upgrade_building_id) {
          this.getBuildingDetails(this.buildingDetails.upgrade_building_id);
        }
      });
  }

  getBuildingDetails(buildingId: number) {
    this.buildingsService.getBuildingDetails(buildingId)
      .subscribe((buildingDetails) => {
        this.upgradeBuildingDetails = buildingDetails;
        this.setDifferentGroup();
        this.getBuildingProductions();
        this.getUnlockedBuildings();
        this.getUpgradeDifferenceParameters();
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }

  setDifferentGroup() {
    this.differentGroup = this.buildingDetails.group !== this.upgradeBuildingDetails.group;
  }

  getBuildingProductions() {
    this.diffProductionsSlider.items = [];
    this.diffProductionsSlider.pages = [];

    switch (this.upgradeBuildingDetails.group_type) {
      case BUILDING_TYPES.PRODUCTION:
      case BUILDING_TYPES.CROP:
        Promise.all([
          this.productionService.getBuildingProduction(this.buildingDetails.building_id).toPromise(),
          this.productionService.getBuildingProduction(this.upgradeBuildingDetails.building_id).toPromise(),
        ])
          .then((
            [
              productionNormal,
              productionUpgrade,
            ]: [
              any[],
              any[]
            ]
          ) => {
            this.diffProductionsSlider.items = upgradeDifferenceProduction(productionNormal, productionUpgrade);
            this.diffProductionsSlider = generateEachPages(this.diffProductionsSlider);
        });
        break;
    }
  }

  getUnlockedBuildings() {
    switch (this.upgradeBuildingDetails.group_type) {
      case BUILDING_TYPES.NORMAL:
        this.buildingsService.getUnlockedBuildings(this.upgradeBuildingDetails.building_id)
          .subscribe((buildings: any[]) => {
            this.unlockedBuildings = buildings;
          });
        break;
    }
  }

  getUpgradeDifferenceParameters() {
    this.diffParametersSlider.items = [];
    this.diffParametersSlider.pages = [];

    this.diffParametersSlider.items = upgradeDifferenceParameters(this.buildingDetails.parameters, this.upgradeBuildingDetails.parameters);

    this.diffParametersSlider = generateEachPages(this.diffParametersSlider);
  }

  upgrade({fastUpgrade}: { fastUpgrade?: boolean } = {}) {
    this.buildingsService.upgrade(this.buildingDetails.player_building_id, fastUpgrade)
      .subscribe(resp => {
          this.afterUpgradeRequest();
        }, errResp => {
          this.dialogService.openAlertErrorApi({errResp});
        }
      );
  }

  afterUpgradeRequest() {
    this.matDialogRef.close(true);
  }

  openFastSpecialUpgradeConfirm() {
    let description = translate('fast-action.description');
    if (this.upgradeBuildingDetails.fast_building_time_in_seconds) {
      const time = moment.utc(this.upgradeBuildingDetails.fast_building_time_in_seconds * 1000).format('HH[h] mm[m] ss[s]');
      const extendDescription = translate('building-special.fast-action-extend-description', [time]);
      description += extendDescription;
    }

    this.dialogService.openConfirm({
      title: translate('fast-action.title'),
      description,
      costs: {
        separatorTitle: translate('fast-action.separator-title'),
        currencies: this.upgradeBuildingDetails.fast_build_currency_prices,
        products: this.upgradeBuildingDetails.fast_build_product_prices,
      }
    }, (confirm) => {
      if (confirm) {
        this.upgrade({fastUpgrade: true});
      }
    });
  }

  setRequirementsStatus(status) {
    this.requirementsStatus = status;
  }
}
