import { Component, Input } from '@angular/core';
import { BuildingDetailsConfig } from '../../../../../../game-engine/interfaces/building-details-config';

@Component({
  selector: 'app-core-building-time',
  templateUrl: './building-time.component.html',
  styleUrls: ['./building-time.component.scss']
})
export class CoreBuildingTimeComponent {
  @Input() building: BuildingDetailsConfig;
}
