<m-ui-stock-other
  [item]="{icon: 'gift', amount: items['product_prizes'].length + items['currency_prizes'].length}"
  [stockView]="stockView"
  [lack]="false"
  (click)="click()"
></m-ui-stock-other>

<!-- MULTIPLE PRIZES CONTAINER -->
<div
  *ngIf="isShowMultipleProductPrizes"
  class="multi-products-container"
>
  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="toggleMultipleProducts()"
  ></m-ui-close-button>

  <!-- MULTIPLE PRIZES -->
  <m-ui-product
    *ngFor="let product of items['product_prizes']"
    [item]="product"
    [other]="{value: 'x'}"
    [stockView]="STOCK_VIEW.A"
    [lack]="false"
  ></m-ui-product>

  <m-ui-currency
    *ngFor="let currency of items['currency_prizes']"
    [item]="currency"
    [other]="{value: 'x'}"
    [stockView]="STOCK_VIEW.A"
    [lack]="false"
  ></m-ui-currency>
</div>
