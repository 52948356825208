import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VirtualKeyboardAbstractModule } from '../abstract/virtual-keyboard-abstract.module';
import { MVirtualKeyboardComponent } from './components/m-virtual-keyboard-inner/m-virtual-keyboard.component';

@NgModule({
  imports: [
    CommonModule,
    VirtualKeyboardAbstractModule,
  ],
  declarations: [
    MVirtualKeyboardComponent,
  ],
  exports: [
    MVirtualKeyboardComponent,
  ]
})
export class VirtualKeyboardMobileModule {
}
