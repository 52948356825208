import { Injectable } from '@angular/core';
import { AbstractInjectBaseComponent } from './abstract-inject-base.component';
import { OwInject } from '../decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AlertConfirm } from '../interfaces/alert';
import { STOCK_VIEW } from '../../modules/game/game-ui/shared-ui/mobile/consts/stock-view.const';

@Injectable()
export abstract class AbstractAlertConfirmComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractAlertConfirmComponent>;
  @OwInject(MAT_DIALOG_DATA) data: AlertConfirm;
  STOCK_VIEW = STOCK_VIEW;
  costsRequirement: {
    reason: boolean,
    valid: boolean,
  };

  setDefaultLack() {
    if (this.data.costs && this.data.costs.lack === undefined) {
      this.data.costs.lack = true;
    }
  }

  checkCostsRequirements(event) {
    setTimeout(() => {
      this.costsRequirement = event.value;
    });
  }

  close(confirm: boolean) {
    this.matDialogRef.close(confirm);
  }
}
