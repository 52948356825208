import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/providers/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiBusinessRanksService {

  constructor(
    public apiService: ApiService,
  ) {
  }

  businessRanks({year, month}: { year: number, month: number }) {
    const options = {
      params: {
        year,
        month,
      }
    };
    return this.apiService.get('business/ranks', options);
  }

  businessRanksBranchResults({year, month, taskDefinitionId, limit, branch, region, makroregion}: { year: number, month: number, taskDefinitionId: number, limit: number, branch?: boolean, region?: boolean, makroregion?: boolean }) {
    const options = {
      params: {
        limit,
        branch,
        region,
        makroregion,
      }
    };

    return this.apiService.get(`business/ranks/branch-results/${year}/${month}/${taskDefinitionId}`, options);
  }

  businessRanksPlayerResults({year, month, taskDefinitionId, limit, branch, region, makroregion}: { year: number, month: number, taskDefinitionId: number, limit: number, branch?: boolean, region?: boolean, makroregion?: boolean }) {
    const options = {
      params: {
        limit,
        branch,
        region,
        makroregion,
      }
    };

    return this.apiService.get(`business/ranks/player-results/${year}/${month}/${taskDefinitionId}`, options);
  }
}
