import { Action } from '@ngrx/store';
import { Player } from '../../modules/player/interfaces/player';

export enum ActionTypes {
  FETCH_PLAYER = '[player] fetch player',
  FETCH_PLAYER_SUCCESS = '[player] fetch player success',
  TRY_UPDATE_PLAYER = '[player] try update player',
  UPDATE_PLAYER = '[player] update player',
  UPDATE_UNREAD_MESSAGES = '[player] update unread messages',
  NULL_ACTION = '[user] null action'
}

export class FetchPlayer implements Action {
  readonly type = ActionTypes.FETCH_PLAYER;

  constructor(public payload?: { playerId: number }) {
  }
}

export class FetchPlayerSuccess implements Action {
  readonly type = ActionTypes.FETCH_PLAYER_SUCCESS;

  constructor() {
  }
}

export class TryUpdatePlayer implements Action {
  readonly type = ActionTypes.TRY_UPDATE_PLAYER;

  constructor(public payload: Player) {
  }
}

export class UpdatePlayer implements Action {
  readonly type = ActionTypes.UPDATE_PLAYER;

  constructor(public payload: Player) {
  }
}

export class UpdateUnreadMessages implements Action {
  readonly type = ActionTypes.UPDATE_UNREAD_MESSAGES;

  constructor(public payload: any) {
  }
}

export class NullAction implements Action {
  readonly type = ActionTypes.NULL_ACTION;

  constructor(public payload: any) {
  }
}

export type Actions =
  FetchPlayer
  | FetchPlayerSuccess
  | TryUpdatePlayer
  | UpdatePlayer
  | UpdateUnreadMessages
  | NullAction
  ;
