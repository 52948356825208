import { Injectable } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ApiBusinessService } from '../../api/custom/services/api-business.service';
import { GlobalService } from '../../../../../../core/providers/global.service';
import { CardTask } from '../../interfaces/custom/card-task.interface';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { Task } from '../../interfaces/custom/task.interface';
import { CardTaskDetailsComponent } from '../../basic/custom/dialogs/card-task-details/card-task-details.component';

@Injectable()
export abstract class AbstractChooseTaskComponent extends AbstractInjectBaseComponent {
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;
  @OwInject(GlobalService) globalService: GlobalService;
  abstract dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: {
    cardTask: CardTask,
    hideBackButton: boolean,
  };
  STOCK_VIEW = STOCK_VIEW;

  openCardTask(task: Task) {
    this.dialogService.open(CardTaskDetailsComponent, {
      data: {
        task,
        cardTask: this.data.cardTask,
        showOtherClose: true,
      }
    });
  }
}
