import { Component } from '@angular/core';
import { AbstractBackButtonComponent } from '../../../abstract/abstract-back-button.component';

@Component({
  selector: 'm-ui-back-button',
  templateUrl: './ui-back-button.component.html',
  styleUrls: ['./ui-back-button.component.scss']
})
export class MUiBackButtonComponent extends AbstractBackButtonComponent {
}
