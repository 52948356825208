import { Component, OnInit } from '@angular/core';
import { AbstractBuildingBuildComponent } from '../../../../abstract/core/abstract-building-build.component';

@Component({
  selector: 'app-m-building-build',
  templateUrl: './m-building-build.component.html',
  styleUrls: ['./m-building-build.component.scss']
})
export class MBuildingBuildComponent extends AbstractBuildingBuildComponent implements OnInit {
  ngOnInit() {
    this.getData();
  }
}
