import {
  BHP_BUILDING_NAME,
  BUILDING_GROUP_TYPE_AUTO_PRODUCTION,
  BUILDING_GROUP_TYPE_DIVE,
  BUILDING_GROUP_TYPE_GASTRONOMY,
  BUILDING_GROUP_TYPE_HIDDEN,
  BUILDING_GROUP_TYPE_META_PRODUCTION,
  BUILDING_GROUP_TYPE_PRODUCTION,
  BUILDING_GROUP_TYPE_SPECIAL,
  BUILDING_GROUP_TYPE_SPECIAL_INCREASE,
  BUILDING_GROUP_TYPE_WITH_PROBABILITY,
  BUILDING_PARAMETERS_DEFINITIONS,
  WAREHOUSE_GROUP_NAMES
} from '../../constants';
import { BuildingImageParameters } from '../../game-engine/interfaces/BuildingImageParameters';
import { Attribute } from '../../game-engine/interfaces/building-details-config';
import { CurrencyBalance } from '../../../../core/interfaces/currency';
import { translate } from '../../../../core/helpers/translate.helper';

export function fastBuildConfirmText(currency: CurrencyBalance, canBuild: boolean, isUpgrade?: boolean) {
  return `
    ${translate('quick-action.description')}
    <br/><br/>
    <span class="flex-center">
      ${translate('quick-action.cost')}:
      <div class="icon small ${currency.key}"></div>
      <span class="${!canBuild ? '' : 'not-met'}"> x ${currency.amount}</span>
    </span>
  `;
}


export function isSpecial(buildingGroup: string) {
  return buildingGroup === BUILDING_GROUP_TYPE_SPECIAL;
}

export function isBuildingWithProbability(buildingGroup: string) {
  return buildingGroup === BUILDING_GROUP_TYPE_WITH_PROBABILITY;
}

export function isBuildingSpecialIncrease(buildingGroup: string) {
  return buildingGroup === BUILDING_GROUP_TYPE_SPECIAL_INCREASE;
}

export function isDive(buildingGroup: string) {
  return buildingGroup === BUILDING_GROUP_TYPE_DIVE;
}

export function isProduction(buildingGroupType: string) {
  return [BUILDING_GROUP_TYPE_PRODUCTION, BUILDING_GROUP_TYPE_META_PRODUCTION].includes(buildingGroupType);
}

export function isAutoproduction(buildingGroup: string) {
  return buildingGroup === BUILDING_GROUP_TYPE_AUTO_PRODUCTION;
}

export function isGastronomy(buildingGroup: string) {
  return buildingGroup === BUILDING_GROUP_TYPE_GASTRONOMY;
}

export function isWarehouse(buildingGroup: string) {
  const foundGroup = WAREHOUSE_GROUP_NAMES.find((group) => group === buildingGroup);
  return !!foundGroup;
}

export function isBHP(buildingGroup: string) {
  return BHP_BUILDING_NAME === buildingGroup;
}

export function isAutoProduction(buildingGroup: string) {
  return buildingGroup === BUILDING_GROUP_TYPE_AUTO_PRODUCTION;
}

export function isHidden(buildingIcon: string) {
  return buildingIcon === BUILDING_GROUP_TYPE_HIDDEN;
}

export function constructBuildingFilenamePart(icon: string, level: number, params = []) {
  let paramsString = '';
  if (params.length) {
    paramsString = `-${params.join('-')}`;
  }
  return `${icon}-${level}${paramsString}`;
}

/**
 * Get building filename from asset path.
 * Remove path and hash from asset path string.
 * @param assetPath
 */
export function extractBuildingFilenameFromAsset(assetPath: string) {
  const paramsStringSplit = assetPath.split('/');
  return paramsStringSplit[paramsStringSplit.length - 1].split('?')[0];
}

/**
 * Extract building image parameters from image filename.
 * Parameters in filename should be separated with `-`.
 * Parameters order is: icon, level, version, anchor
 * @param assetPath
 */
export function extractBuildingImageParametersFromAssetPath(assetPath: string) {
  const filename = extractBuildingFilenameFromAsset(assetPath);
  const paramsString = filename.replace('.png', '').split('-');
  return assignBuildingImageParameters(paramsString);
}

/**
 * Create building image parameters object and assign passed values.
 * Parameters order is: icon, level, version, anchor
 * @param parameterValues
 */
function assignBuildingImageParameters(parameterValues) {
  const buildingParameters: BuildingImageParameters = {
    icon: null,
    level: null,
    version: null,
    anchor: null,
    x: null,
    y: null,
    animation: null,
    animParam: null
  };
  parameterValues.forEach((paramValue, index) => {
    buildingParameters[BUILDING_PARAMETERS_DEFINITIONS[index]] = paramValue;
  });
  return buildingParameters;
}

export function getBuildingAttribute(attributeName: string, attributes: Attribute[] = []) {
  const attribute = attributes.find(attr => attr.attribute === attributeName);
  return attribute ? attribute : {value: 0};
}
