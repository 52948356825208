import { isoToScreen } from '../../utils/utils';
import { MyScene } from './MyScene';
import { ISO_OBJECT_DEPTH_VALUE_FACTOR } from '../../../constants/iso.constants';
import { BOARD_GRID_POSITION_FACTOR, BoardViewMode, ISO_BOARD_TILE_HEIGHT, ISO_BOARD_TILE_WIDTH } from '../../../constants';
import { isIsometric, isOrto } from '../../utils/board.helper';
import { PositionOffset } from '../../interfaces/shared';

export class BoardContainer extends Phaser.GameObjects.Container {

  public isoX: number;
  public isoY: number;
  depthIndex: number;
  customData: any = {};
  viewMode: BoardViewMode;

  offset: PositionOffset = {
    x: 0,
    y: 0
  };

  constructor(params) {
    super(params.scene as MyScene, params.x, params.y);

    if (params.offset) {
      this.offset = params.offset;
    }

    this.isoX = params.x;
    this.isoY = params.y;
    this.depthIndex = params.depthIndex;

    params.scene.add.existing(this);

    let screenPos = {
      x: this.x,
      y: this.y
    };

    if (isIsometric(params.viewMode)) {
      screenPos = isoToScreen(this.isoX * BOARD_GRID_POSITION_FACTOR.x, this.isoY * BOARD_GRID_POSITION_FACTOR.y);
    }

    this.x = screenPos.x + this.offset.x;
    this.y = screenPos.y + this.offset.y;

    this.depth = this.y;
  }

  calculateDepth() {
    this.setDepth(this.depthIndex + (this.y - this.width / 3) * ISO_OBJECT_DEPTH_VALUE_FACTOR);
  }
}
