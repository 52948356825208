import {createSelector} from '@ngrx/store';
import {LoginState} from './state';
import {AuthState} from '../state';
import {selectAuthState} from '../selectors';

export const selectLoginState = createSelector(
  selectAuthState,
  (state: AuthState): LoginState => state.login,
);

const getIsAuthenticating = (state: LoginState): boolean => state.isAuthenticating;
export const selectIsAuthenticating = createSelector(
  selectLoginState,
  getIsAuthenticating
);