import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractLoginComponent } from '../../../abstract/abstract-login.component';
import { getToken } from '../../../../../core/utility/token';
import { unsubscribeObject } from '../../../../../core/utility/unsubscribe-array';
import { orientationModes } from '../../../../../core/consts/core/orientation-modes.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends AbstractLoginComponent implements OnInit, OnDestroy {
  ngOnInit() {
    if (getToken().token) {
      this.router.navigate(['/auth/player-choose']);
    } else {
      this.androidService.setNativeAppOrientation(orientationModes.Auto);
      this.subscribeAuthSuccess();
      this.subscribeUserSuccess();
    }
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
