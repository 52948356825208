import { Component } from '@angular/core';
import { OW_ASSETS_SVG } from '../../../../../../../../assets-svg.const';
import { AbstractHudMenuComponent } from '../../../abstract/core/abstract-hud-menu.component';

@Component({
  selector: 'app-hud-menu',
  templateUrl: './hud-menu.component.html',
  styleUrls: ['./hud-menu.component.scss']
})
export class HudMenuComponent extends AbstractHudMenuComponent {
  OW_ASSETS_SVG = OW_ASSETS_SVG;
}
