import {Actions, ActionTypes} from './actions';
import {PasswordState} from './state';

const initialState: PasswordState = {
  error: null,
  isChanging: false,
  tempToken: null,
};

export function passwordReducer(state = initialState, action: Actions): PasswordState {
  switch (action.type) {
    case ActionTypes.MUST_CHANGE:
      return {
        ...state,
        tempToken: action.payload.token,
      };

    case ActionTypes.CHANGE_START:
      return {
        ...state,
        isChanging: true,
        error: null,
      };
    case ActionTypes.CHANGE_SUCCESS:
      return {
        ...state,
        isChanging: false,
        error: false,
        tempToken: null,
      };
    case ActionTypes.CHANGE_FAILED:
      return {
        ...state,
        isChanging: false,
        error: action.payload
      };
    default:
      return state;
  }
}