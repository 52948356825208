import { Component, OnInit } from '@angular/core';
import { AbstractHudComponent } from '../../../abstract/core/abstract-hud.component';
import { GAME_EVENTS } from '../../../../../constants';
import { WORLD_BOOT_SCENE } from '../../../../../game-engine/scenes-world/world.constants';
import { MAIN_BOOT_SCENE } from '../../../../../game-engine/scenes-main/main.constants';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GameService } from '../../../../../services/game.service';
import { screenToIso } from '../../../../../game-engine/utils/utils';
import { IsoSprite } from '../../../../../game-engine/classes/core/IsoSprite';
import { GlobalService } from '../../../../../../../core/providers/global.service';

@Component({
  selector: 'app-hud',
  templateUrl: './hud.component.html',
  styleUrls: ['./hud.component.scss']
})
export class HudComponent extends AbstractHudComponent implements OnInit {

  ngOnInit() {
    this.subscribePlayer();
    this.subscribeUser();
    this.subs.globalEmitter = this.globalService.globalEvents.subscribe(this.handleGameEvents.bind(this));
    this.setDynamicsComponents();
  }

  startWorldScene() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: WORLD_BOOT_SCENE
    });
  }

  startMainScene() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: MAIN_BOOT_SCENE
    });
  }

  showWorldInfo() {
    this.dialogService.open(WorldInfoComponent);
  }
}

@Component({
  selector: 'app-world-info',
  styles: [
      `
          .d {
              background: rgba(255, 255, 255, 0.7);
              padding: 50px;
              max-width: 80%;
              max-height: 80%;
              width: 700px;
              overflow: hidden;
              overflow-y: auto;
              font-family: "Archivo Narrow", sans-serif;
              font-size: 13px;
          }

          ul {
              width: 100%;
          }

          .d > ul {
              padding: 0;
          }

          li {
              margin-bottom: 20px;
          }

          li ul li:hover {
              color: red;
              cursor: pointer;
          }
    `
  ],
  template: `
    <div class="d">
      <loading></loading>

      <ul>
        <li *ngFor="let region of worldData$ | async">
          {{region.name}} - {{region.map_region_id}}<br> <br>

          Islands:<br>
          <ul>
            <li *ngFor="let island of region.islands" style="cursor:pointer;"
                (click)="loadBoard(island.player_island_id, island.group_type)">
              Name: {{island.name}} - GroupID: {{island.group_id}} - GroupType: {{island.group_type}} -
              PlayerIslandID: {{island.player_island_id}}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  `,
})
export class WorldInfoComponent implements OnInit {

  worldData$;

  constructor(
    private gameService: GameService,
  ) {

  }

  ngOnInit(): void {
    this.worldData$ = this.gameService.boardService.getWorld(this.gameService.playerService.getActivePlayerId())
      .pipe(
        mergeMap(worldData => {
            return of(worldData);
          }
        ));
  }

  loadBoard(playerIslandId: number, islandGroupType: string) {
    this.gameService.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: MAIN_BOOT_SCENE,
      data: {
        playerIslandId,
        islandGroupType
      }
    });
  }
}

@Component({
  selector: 'app-to-iso',
  styles: [
      `.screentoiso {
          position: fixed;
          right: 0;
          bottom: -100px;
          padding: 20px;
          background: #fff;
          border-radius: 5px;
      }

      .screentoiso.open {
          bottom: 200px;
      }

      p {
          margin: 5px;
      }

      .container {
          position: relative;
      }

      button {
          position: absolute;
          right: 0;
          top: -120px;
      }

      button {
          background: #ffffff !important;
          padding: 3px;
          margin: 3px;
          border: 1px solid black;
      }

      button.button-toggle {
          position: absolute;
          top: -26px;
          font-size: 20px;
          border: none;
          padding: 10px;
          transform: translateY(-100%);
          right: 15px;
      }

      .desc {
          position: absolute;
          right: 0;
          top: -10px;
          background: #fff;
          padding: 2px;
      }
    `
  ],
  template: `
    <div class="screentoiso" [class.open]="isOpen" *ngIf="gameService.debugUI && globalService.isDevDomain">
      <p class="desc">Screen to Iso</p>
      <div class="container">
        <button class="button-toggle" (click)="toggle()">
          <i class="fas fa-arrow-alt-{{!isOpen ? 'up' : 'down'}}"></i>
        </button>
        <p> Współrzędne ekranu (ortho) na Izometryczne</p>

        <input placeholder="x w ortho" [(ngModel)]='screenX' (keyup)='toIso()'>
        <input placeholder="y w ortho" [(ngModel)]='screenY' (keyup)='toIso()'>

        <p>x={{isoX}} y={{isoY}}</p>
      </div>
    </div>
  `
})
export class ConvertToIsoComponent {
  screenX = 0;
  screenY = 0;

  isoX: number;
  isoY: number;

  pointer;

  isOpen: boolean;

  constructor(
    public gameService: GameService,
    public globalService: GlobalService,
  ) {
    this.isOpen = Boolean(localStorage.getItem('screenToIsoOpen'));
  }

  toIso() {
    const toIso = screenToIso(this.screenX, this.screenY);
    this.isoX = toIso.x;
    this.isoY = toIso.y;

    if (this.pointer) {
      this.pointer.destroy();
      this.pointer = null;
    }
    this.pointer = new IsoSprite({
      scene: this.gameService.game.currentScene,
      x: this.isoX,
      y: this.isoY,
      key: 'map-atlas',
      frame: 'cancel.png'
    });
    // this.pointer.setScale(0.2);
  }

  toggle() {
    this.isOpen = !this.isOpen;
    localStorage.setItem('screenToIsoOpen', this.isOpen.toString());
  }
}
