import { AbstractInjectBaseComponent } from '../../../../../core/abstracts/abstract-inject-base.component';
import { Injectable } from '@angular/core';
import { OwInject } from '../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../shared/providers/dialog.service';
import { RankDetails } from '../interfaces';
import { FILTER_TYPE } from '../consts/filter-type.const';
import { MatDialogRef } from '@angular/material';

@Injectable()
export abstract class AbstractRankDetailsBase extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractRankDetailsBase>;

  defaultFilter = FILTER_TYPE.D;
  limit = 100;
  rankDetails: RankDetails;
  activeFilterType: string;
  filters = {
    [FILTER_TYPE.A]: false,
    [FILTER_TYPE.B]: false,
    [FILTER_TYPE.C]: false,
    [FILTER_TYPE.D]: false,
  };

  abstract getRank(): void;

  changeFilter(type: string) {
    this.activeFilterType = type;
    this.setFilters();
    this.getRank();
  }

  setFilters() {
    Object.keys(this.filters).forEach((key) => {
      this.filters[key] = false;
    });

    this.filters[this.activeFilterType] = true;
  }

  close() {
    this.matDialogRef.close();
  }
}
