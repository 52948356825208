import { CUSTOM_AUTH_ROUTING_PATH } from '../custom/routing-auth.const';

export const AUTH_ROUTING_PATH = {
  CHANGE_PASSWORD: 'change-password',
  FORGOT_PASSWORD: 'forgot-password',
  PASSWORD_RESET: 'password/reset/:token',
  REAUTHORIZE: 'reauthorize',
  REGISTRATION: 'registration',
  AUTHORIZE: 'authorize/:temp-token',
  LOGIN: '',
  ...CUSTOM_AUTH_ROUTING_PATH,
};
