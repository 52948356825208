import { Injectable, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { ApiTransactionHistoryService } from '../../api/core/services/api-transaction-history.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { CurrencyTransactionHistory } from '../../interfaces/core/currency-transaction-history.interface';
import { NavigationUi } from '../../../shared-ui/interfaces/navigation-ui.interface';
import { GetCurrencyTransactionRequest } from '../../api/core/interfaces/get-currency-transaction-request.interface';
import { afterRequestCurrencyTransactionHelper } from '../../helpers/custom/after-request-currency-transaction.helper';


@Injectable()
export abstract class CoreAbstractHistoryTransactionBaseComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiTransactionHistoryService) apiTransactionHistoryService: ApiTransactionHistoryService;
  @OwInject(MAT_DIALOG_DATA) data: any;
  @OwInject(DialogService) dialogService: DialogService;

  STOCK_VIEW = STOCK_VIEW;
  items: CurrencyTransactionHistory[];
  navigation: NavigationUi = {
    currentPage: 1,
    maxPage: null,
  };
  limit = 10;

  ngOnInit() {
    this.getCurrencyTransaction();
  }

  getCurrencyTransaction(page?: number) {
    if (page) {
      this.navigation.currentPage = page;
    }
    const getCurrencyTransactionRequest: GetCurrencyTransactionRequest = {
      currency: this.data.currency.currency_id,
      limit: this.limit,
      page: this.navigation.currentPage,
    };
    this.apiTransactionHistoryService.currencyTransaction(getCurrencyTransactionRequest)
      .subscribe((resp) => {
        this.items = resp.body;
        this.navigation.maxPage = resp.headers.get('x-pages-count');

        afterRequestCurrencyTransactionHelper.call(this);
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }
}
