import { Currency, CurrencyBalanceDetails, CurrencyDefinition } from '../../../core/interfaces/currency';
import { Product, ProductAmount, ProductDetails } from '../../player/interfaces/product.interface';

export interface BuildingProductionData {
  alternative_icon: string;
  alternative_name: string;
  currency_prizes: Currency[];
  fast_production_currency_prices: Currency[];
  has_required_invention: boolean;
  invention: any;
  order: number;
  product_prizes: ProductAmount[];
  production_currency_prices: Currency[];
  production_product_prices: ProductAmount[];
  production_recipe_id: number;
  time_in_days: number;
  time_in_minutes: number;
}

export interface BuildingProduction {
  production: any;
  upgradeProduction: any;
}

export interface ProcessedProductionIngredient {
  ingredientCurrency: CurrencyBalanceDetails;
  ingredientProduct: ProductAmount;
  icon: string;
  amount: number;
  have: boolean;
}

export interface ProcessedProduction {
  ingredients: ProcessedProductionIngredient[];
  producingProduct: ProductAmount;
  producingCurrency: Currency;
  producingTimeMinutes: number;
  finalProductType: FinalProductType;
  finalProductName: string;
  finalProductAmount: number;
  icon: string;
  recipeId: number;
  fastProductionCurrencyPrice: Currency;
  canStart: boolean;
  canStartFast: boolean;
  upgrade?: {
    finalProductAmountDiff: number;
    finalProductAmount: number;
    producingTimeDiff: number;
  };
  upgradeFrom?: {
    finalProductAmountDiff: number;
    finalProductAmount: number;
    producingTimeDiff: number;
  };
}

export enum FinalProductType {
  Currency,
  Product
}

export enum IngredientProductType {
  Currency,
  Product
}

export interface ProductionCycles {
  done: number;
  total: number;
}

export interface AutomaticProductionCombined {
  storage: number;
  currency: CurrencyDefinition;
  product: ProductDetails;
  amount: number;
  icon: string;
  upgrade?: {
    amountDiff: number;
    storageDiff: number;
  };
}
