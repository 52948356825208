
    <div class="d">
      <loading></loading>

      <ul>
        <li *ngFor="let region of worldData$ | async">
          {{region.name}} - {{region.map_region_id}}<br> <br>

          Islands:<br>
          <ul>
            <li *ngFor="let island of region.islands" style="cursor:pointer;"
                (click)="loadBoard(island.player_island_id, island.group_type)">
              Name: {{island.name}} - GroupID: {{island.group_id}} - GroupType: {{island.group_type}} -
              PlayerIslandID: {{island.player_island_id}}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  