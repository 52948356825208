import { Pipe, PipeTransform } from '@angular/core';
import { PlayerBuilding } from '../../game-engine/interfaces/player-building.config';
import { BuildingConfig } from '../../game-engine/interfaces/building-config';
import { BuildingsService } from '../../services/buildings.service';
import { BuildingDetailsConfig } from '../../game-engine/interfaces/building-details-config';

@Pipe({
  name: 'buildingIcon',
})
export class BuildingIconPipe implements PipeTransform {
  constructor(private buildingsService: BuildingsService,) {
  }

  transform(building: PlayerBuilding | BuildingConfig | BuildingDetailsConfig, asUrl: boolean) {
    let imagePath = this.buildingsService.getBuildingIcon(building as PlayerBuilding);
    return asUrl ? `url(${imagePath})` : imagePath;
  }
}
