import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractBusinessCards } from '../../../../abstract/custom/abstract-business-cards.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';
import { BasicActionsComponent } from '../basic-actions/basic-actions.component';
import { OwPaginationDate } from '../../../../../../../../core/utility/ow-pagination.class';
import { OwDate } from '../../../../../../../../core/utility/ow-date.class';
import { AppState } from '../../../../../../../../store/state';
import { select, Store } from '@ngrx/store';
import { PrimarySelectors } from '../../../../../../../../store/primary';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-business-cards',
  templateUrl: './business-cards.component.html',
  styleUrls: ['./business-cards.component.scss']
})
export class BusinessCardsComponent extends AbstractBusinessCards implements OnInit, OnDestroy {
  @OwInject(Store) store: Store<AppState>;
  @OwInject(DialogService) dialogService: DialogService;
  basicActionsComponent = BasicActionsComponent;
  NUMBER_CARDS = 0;
  startOwDate: OwDate;

  ngOnInit() {
    this.subscribePrimary();
    this.subscribeGlobalEvents();
  }

  subscribePrimary() {
    this.store
      .pipe(
        select(PrimarySelectors.selectPrimary),
        take(1)
      )
      .subscribe((primary) => {
        this.primary = primary;
        this.startOwDate = new OwDate({
          month: primary['first_task_month'],
          year: primary['first_task_year'],
        });

        this.getTasks();
      });
  }

  setPagination() {
    this.owPaginationDate = new OwPaginationDate({
      start: this.startOwDate,
      current: this.owDate,
    });
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
