import {Subscription} from 'rxjs';

export function unsubscribeArray(subscriptions: Subscription[] = []) {
  subscriptions.forEach((subscription) => {
    if (subscription) {
      subscription.unsubscribe();
    }
  })
}

export function unsubscribeObject(subscriptions: { [name: string]: Subscription }) {
  let arraySubscriptions = Object.keys(subscriptions).map((key) => {
    return subscriptions[key]
  });

  unsubscribeArray(arraySubscriptions);
}