<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button></m-ui-back-button>


  <div class="container flex-center loading-opacity" *ngIf="player">
    <!-- TITLE -->
    <div class="title">
      {{ 'user-settings.change-user-name' | myTranslate }}
    </div>

    <!-- FORM -->
    <form [formGroup]="nickForm">
      <input
        type="text"
        formControlName="farm_name"
        [placeholder]="player.farm_name || 'Nick'"
      />

      <!-- INPUT VALIDATOR -->
      <!-- <ow-input-validators [inputControl]="nickForm.get('farm_name')"></ow-input-validators>-->

      <p class="text-error" *ngIf="this.nickForm.get('farm_name') as inputControl">
        <ng-container *ngIf="inputControl.invalid && inputControl.dirty && inputControl.errors">
          <ng-container *ngIf="inputControl.errors.maxlength">
            Max. długość to: {{ inputControl.errors.maxlength.requiredLength }}<br/>
          </ng-container>

          <ng-container *ngIf="inputControl.errors.minlength">
            Min. długość: {{ inputControl.errors.minlength.requiredLength }}<br/>
          </ng-container>

          <ng-container *ngIf="inputControl.errors.max">
            Max. liczba to: {{ inputControl.errors.max.max }}<br/>
          </ng-container>

          <ng-container *ngIf="inputControl.errors.min">
            Min. liczba to: {{ inputControl.errors.min.min }}<br/>
          </ng-container>

          <ng-container *ngIf="inputControl.errors.pattern">
            Niepoprawny format<br/>
          </ng-container>

          <ng-container *ngIf="inputControl.errors.required">
            Pole obowiązkowe<br/>
          </ng-container>
        </ng-container>
      </p>
    </form>

    <button class="base primary" (click)="changeNick()" [disabled]="nickForm.invalid">
      {{ 'user-settings.change-name' | myTranslate }}
    </button>
  </div>
</div>

