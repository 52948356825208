import { Injectable } from '@angular/core';
import { MAPPED_TYPES_TO_COMPONENT } from '../../consts/core/mapped-types-to-component.const';
import { AbstractMessageService } from '../../abstract/core/abstract-message.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends AbstractMessageService {
  mappedTypesToEventDialog = MAPPED_TYPES_TO_COMPONENT;
}
