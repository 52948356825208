import { Component, OnInit } from '@angular/core';
import { AbstractMessageDetailsProbabilityCompensationComponent } from '../../../../abstract/core/abstract-message-details-probability-compensation.component';

@Component({
  selector: 'app-message-details-probability-compensation',
  templateUrl: './message-details-probability-compensation.component.html',
  styleUrls: ['./message-details-probability-compensation.component.scss']
})
export class MessageDetailsProbabilityCompensationComponent extends AbstractMessageDetailsProbabilityCompensationComponent implements OnInit {
  ngOnInit() {
    this.setMessageProperty();
    this.afterInit();
  }
}
