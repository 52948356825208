import { EventEmitter, Injectable } from '@angular/core';
import { GlobalEvent } from '../interfaces/shared.interfaces';
import { DeviceService } from './device.service';
import { ApiService } from './api.service';
import * as R from 'ramda';
import { DEV_DOMAIN } from '../consts/core/dev-domain.const';

@Injectable()
export class GlobalService {
  globalEvents: EventEmitter<GlobalEvent> = new EventEmitter<GlobalEvent>(true);
  isDevDomain: boolean;

  constructor(
    private deviceService: DeviceService,
    private apiService: ApiService,
  ) {
    this.handlePostMessage();
    this.setDevDomain();
  }

  setDevDomain() {
    const hostname = window.location.hostname;
    this.isDevDomain = DEV_DOMAIN.some(domain => hostname.includes(domain));
  }

  handlePostMessage() {
    const receiveMessage = (event) => {
      {
        console.log(event);

        const isParentTop = window.parent === window.top;
        const isOriginParent = event.origin === 'https://vct.wazdanep.com';

        console.log('isParentTop: ', isParentTop);
        console.log('isOriginParent: ', isOriginParent);

        if (!isParentTop || !isOriginParent) {
          return false;
        }

        const eventData = event.data;
        const eventClass = R.path(['class'], eventData);
        const eventAction = R.path(['action'], eventData);

        console.log(eventAction, eventClass);

        switch (eventClass) {
          case 'actionPoints':

            if (eventAction === 'valueChange') {
              this.syncVoltBalance(eventData.data);
            }
            break;
        }
      }
    };
    if (window.self !== window.top) {
      window.addEventListener('message', receiveMessage, false);
    }
  }

  syncVoltBalance(balance: number) {
    this.apiService.post('user/sync-volt-balance', {body: {balance}})
      .subscribe();
  }
}
