import { Injectable, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../core/decorators/ow-inject.decorator';
import { UserService } from '../../user/providers/user.service';
import { AuthService } from '../providers/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state';
import { User } from '../../user/interfaces/user';
import { Router } from '@angular/router';
import { APP_ROUTING_ABSOLUTE_PATH } from '../../../consts/routing-app-absolute.const';
import { LoginActions } from '../../../store/auth/login';
import { getToken } from '../../../core/utility/token';

@Injectable()
export abstract class AbstractReauthorizeComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(UserService) userService: UserService;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(Router) router: Router;

  hiddenUserInfo: string;

  ngOnInit() {
    if (this.isTokenRememberMe()) {
      this.setHashUser();
    } else {
      this.redirectToGame();
    }
  }

  reauthorize() {
    this.authService.reauthorize()
      .subscribe(() => {
        this.redirectToGame();
      });
  }

  setHashUser() {
    const user: User = this.userService.me;
    this.hiddenUserInfo = this.hiddenString(user.first_name) + ' ' + this.hiddenString(user.last_name);
  }

  hiddenString(string: string) {
    return string
      .split('')
      .map((char, index) => {
        return index > 0 ? '*' : char;
      })
      .join('');
  }

  redirectToGame() {
    this.router.navigate([APP_ROUTING_ABSOLUTE_PATH.PLAYER_CHOOSE]);
  }

  logout() {
    this.store.dispatch(new LoginActions.AuthLogout());
  }

  isTokenRememberMe() {
    return getToken().reauthorize_after;
  }
}
