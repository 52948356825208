import { ErrorHandler } from '@angular/core';

export class AngularErrorHandler extends ErrorHandler {
  handleError(error: any) {
    if (window['owError']) {
      window['owError'].pushError(error);
    }
    super.handleError(error);
  }
}
