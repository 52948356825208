import { Injectable } from '@angular/core';
import { EventDialog } from '../interfaces/event-dialog.interface';
import { DialogService } from '../../modules/shared/providers/dialog.service';
import { EventEmitterDialogsService } from '../services/core/event-emitter-dialogs.service';
import { EventEmitterDialog } from '../interfaces/event-emitter-dialog.interface';
import { CustomDialogEvent } from '../classes/custom-dialog-event.class';

@Injectable()
export abstract class AbstractEventDialogsService {
  private eventDialogs: EventDialog;

  constructor(
    protected eventEmitterDialogsService: EventEmitterDialogsService,
    protected dialogService: DialogService,
  ) {
    this.setEventDialogs();
    this.subscribeEventDialogs();
  }

  abstract getEventDialogs(): { customEventDialogs: EventDialog, coreEventDialogs?: EventDialog };

  setEventDialogs() {
    const {coreEventDialogs, customEventDialogs} = this.getEventDialogs();
    this.eventDialogs = {...coreEventDialogs, ...customEventDialogs};
  }

  subscribeEventDialogs() {
    this.eventEmitterDialogsService.emitter.subscribe((event: EventEmitterDialog) => {
      Object.keys(this.eventDialogs).forEach((eventComponent) => {
        let component = this.eventDialogs[eventComponent];

        if (event.name === eventComponent) {
          if (component instanceof CustomDialogEvent) {
            component = component.getComponent(event.config);
          }

          this.dialogService.open(component, event.config, event.callback);
        }
      });
    });
  }
}
