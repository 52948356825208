import { EventEmitter, Injectable, Input, Output } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../core/abstracts/abstract-inject-base.component';
import { STOCK_VIEW } from '../mobile/consts/stock-view.const';

@Injectable()
export abstract class AbstractMultiStockComponent extends AbstractInjectBaseComponent {
  @Input() items: any;
  @Input() stockView: string;
  @Input() preventDefault: boolean;
  @Output() clicked: EventEmitter<boolean> = new EventEmitter();
  @Output() isActiveMultiView: EventEmitter<boolean> = new EventEmitter();

  STOCK_VIEW = STOCK_VIEW;
  isShowMultipleProductPrizes: boolean;

  click() {
    this.clicked.emit(true);

    if (!this.preventDefault) {
      this.toggleMultipleProducts();
    }
  }

  toggleMultipleProducts() {
    this.isShowMultipleProductPrizes = !this.isShowMultipleProductPrizes;
    this.isActiveMultiView.emit(this.isShowMultipleProductPrizes);
  }
}
