import { Component } from '@angular/core';
import { GameEnginePceComponent } from '../../../components/game-engine-pce/game-engine-pce.component';
import { GameEngineP3Component } from '../../../components/game-engine-p3/game-engine-p3.component';

@Component({
  selector: 'app-mobile-game-engine-pce',
  templateUrl: './game-engine-pce.component.html',
  styleUrls: ['./game-engine-pce.component.scss']
})
export class MobileGameEnginePceComponent extends GameEngineP3Component {

}
