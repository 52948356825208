import { Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractPlayerDetailsComponent } from '../../../abstract/core/abstract-player-details.component';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { setDynamicsComponents } from '../../../../../../../core/helpers/dynamic-component.helper';
import { CORE_DYNAMIC_COMPONENTS } from '../../../const/core/dynamic-components/player-details/dynamic-components.const';
import { CUSTOM_DYNAMIC_COMPONENTS } from '../../../const/custom/dynamic-components/player-details/dynamic-components.const';

@Component({
  selector: 'app-player-details',
  templateUrl: './player-details.component.html',
  styleUrls: ['./player-details.component.scss']
})
export class PlayerDetailsComponent extends AbstractPlayerDetailsComponent {
  @OwInject(ComponentFactoryResolver) componentFactoryResolver: ComponentFactoryResolver;
  @ViewChild('otherViewTpl', {static: false, read: ViewContainerRef}) otherViewTpl: ViewContainerRef;

  setDynamicsComponents() {
    setDynamicsComponents.bind(this)({
      coreDynamicComponents: CORE_DYNAMIC_COMPONENTS,
      customDynamicComponents: CUSTOM_DYNAMIC_COMPONENTS
    });
  }

  toggleOthersView() {
    super.toggleOthersView();

    setTimeout(() => {
      if (this.isOthersView) {
        this.setDynamicsComponents();
      }
    });
  }
}
