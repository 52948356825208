import { NgModule } from '@angular/core';
import { MatButtonModule, MatDialogModule, MatTooltipModule } from '@angular/material';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

const usedModules = [
  MatButtonModule,
  MatDialogModule,
  MatTooltipModule,
  NoopAnimationsModule,
];

@NgModule({
  imports: usedModules,
  exports: usedModules,
})
export class MyMaterialModule {
}

