import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { ApiService } from '../providers/api.service';
import { AppState } from '../../store/state';
import { catchError, filter, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { UserActions, UserSelectors, UserState } from '../../store/user';
import { LoginActions } from '../../store/auth/login';
import { InactiveMeasureService } from '../services/core/inactive-measure.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private apiService: ApiService,
    public store: Store<AppState>,
    public inactiveMeasureService: InactiveMeasureService,
  ) {
  }

  getState(): Observable<any> {
    const tokenExist = this.apiService.getToken().token !== null;

    if (tokenExist) {
      return this.store
        .pipe(
          withLatestFrom(
            this.store.pipe(select(UserSelectors.selectState)),
          ),
          tap(([state, userState]: [AppState, UserState]) => {
            if (!userState.me && !userState.isLoading && !userState.error) {
              this.store.dispatch(new UserActions.FetchUser());
            }
          }),
          filter(([state, userState]: [AppState, UserState]) => {
            return userState.me !== null && userState.isLoading === false;
          }),
          tap(() => {
            this.inactiveMeasureService.start();
          }),
          take(1)
        );
    } else {
      this.store.dispatch(new LoginActions.AuthLogout());
      return throwError(false);
    }
  }

  checkAuth(): Observable<boolean> {
    return this.getState()
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
      )
  }

  canActivate(): Observable<boolean> {
    return this.checkAuth();
  }

  canLoad(): Observable<boolean> {
    return this.checkAuth();
  }
}
