<div class="ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR ?-->
  <!-- <div class="back-bar" *ngIf="dialogData.backButton">-->
  <!-- <button class="square normal back" [mat-dialog-close]></button>-->
  <!-- </div>-->

  <!-- TOP BAR LONG -->
  <div class="top-bar long" *ngIf="data.player">
    <div class="title">
      {{ data.player.first_name }} {{ data.player.last_name }}
    </div>
  </div>

  <!-- NAVIGATION CENTER -->
  <ng-container *ngIf="notEmptyCardTasks.length > 1">
    <m-ui-navigation-center
      (prevClicked)="prev()"
      (nextClicked)="next()"
    ></m-ui-navigation-center>
  </ng-container>

  <ng-container *ngIf="task">
    <!-- TITLE -->
    <div class="title">
      {{ task.task_definition.name }}
    </div>

    <!-- DESCRIPTION -->
    <div
      class="description"
      [innerHtml]="task.task_definition.description"
      owEllipsis
    >
    </div>

    <!-- PROGRESSBAR -->
    <ng-container *ngIf="task.performance !== null">
      <div class="progress-title">
        {{ 'business.card-task-details.progress-title' | myTranslate }}
      </div>

      <div class="progress-bar-container">
        <div class="progress-container">
          <div
            class="fill one"
            [ngClass]="task.taskClasses"
            [style.width]="task.realization * 100 + '%'"
          ></div>
        </div>
        <div
          class="value-progressbar"
          [style.left]="(task.realization >= 1) ? '100%' : (task.realization * 100 + '%')"
        >
          {{task.realization | percent:'0.2-2'}}
        </div>
      </div>
    </ng-container>

    <!-- PRICES -->
    <div class="prices">
      <div>
        <div
          class="price"
          *ngFor="let scope of task.scopes; let index = index"
        >
          <div class="progress">{{ scope.target | percent }}</div>

          <div
            class="value-container"
            [class.with-sibling-red]="scope.currency_prizes[1]"
          >
            <div
              *ngIf="scope.currency_prizes[0] as currency"
            >
              <m-ui-currency
                [item]="currency"
                [lack]="false"
                [hideValue]="true"
                [stockView]="STOCK_VIEW.A"
              ></m-ui-currency>

              <span class="value">
                {{ currency.amount * task.multiplier | floor | NumberWithSpaces }}
              </span>
            </div>
          </div>

          <div
            *ngIf="scope.currency_prizes[1] as currency"
            class="value-container red"
          >
            <span class="value">
              {{ currency.amount * task.multiplier | floor }}
            </span>

            <m-ui-currency
              [item]="currency"
              [lack]="false"
              [hideValue]="true"
              [stockView]="STOCK_VIEW.A"
            ></m-ui-currency>
          </div>
        </div>
      </div>
    </div>

    <div class="description additional-info">
      Uwaga: Dodatkowo za każde zrealizowane 1% targetu otrzymasz 100 banknotów i 5 punktów rankingowych
    </div>

    <!-- TABLE -->
    <ng-container *ngIf="task.performance !== null && task.target; else noBusinessResultTpl">
      <table>
        <tbody>
        <tr>
          <td>Twój target:</td>
          <td>
            <ng-container
              [ngTemplateOutlet]="valueTypeTpl"
              [ngTemplateOutletContext]="{value: task.target}"
            ></ng-container>
          </td>

          <td>Twoja realizacja:</td>
          <td>
            <ng-container
              [ngTemplateOutlet]="valueTypeTpl"
              [ngTemplateOutletContext]="{value: task.performance}"
            ></ng-container>
          </td>
        </tr>
        </tbody>
      </table>

      <span class="min-info" *ngIf="task.min_valid !== null">
        {{ 'business.card-task-details.info' | myTranslate }}
      </span>
    </ng-container>
    <!-- NO BUSINESS RESULT TPL -->
    <ng-template #noBusinessResultTpl>
      <p class="description no-business"
         [innerHTML]="'business.card-task-details.info-no-business-result' | myTranslate"
      ></p>
    </ng-template>

    <!-- CHOOSE TASKS BUTTONS-->
    <!-- W Expert city osobny component do podmieniania przycisków-->
    <!-- <choose-tasks-buttons-->
    <!-- *ngIf="!data.hideChooseTasksButtons"-->
    <!-- [task]="task"-->
    <!-- [cardTask]="cardTask"-->
    <!-- [showOtherClose]="data.showOtherClose"-->
    <!-- (cancelTaskEvent)="cancelEvent($event)"-->
    <!-- ></choose-tasks-buttons>-->
    <ng-container *ngIf="!data.hideChooseTasksButtons">
      <div class="bottom-bar" *ngIf="cardTask.other_tasks.length">
        <div class="bar-button base">
          <button class="base secondary">
            Wybierz
          </button>
        </div>
        <div class="bar-button base">
          <button class="base primary">
            Zobacz inne
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #valueTypeTpl let-value="value">
  <ng-container [ngSwitch]="task.task_definition.value_type">
    <!-- INT -->
    <ng-container *ngSwitchCase="VALUE_TYPE.INT">
      {{ value }}{{ task.task_definition.string_format }}
    </ng-container>
    <!-- FLOAT PERCENTAGE -->
    <ng-container *ngSwitchCase="VALUE_TYPE.FLOAT_PERCENTAGE">
      {{ value | percent:'1.2-2' }}
    </ng-container>
    <!-- FLOAT -->
    <ng-container *ngSwitchCase="VALUE_TYPE.FLOAT">
      {{ value | floor:'float' | number:'1.2-2' }}{{ task.task_definition.string_format }}
    </ng-container>
  </ng-container>
</ng-template>
