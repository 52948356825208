<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div
    class="content"
    *ngIf="buildingDetails && upgradeBuildingDetails"
    [class.opacity-1]="requirementsStatus"
  >
    <!-- TOP BAR BASE -->
    <div class="top-bar">
      <!-- BUILDING NAME AND LEVEL -->
      <div class="title">
        {{ buildingDetails.name }}
      </div>
    </div>

    <!-- EXPERIENCE -->
    <m-ui-exp-up [experience]="upgradeBuildingDetails.exp_points"></m-ui-exp-up>

    <div
      class="level loading-opacity"
      [innerHTML]="'building-upgrade.title-level-different' | myTranslate:[buildingDetails.level, upgradeBuildingDetails.level]">
    </div>

    <swiper #sliderUpgrade [(index)]="sliderActiveIndex" class="loading-opacity">
      <!-- UPGRADE DIFF PRODUCTION -->
      <div class="page" *ngFor="let page of diffProductionsSlider.pages">
        <m-building-upgrade-diff-production
          *ngFor="let production of page"
          [normal]="production.normal"
          [parameters]="production.parameters"
          [upgrade]="production.upgrade"
        ></m-building-upgrade-diff-production>
      </div>

      <!-- UPGRADE DIFF PARAMETER -->
      <div class="page" *ngFor="let page of diffParametersSlider.pages">
        <m-building-upgrade-diff-parameter
          *ngFor="let parameter of page"
          [normal]="parameter.normal"
          [parameters]="parameter.parameters"
          [upgrade]="parameter.upgrade"
        ></m-building-upgrade-diff-parameter>
      </div>

      <!-- UNLOCKED BUILDINGS -->
      <div class="page unlocked-buildings" *ngIf="unlockedBuildings && unlockedBuildings.length">
        <!-- TITLE UNLOCKED -->
        <p class="title-small">
          {{ (unlockedBuildings.length > 1 ? 'building-build.unlock-buildings' : 'building-build.unlock-build') | myTranslate }}
        </p>

        <!-- BUILDINGS THUMBNAIL LIST -->
        <div class="flex-center">
          <m-building-thumbnail
            *ngFor="let building of unlockedBuildings"
            [building]="building"
          ></m-building-thumbnail>
        </div>
      </div>

      <!-- ADDITIONAL DETAILS -->
      <div class="page" *ngIf="upgradeBuildingDetails.additional_details">
        <div class="inner-html-description" [innerHTML]="upgradeBuildingDetails.additional_details"></div>
      </div>
    </swiper>

    <div class="arrows-slider loading-opacity">
      <i class="fas fa-arrow-alt-left left"
         (click)="prevSlide()"
         [class.hide]="sliderActiveIndex <= 0"
      ></i>

      <m-ui-slider-dots [sliderRef]="sliderUpgrade"></m-ui-slider-dots>

      <i class="fas fa-arrow-alt-right right"
         (click)="nextSlide()"
         [class.hide]="sliderActiveIndex >= this.maxSlides - 1"
      ></i>
    </div>

    <!-- REQUIREMENTS -->
    <m-ui-requirements
      class="loading-opacity"
      [separator]="'global.requirements' | myTranslate"
      [products]="upgradeBuildingDetails.build_product_prices"
      [currencies]="upgradeBuildingDetails.build_currency_prices"
      [playerLevel]="upgradeBuildingDetails.required_level"
      [population]="upgradeBuildingDetails.required_population"
      [requiredBuilding]="upgradeBuildingDetails.required_building"
      [star]="upgradeBuildingDetails.required_star"
      (requirementsStatus)="setRequirementsStatus($event)"
    ></m-ui-requirements>

    <!-- BOTTOM BAR -->
    <div class="bottom-bar" *ngIf="requirementsStatus">
      <!-- BUILD -->
      <div class="bar-button base">
        <button
          class="base secondary"
          (click)="upgrade()"
          [disabled]="!requirementsStatus.valid"
        >
          {{ 'building-upgrade.button-upgrade' | myTranslate }}
          <app-core-building-time [building]="upgradeBuildingDetails"></app-core-building-time>
        </button>
      </div>

      <!-- FAST ACTION -->
      <div class="bar-button base"
           *ngIf="upgradeBuildingDetails.fast_build_currency_prices.length || upgradeBuildingDetails.fast_build_product_prices.length">
        <button
          class="base secondary icon-left-side"
          (click)="openFastUpgradeConfirm()"
          [disabled]="!requirementsStatus.valid"
        >
          <i class="far fa-bolt"></i>
          {{'building-upgrade.button-fast-upgrade' | myTranslate}}
        </button>
      </div>
    </div>
  </div>
</div>
