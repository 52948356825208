import { EVENT_DIALOGS_NAMES_CORE } from './event-names.const';
import { AlertComponent } from '../../../basic/core/dialogs/alert/alert.component';
import { AlertConfirmComponent } from '../../../basic/core/dialogs/alert-confirm/alert-confirm.component';
import { EventDialog } from '../../../interfaces/event-dialog.interface';
import { AlertImageComponent } from '../../../basic/core/dialogs/alert-image/alert-image.component';

export const EVENT_DIALOGS_CORE: EventDialog = {
  [EVENT_DIALOGS_NAMES_CORE.ALERT]: AlertComponent,
  [EVENT_DIALOGS_NAMES_CORE.ALERT_CONFIRM]: AlertConfirmComponent,
  [EVENT_DIALOGS_NAMES_CORE.ALERT_IMAGE]: AlertImageComponent,
};
