import { TileTooltipCore } from '../core/TileTooltipCore.class';
import { CustomWorldMainScene } from '../../scenes-world/custom/CustomWorld.main.scene';

export class TileTooltip extends TileTooltipCore {
  afterCreate() {
    if (this.gameService.game.currentScene instanceof CustomWorldMainScene) {
      this.y = this.boardTile.y - 88;
    }

    const takeImage = this.list[0];
    if (takeImage) {
      takeImage.destroy();
    }
  }

  setJumpAnim(target: Phaser.GameObjects.GameObject[]) {
    if (this.gameService.game.currentScene instanceof CustomWorldMainScene) {
      return;
    } else {
      super.setJumpAnim(target);
    }
  }
}
