import { Injectable, Input, ViewChild } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { MBuildingRowProductionComponent } from '../../base/core/components/building-row-production/building-row-production.component';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { ProductionService } from '../../../../services/production.service';
import { BuildingProductionData } from '../../../../interfaces/production.interfaces';
import { removeDuplicateMax } from '../../helpers/core/upgrade.helpers';

@Injectable()
export abstract class AbstractBuildingAllowProductionComponent extends AbstractInjectBaseComponent {
  @OwInject(ProductionService) productionService: ProductionService;
  STOCK_VIEW = STOCK_VIEW;
  production: BuildingProductionData[];
  activeProduct: BuildingProductionData;

  @Input() buildingId: number;
  @ViewChild(MBuildingRowProductionComponent, {static: false}) buildingRowProductionComponent;

  getBuildingProduction() {
    this.productionService.getBuildingProduction(this.buildingId)
      .subscribe((production: any[]) => {
        this.production = removeDuplicateMax(production);
      });
  }

  tooltipRowProduction(product, event?) {
    if (event) {
      event.stopPropagation();
    }

    if (product === this.activeProduct) {
      return;
    }

    this.activeProduct = product;
    if (this.buildingRowProductionComponent && this.activeProduct) {
      this.buildingRowProductionComponent.restart();
    }
  }
}
