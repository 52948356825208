import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { SwiperComponent } from 'ngx-swiper-wrapper';
import { Subscriber } from 'rxjs';
import { AbstractSliderDotsComponent } from '../../../abstract/abstract-slider-dots.component';

@Component({
  selector: 'm-ui-slider-dots',
  templateUrl: './ui-slider-dots.component.html',
  styleUrls: ['./ui-slider-dots.component.scss']
})
export class MUiSliderDotsComponent extends AbstractSliderDotsComponent implements OnDestroy, AfterViewInit {
  subscriptions: Subscriber<any> = new Subscriber();

  ngAfterViewInit() {
    if (this.sliderRef instanceof SwiperComponent) {
      this.compareElements();

      this.subscriptions.add(
        this.sliderRef.S_SLIDECHANGE.subscribe((value) => {
          this.setCurrentIndex();
        })
      );

      this.subscriptions.add(
        this.sliderRef.S_INIT.subscribe((value) => {
          this.setCurrentIndex();
          this.setElements();
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
