import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/providers/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiGameRanksService {

  constructor(
    public apiService: ApiService,
  ) {
  }

  gameRanks({location}: { location?: number } = {}) {
    const options = {
      params: {
        location,
      }
    };
    return this.apiService.get('game/ranks', options);
  }

  gameRanksResults({rankEditionId, branch, region, makroregion, limit}: { rankEditionId: number, branch?: boolean, region?: boolean, makroregion?: boolean, limit?: number }) {
    const options = {
      params: {
        branch,
        region,
        makroregion,
        limit,
      }
    };

    return this.apiService.get(`game/ranks/${rankEditionId}/results`, options);
  }
}
