<div class="bottom-bar" *ngIf="qaInfo">
  <div
    class="bar-button base"
    [matTooltip]="'messages.message-details-normal.qa.button-open-tooltip-disabled-' + qaInfo.qa_type | myTranslate"
    matTooltipPosition="above"
    matTooltipShowDelay="300"
    [matTooltipDisabled]="qaInfo.qa_published"
  >

    <!-- QA -->
    <button
      (click)="openQaList()"
      class="base primary"
      [disabled]="!qaInfo.qa_published"
    >
      {{ 'messages.message-details-normal.qa.button-open-qa-' + qaInfo.qa_type | myTranslate:[qaInfo.qa_type] }}
    </button>
  </div>
</div>
