import { SceneConfig } from '../interfaces/scene-config.interface';
import { MAIN_SCENE_WORLD_HEIGHT, MAIN_SCENE_WORLD_WIDTH } from './main.constants';

export const MAIN_SCENE_CUSTOM_CONFIG: Partial<SceneConfig> = {
  tiledStartOffset: {
    x: 0,
    y: -2273
  },
  tiledJsonPrefix: 'map',
  backgroundImages: [
    {
      filename: 'map-0-0.jpg',
      key: 'map-l',
      origin: {
        x: 1,
        y: 0
      },
      displayWidth: MAIN_SCENE_WORLD_WIDTH / 2,
      displayHeight: MAIN_SCENE_WORLD_HEIGHT,
      x: 0,
      y: 0
    },
    {
      filename: 'map-1-0.jpg',
      key: 'map-r',
      origin: {
        x: 0,
        y: 0
      },
      displayWidth: MAIN_SCENE_WORLD_WIDTH / 2,
      displayHeight: MAIN_SCENE_WORLD_HEIGHT,
      x: -2,
      y: 0
    }
  ]
};
