import 'phaser';

import { filter, mergeMap, take } from 'rxjs/operators';
import { select } from '@ngrx/store';
import { selectGame } from '../../../../../store/game/selectors';
import { GameState } from '../../../../../store/game';
import { MyScene } from '../../classes/core/MyScene';
import { MyGame } from '../../classes/core/MyGame';
import { MAIN_BOOT_SCENE, MAIN_MAIN_SCENE, MAIN_PRELOAD_SCENE } from '../main.constants';
import { CustomPreloadScene } from '../custom/CustomPreload.scene';
import { CustomMainScene } from '../custom/CustomMain.scene';

export class BootScene extends MyScene {

  constructor() {
    super({
      key: MAIN_BOOT_SCENE,
    });
  }

  init(data?: any) {
    this.passedData = data;
  }

  preload(): void {
    this.gameService = (this.game as MyGame).gameService;
    this.gameService.guiService.isSplashShow.next(true);

    // this.gameService.store.dispatch(new ResetBoard());
    // this.gameService.store.dispatch(new FetchBoard({playerId: this.gameService.playerService.player.id}));

    if (!this.scene.get(MAIN_PRELOAD_SCENE)) {
      this.scene.add(MAIN_PRELOAD_SCENE, CustomPreloadScene, false);
      this.scene.add(MAIN_MAIN_SCENE, CustomMainScene, false);
    }
  }

  create(): void {
    this.gameService.store
      .pipe(
        select(selectGame),
        filter(game => game.boardState.loaded),
        take(1)
      )
      .subscribe((game: GameState) => {
        const assetsToPreload = this.gameService.buildingsService.getBuildingsAssetsNamesFromBoard(game.boardState.board);
        this.scene.start(MAIN_PRELOAD_SCENE, {
          assetsToPreload,
          ...this.passedData
        });
      });
  }
}
