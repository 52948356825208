import { TokenObject } from '../../modules/auth/interfaces/token-object';
import { getCookie, removeCookie, setCookie } from './cookie';
import { DATA_STORAGE, STORAGE_CONFIG } from '../config/core/data-storage.config';

export function getToken(): TokenObject {
  let token: TokenObject = {
    token: null,
    expired_at: null,
    download_token: null,
    is_impersonate: null,
    reauthorize_after: null,
    refresh_after: null,
  };

  let lsToken;

  switch (STORAGE_CONFIG.TOKEN) {
    case DATA_STORAGE.COOKIE:
      lsToken = getCookie('token');
      break;

    case DATA_STORAGE.LOCALSTORAGE:
      lsToken = localStorage.getItem('token');
      break;
  }

  if (lsToken) {
    try {
      token = JSON.parse(lsToken);
    } catch (e) {
    }
  }

  return token;
}

export function setToken(token: TokenObject) {
  let tokenString: string;

  if (token) {
    tokenString = JSON.stringify(token);
  }
  switch (STORAGE_CONFIG.TOKEN) {
    case DATA_STORAGE.COOKIE:
      setCookie('token', tokenString);
      break;

    case DATA_STORAGE.LOCALSTORAGE:
      localStorage.setItem('token', tokenString);
      break;
  }
}

export function removeToken() {
  switch (STORAGE_CONFIG.TOKEN) {
    case DATA_STORAGE.COOKIE:
      removeCookie('token');
      break;

    case DATA_STORAGE.LOCALSTORAGE:
      localStorage.removeItem('token');
      break;
  }
}
