import { Scope } from '../../interfaces/custom/scope.interface';
import { Currency } from '../../../../../../core/interfaces/currency';

export function setScopePrize(scopes: Scope[]): Currency[] {
  const scope100percentage = scopes.find((scope) => {
    return scope.target === 1;
  });

  let currencies: Currency[] = [];
  if (scope100percentage) {
    currencies = scope100percentage.currency_prizes;
  }

  return currencies;
}
