import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MUiNavigationComponent } from './components/ui-navigation/ui-navigation.component';
import { MUiBackButtonComponent } from './components/ui-back-button/ui-back-button.component';
import { MUiNavigationCenterComponent } from './components/ui-navigation-center/ui-navigation-center.component';
import { MUiCloseButtonComponent } from './components/ui-close-button/ui-close-button.component';
import { MUiCurrencyComponent } from './components/stock/ui-currency/ui-currency.component';
import { SharedModule } from '../../../../shared/shared.module';
import { MUiProductComponent } from './components/stock/ui-product/ui-product.component';
import { MUiStockOtherComponent } from './components/stock/ui-stock-other/ui-stock-other.component';
import { MUiSliderDotsComponent } from './components/ui-slider-dots/ui-slider-dots.component';
import { MUiGroupCountComponent } from './components/ui-group-count/ui-group-count.component';
import { MUiGameDateComponent } from './components/ui-game-date/ui-game-date.component';
import { MUiExpUpComponent } from './components/ui-exp-up/ui-exp-up.component';
import { MUiCostsComponent } from './components/ui-costs/ui-costs.component';
import { MUiRequirementsComponent } from './components/ui-requirements/ui-requirements.component';
import { MUiSeparatorComponent } from './components/ui-separator/ui-separator.component';
import { MUiChangePasswordButtonComponent } from './components/ui-change-password-button/ui-change-password-button.component';
import { MUiMultiStockComponent } from './components/ui-multi-stock/ui-multi-stock.component';
import { MUiParameterComponent } from './components/stock/ui-parameter/ui-parameter.component';
import { MUiChangePlayerButtonComponent } from './components/ui-change-player-button/ui-change-player-button.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    MUiNavigationComponent,
    MUiBackButtonComponent,
    MUiNavigationCenterComponent,
    MUiCloseButtonComponent,
    MUiCurrencyComponent,
    MUiProductComponent,
    MUiStockOtherComponent,
    MUiSliderDotsComponent,
    MUiGroupCountComponent,
    MUiGameDateComponent,
    MUiExpUpComponent,
    MUiCostsComponent,
    MUiRequirementsComponent,
    MUiSeparatorComponent,
    MUiChangePasswordButtonComponent,
    MUiMultiStockComponent,
    MUiParameterComponent,
    MUiChangePlayerButtonComponent,
  ],
  exports: [
    MUiNavigationComponent,
    MUiBackButtonComponent,
    MUiNavigationCenterComponent,
    MUiCloseButtonComponent,
    MUiCurrencyComponent,
    MUiProductComponent,
    MUiStockOtherComponent,
    MUiSliderDotsComponent,
    MUiGroupCountComponent,
    MUiGameDateComponent,
    MUiExpUpComponent,
    MUiCostsComponent,
    MUiRequirementsComponent,
    MUiSeparatorComponent,
    MUiChangePasswordButtonComponent,
    MUiMultiStockComponent,
    MUiParameterComponent,
    MUiChangePlayerButtonComponent,
  ]
})
export class SharedUiMobileModule {
}
