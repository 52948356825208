import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildingsModule } from './buildings/module/core/buildings.module';
import { SharedModule } from '../../shared/shared.module';
import { WarehouseModule } from './warehouse/module/core/warehouse.module';
import { MissionModule } from './mission/module/core/mission.module';
import { VirtualKeyboardMobileModule } from './virtual-keyboard/mobile/virtual-keyboard-mobile.module';
import { ChestModule } from './chest/module/core/chest.module';
import { MessageModule } from './message/module/core/message.module';
import { RanksMobileModule } from './ranks/mobile/ranks-mobile.module';
import { QaMobileModule } from './qa/module/core/qa-mobile.module';
import { CurrencyExchangeModule } from './currency-exchange/module/core/currency-exchange.module';
import { TransactionHistoryModule } from './transaction-history/module/core/transaction-history.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    BuildingsModule,
    WarehouseModule,
    MissionModule,
    VirtualKeyboardMobileModule,
    ChestModule,
    MessageModule,
    RanksMobileModule,
    QaMobileModule,
    CurrencyExchangeModule,
    TransactionHistoryModule,
  ]
})
export class GameUiMobileModule {
}
