import { Component, OnInit } from '@angular/core';
import { AbstractHudMenuComponent } from '../../../../abstract/core/abstract-hud-menu.component';
import { OW_ASSETS_SVG } from '../../../../../../../../../assets-svg.const';
import { FEATURE_FLAG } from '../../../../../../../../core/config/core/feature-flags.config';
import { GAME_EVENTS } from '../../../../../../constants';
import { WORLD_BOOT_SCENE } from '../../../../../../game-engine/scenes-world/world.constants';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { GlobalService } from '../../../../../../../../core/providers/global.service';
import { GameService } from '../../../../../../services/game.service';
import { CustomMainScene } from '../../../../../../game-engine/scenes-main/custom/CustomMain.scene';
import { MAIN_BOOT_SCENE } from '../../../../../../game-engine/scenes-main/main.constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../../store/state';
import { QUIZ_LOCATION } from '../../../../../qa/consts/core/qa-location.const';
import { EVENT_DIALOGS_NAMES_QA } from '../../../../../qa/consts/core/event-dialogs/event-names.const';

@Component({
  selector: 'app-custom-hud-menu',
  templateUrl: './hud-menu.component.html',
  styleUrls: ['./hud-menu.component.scss']
})
export class HudMenuComponent extends AbstractHudMenuComponent implements OnInit {
  OW_ASSETS_SVG = OW_ASSETS_SVG;
  FEATURE_FLAG = FEATURE_FLAG;

  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(GameService) gameService: GameService;
  @OwInject(Store) story: Store<AppState>;

  WORLD_BOOT_SCENE = WORLD_BOOT_SCENE;
  MAIN_BOOT_SCENE = MAIN_BOOT_SCENE;

  sceneKey: string;

  ngOnInit() {
    this.sceneKey = MAIN_BOOT_SCENE;
  }

  openQaList() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA.QA_LIST,
      config: {
        data: {
          location: QUIZ_LOCATION.MENU_GUI
        }
      }
    })
  }

  changeScene() {
    this.sceneKey = MAIN_BOOT_SCENE;
    if (this.gameService.game.currentScene instanceof CustomMainScene) {
      this.sceneKey = WORLD_BOOT_SCENE;
    }

    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: this.sceneKey
    });
  }
}
