import { GameObjects } from 'phaser';
import { MyScene } from './MyScene';
import {
  AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME,
  BOARD_TILE_BUYABLE,
  BUILDING_LEVEL_FONT_STYLES,
  BUILDING_NAME_FONT_STYLES,
  BUTTON_ICON_TEXT_STYLE,
  PHASER_CAMERA_ZOOM,
  TILE_HOVER_DEPTH
} from '../../../constants';
import { BoardTile } from '../custom/BoardTile.class';
import { getTileMenuPositionOffset, getTileMenuPositionOffsetByBuildingIcon } from '../../utils/board.helper';
import { GameService } from '../../../services/game.service';
import { keepScale1 } from '../../utils/utils';
import { translate } from '../../../../../core/helpers/translate.helper';
import * as R from 'ramda';
import { isAutoProduction, isProduction } from '../../../game-gui/helpers/buildings.helper';
import { isNonInteractiveBuilding } from '../../utils/game.helper';
import { TILE_MENU_ATLAS } from '../../scenes-main/main.constants';
import { customTileHoverConfig } from '../../config/custom-tile-hover.config';
import { TileHoverConfig } from '../../interfaces/tileHoverConfig';

export class TileHoverCore extends GameObjects.Container {

  myScene: MyScene;
  gameService: GameService;
  background: GameObjects.Sprite;
  textObject: GameObjects.Text;
  textObjectString: string;
  textObjectBelow: GameObjects.Text;
  textObjectStringBelow: string;
  levelText: GameObjects.Text;
  levelImage: GameObjects.Sprite;
  building;
  counter: HoverCounter;
  menuBgHoverBgHeightDifference = 153;
  autoProductionCombined;
  targetTile: BoardTile;
  autoProductionTextObject: {
    autoProductionName: GameObjects.Text,
    autoProductionIcon: GameObjects.Text,
    autoProductionAmount: GameObjects.Text,
  };

  autoproductionContainer: GameObjects.Container;
  resourcesLeftText: GameObjects.Text;

  basePositions = {
    autoProductionText: {x: 0, y: -22},
    levelText: {x: 0, y: 8},
    textObject: {x: 0, y: -8},
    textObjectBelow: {x: 0, y: 11},
  };
  offset: { x: number; y: number };

  tileHoverConfig: TileHoverConfig;
  buildingNameTextStyles;
  maxZoomScaleValue = 5;

  constructor(scene: MyScene) {
    super(scene, 0, 0);
    this.myScene = scene;
    this.gameService = this.myScene.gameService;
    this.autoproductionContainer = this.myScene.make.container({x: 0, y: 0});

    this.setConfig(customTileHoverConfig);
    this.beforeGenerate();

    this.generateBackground();
    this.generateForeground();

    this.myScene.add.existing(this);
    this.visible = false;

    keepScale1(this, this.scene.cameras.main.zoom, this.maxZoomScaleValue);
    (this.scene as MyScene).phaserEvents.on(PHASER_CAMERA_ZOOM, (zoom: number) => {
      keepScale1(this, zoom, this.maxZoomScaleValue);
    });

    this.add(this.autoproductionContainer);
    this.afterGenerate();
  }

  beforeGenerate() {
  }

  afterGenerate() {
  }

  calculateOffset(target: BoardTile) {
    const positionOffsetByBuildingIcon = getTileMenuPositionOffsetByBuildingIcon(target.playerBuildingData.icon);
    return positionOffsetByBuildingIcon ? positionOffsetByBuildingIcon : getTileMenuPositionOffset(target.tileData.tile_type);
  }

  generateBackground() {
    this.background = this.myScene.add.sprite(0, 0, TILE_MENU_ATLAS, 'hover.png');
    this.background.scale = 0.5;
    this.background.alpha = 0;
    this.add(this.background);
  }

  calculateBackgroundOffset() {
    return {
      x: 0,
      y: -this.background.height / 2 - this.menuBgHoverBgHeightDifference / 2,
    };
  }

  generateForeground() {
    const levelTextConfig = {
      x: 0,
      origin: 0.5,
      style: BUILDING_LEVEL_FONT_STYLES,
    };
    this.levelText = this.myScene.make.text(levelTextConfig);
    this.add(this.levelText);

    this.autoProductionTextObject = {
      autoProductionName: null,
      autoProductionIcon: null,
      autoProductionAmount: null,
    };

    const autoProductionTextConfig = {
      x: -8,
      style: {
        ...this.buildingNameTextStyles,
        fontSize: '10px'
      },
    };
    this.autoProductionTextObject.autoProductionName = this.myScene.make.text(autoProductionTextConfig);
    this.autoproductionContainer.add(this.autoProductionTextObject.autoProductionName);

    const autoProductionIconConfig = {
      x: 0,
      origin: 0.5,
      text: '\uf021',
      style: {
        ...BUTTON_ICON_TEXT_STYLE,
        fontSize: '10px',
      },
    };
    this.autoProductionTextObject.autoProductionIcon = this.myScene.make.text(autoProductionIconConfig);
    this.autoproductionContainer.add(this.autoProductionTextObject.autoProductionIcon);

    const autoProductionAmountTextConfig = {
      x: 8,
      style: {
        ...this.buildingNameTextStyles,
        fontSize: '10px'
      },
    };
    this.autoProductionTextObject.autoProductionAmount = this.myScene.make.text(autoProductionAmountTextConfig);
    this.autoproductionContainer.add(this.autoProductionTextObject.autoProductionAmount);

    const textConfig = {
      x: 0,
      origin: 0.5,
      style: this.buildingNameTextStyles,
    };
    this.textObject = this.scene.make.text(textConfig);
    this.textObjectBelow = this.scene.make.text(textConfig);

    this.addShadows();
    this.add(this.textObject);
    this.add(this.textObjectBelow);
  }

  processAutoProduction() {
    this.autoProductionCombined = {
      currency: this.building.automatic_currency
        ? this.gameService.productionService.currencyService.getCurrencyDefinition(this.building.automatic_currency)
        : null,
      product: this.building.automatic_product
        ? this.gameService.productionService.productsService.getProduct(this.building.automatic_product)
        : null,
      amount: null,
    };
    if (this.autoProductionCombined.currency || this.autoProductionCombined.product) {
      this.autoProductionCombined.amount =
        R.path(['additional_board_data', AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME], this.building) || 0;
    }
  }

  show(target: BoardTile, timerSeconds?) {
    if (target.tileData.state === BOARD_TILE_BUYABLE) {
      this.showOnBuyable(target);
      return;
    }
    this.targetTile = target;
    this.myScene = target.myScene;
    this.gameService = this.myScene.gameService;
    this.offset = this.calculateOffset(target);
    const backgroundOffset = this.calculateBackgroundOffset();
    this.x = target.x + this.offset.x + backgroundOffset.x;
    this.y = target.y + this.offset.y + backgroundOffset.y;
    // this.y = target.y - target.baseSprite.height / 2;
    // console.log('TARGET', target, 'BASE SPRITE', target.baseSprite.height)
    this.building = target.playerBuildingData;
    this.depth = target.depth + 100 + TILE_HOVER_DEPTH;
    this.processAutoProduction();

    this.setBasicParams();
    if (timerSeconds) {
      // this.createTimer(timerSeconds);
      // this.adjustViewToCounter();
    }
    this.levelText.setText(translate('tile-menu.level-text', [this.building.level]));
    this.visible = true;
    this.textObjectBelow.alpha = 0;
    this.autoproductionContainer.alpha = 1;
    this.levelText.alpha = 1;
    this.levelText.visible = !isNonInteractiveBuilding(this.targetTile.playerBuildingData.group_type);
  }

  showOnBuyable(target: BoardTile) {
    this.gameService = this.myScene.gameService;
    // const backgroundOffset = this.calculateBackgroundOffset();
    this.x = target.x;
    this.y = target.y - target.baseSprite.displayHeight / 2;
    this.depth = target.depth + 100 + TILE_HOVER_DEPTH;
    this.textObjectString = `Dostępne od`;
    this.textObjectStringBelow = `${target.tileData.required_level} poziomu`;

    this.textObject.setText(this.textObjectString);
    this.textObject.setStroke('#000', 3);
    this.textObject.setShadow(2, 2, '#00000011', 3, true, true);

    this.textObjectBelow.setText(this.textObjectStringBelow);
    this.textObjectBelow.setStroke('#000', 3);
    this.textObjectBelow.setShadow(2, 2, '#00000011', 3, true, true);

    this.textObject.y = this.basePositions.textObject.y - 2;
    this.textObjectBelow.y = this.basePositions.textObjectBelow.y;

    this.autoproductionContainer.alpha = 0;
    this.levelText.alpha = 0;
    this.textObjectBelow.alpha = 1;
    this.visible = true;
  }

  hide() {
    this.visible = false;
    this.counter && clearInterval(this.counter.intervalRef);
    this.counter = null;
    this.resourcesLeftText && this.resourcesLeftText.setText('');
  }

  createTimer(seconds) {
    this.counter = {
      secondsLeft: seconds,
      intervalRef: null,
      stringTime: seconds > 0 ? this.gameService.hoursPipe.transform(seconds, 'seconds') : ''
    };
    this.counter.intervalRef = setInterval(() => {
      this.counter.secondsLeft--;
      this.counter.stringTime = this.gameService.hoursPipe.transform(this.counter.secondsLeft, 'seconds');
      this.handleTimer();
    }, 1000);
  }

  handleTimer() {
    if (this.counter.secondsLeft > 0) {
      this.setBasicParams();
      this.adjustViewToCounter();
    } else {
      clearInterval(this.counter.intervalRef);
      this.counter = null;
      this.setBasicParams();
    }
  }

  setBasicParams() {
    this.textObjectString = `${this.building.name}`;
    this.textObject.setText(this.textObjectString);
    this.levelText.y = this.basePositions.levelText.y;
    this.textObject.y = this.basePositions.textObject.y;
    this.textObjectBelow.y = this.basePositions.textObjectBelow.y;
    this.setAutoproductionParams();
  }

  setAutoproductionParams() {
    const param = this.autoProductionCombined.currency || this.autoProductionCombined.product;
    this.setAutoproductionPosition();
    this.setAutoproductionText(param);
  }

  setAutoproductionPosition() {
    this.autoProductionTextObject.autoProductionName.setOrigin(1, 0.5);
    this.autoProductionTextObject.autoProductionAmount.setOrigin(0, 0.5);
    this.autoProductionTextObject.autoProductionAmount.y = this.basePositions.autoProductionText.y;
    this.autoProductionTextObject.autoProductionIcon.y = this.basePositions.autoProductionText.y;
    this.autoProductionTextObject.autoProductionName.y = this.basePositions.autoProductionText.y;
  }

  setAutoproductionText(param) {
    if (param && this.autoProductionCombined.amount) {
      this.autoProductionTextObject.autoProductionName.setText(`${param.name}`);
      this.autoProductionTextObject.autoProductionAmount.setText(`${this.autoProductionCombined.amount}/h`);
      this.autoProductionTextObject.autoProductionIcon.setText('\uf021');
    } else {
      this.autoProductionTextObject.autoProductionName.setText('');
      this.autoProductionTextObject.autoProductionAmount.setText('');
      this.autoProductionTextObject.autoProductionIcon.setText('');
    }

    this.centerAutoprodContainer();

    if (
      this.targetTile.tileData.resource_left && this.targetTile.hasBuilding &&
      (
        isProduction(this.targetTile.playerBuildingData.group_type) ||
        isAutoProduction(this.targetTile.playerBuildingData.group_type)
      )
    ) {
      this.resourcesLeftText = this.myScene.make.text({
        x: 0,
        y: -25,
        text: `${translate('tile-hover.resource-left')}: ${this.targetTile.tileData.resource_left}`,
        style: {
          ...this.buildingNameTextStyles,
          fontSize: '10px'
        }
      });
      this.resourcesLeftText.setOrigin(0.5);
      this.addShadow(this.resourcesLeftText);
      this.add(this.resourcesLeftText);

      if (this.autoProductionTextObject) {
        Object.values(this.autoProductionTextObject).forEach(textObject => textObject.y = textObject.y - 15);
      }
    }
  }

  centerAutoprodContainer() {
    const a = this.autoProductionTextObject.autoProductionName.width;
    const b = this.autoProductionTextObject.autoProductionAmount.width;

    const diff = (b - a) / 2;
    this.autoproductionContainer.x = -diff;
  }

  adjustViewToCounter() {
    this.textObjectString = `${this.textObjectString}\n${this.counter.stringTime}`;
    this.textObject.setText(this.textObjectString);
    this.levelText.y -= 4;
    this.levelImage.y -= 4;
    this.textObject.y += 4;
  }

  addShadows() {
    this.addShadow(this.levelText);
    this.addShadow(this.autoProductionTextObject.autoProductionName);
    this.addShadow(this.autoProductionTextObject.autoProductionIcon);
    this.addShadow(this.autoProductionTextObject.autoProductionAmount);
    this.addShadow(this.textObject);
  }

  addShadow(textObject) {
    textObject.setStroke('#000', 3);
    textObject.setShadow(2, 2, '#00000011', 2, true, true);
  }

  private setConfig(config?: TileHoverConfig) {
    this.tileHoverConfig = config;
    if (config && config.buildingNameTextStyles) {
      this.buildingNameTextStyles = config.buildingNameTextStyles;
    } else {
      this.buildingNameTextStyles = BUILDING_NAME_FONT_STYLES;
    }
  }
}

export interface HoverCounter {
  secondsLeft?: number;
  intervalRef: any;
  stringTime: string;
}
