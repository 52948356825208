import {createSelector} from '@ngrx/store';
import {AppState} from '../state';
import {UserState} from './state';
import {User} from '../../modules/user/interfaces/user';

export const selectState = (state: AppState) => state.application.user;

const getIsLoading = (state: UserState): boolean => state.isLoading;
export const selectIsLoading = createSelector<AppState, object, boolean>(
  selectState,
  getIsLoading
);

const getUser = (state: UserState): User => state.me;
export const selectUser = createSelector<AppState, object, User>(
  selectState,
  getUser
);