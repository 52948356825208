import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractBuildingInfoComponent } from '../../../../abstract/core/abstract-building-info.component';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';

@Component({
  selector: 'app-m-building',
  templateUrl: './m-building-info.component.html',
  styleUrls: ['./m-building-info.component.scss']
})
export class MBuildingInfoComponent extends AbstractBuildingInfoComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribeBoardTile();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
