import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { ReauthorizeService } from '../../services/core/reauthorize.service';

@Injectable({
  providedIn: 'root',
})
export class ReauthorizeGuard implements CanActivate {
  constructor(
    private reauthorizeService: ReauthorizeService
  ) {
  }

  checkReauthorize() {
    return this.reauthorizeService.checkReauthorizeAfter();
  }

  canActivate(): boolean | Promise<boolean> {
    return this.checkReauthorize();
  }
}
