import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreCurrencyExchangeComponent } from '../../basic/core/dialogs/currency-exchange/currency-exchange.component';
import { SharedUiMobileModule } from '../../../shared-ui/mobile/shared-ui-mobile.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_DECLARATIONS, CUSTOM_ENTRY_COMPONENTS } from '../../consts/custom/components.const';
import { CUSTOM_IMPORTS } from '../../consts/custom/imports.const';
import { CUSTOM_PROVIDERS } from '../../consts/custom/providers.const';


@NgModule({
  declarations: [
    CoreCurrencyExchangeComponent,
    ...CUSTOM_DECLARATIONS,
  ],
  entryComponents: [
    CoreCurrencyExchangeComponent,
    ...CUSTOM_ENTRY_COMPONENTS,
  ],
  imports: [
    CommonModule,
    SharedUiMobileModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    ...CUSTOM_IMPORTS,
  ],
  providers: [
    ...CUSTOM_PROVIDERS,
  ]
})
export class CurrencyExchangeModule {
}
