import { Component } from '@angular/core';
import { AbstractMultiStockComponent } from '../../../abstract/abstract-multi-stock.component';

@Component({
  selector: 'm-ui-multi-stock',
  templateUrl: './ui-multi-stock.component.html',
  styleUrls: ['./ui-multi-stock.component.scss']
})
export class MUiMultiStockComponent extends AbstractMultiStockComponent {
}
