import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../core/providers/api.service';
import { PostCurrencyExchangeRequest } from '../interfaces/post-currency-exchange-request.interface';
import { ApiOptions } from '../../../../../../../core/interfaces/api-options';

@Injectable({
  providedIn: 'root'
})
export class ApiCurrencyExchangeService {

  constructor(
    public apiService: ApiService,
  ) {
  }

  postCurrencyExchange({spread_id, amount}: PostCurrencyExchangeRequest) {
    const options: ApiOptions = {
      body: {
        amount
      }
    };
    return this.apiService.post(`exchange/${spread_id}`, options);
  }
}
