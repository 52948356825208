<div class="global-dialog">
  <loading [classNames]="['white', 'stroke']" [size]="'big'"></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- NAVIGATION CENTER -->
  <ng-container *ngIf="ranks.length > 3">
    <m-ui-navigation-center
      [hidePrev]="sliderActiveIndex <= 0"
      [hideNext]="sliderActiveIndex >= ranks.length - 3"
      (prevClicked)="prevSlide()"
      (nextClicked)="nextSlide()"
    ></m-ui-navigation-center>
  </ng-container>

  <div class="content">
    <!-- TITLE -->
    <p class="title">{{ 'ranks.rank-list.ranks' | myTranslate }}</p>

    <ng-container *ngIf="ranks.length">
      <!-- SLIDER -->
      <swiper #sliderRanks [config]="config" [(index)]="sliderActiveIndex">
        <div
          class="ranks"
          *ngFor="let rank of ranks;
          let idx = index"
          (click)="openRankDetails(rank)"
        >
          <div class="ow-dialog window-b primary" [class.active]="idx == sliderActiveIndex">
            <!-- NAME -->
            <p class="name">{{ rank.name }}</p>

            <!-- SEPARATOR -->
            <div class="separator">
              <!-- IS CLEARED -->
              <span *ngIf="rank.cleared" class="separator-text is-cleared">
                  {{ 'ranks.rank-list.rank-closed' | myTranslate }}
                </span>
              <!-- IS ACTIVE -->
              <span *ngIf="!rank.cleared" class="separator-text is-active">
                  {{ 'ranks.rank-list.rank-active' | myTranslate }}
                </span>
            </div>

            <!-- DESCRIPTION -->
            <perfect-scrollbar *ngIf="rank.description">
              <div class="description" [innerHTML]="rank.description"></div>
            </perfect-scrollbar>
          </div>
        </div>
      </swiper>
    </ng-container>

    <!-- RANKS TYPE LIST -->
    <div class="ranks-type-list">
      <ng-container *ngFor="let item of RANKS_TYPES | keyvalue: keyAscOrder">
        <div
          class="rank-type active"
          [class.active]="item.value === activeRankType"
          (click)="changeActiveRankType(item.value)"
          *ngIf="item.value.show"
        >
          {{ item.value.label }}
        </div>
      </ng-container>
    </div>
  </div>

</div>

