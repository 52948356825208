import { GameObjects } from 'phaser';
import { MyScene } from './MyScene';
import { TileMenu } from '../custom/TileMenu';
import {
  AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME,
  BUILDING_NAME_FONT_STYLES,
  BUTTON_ICON_TEXT_STYLE,
  GAME_EVENTS,
  PLAYER_BUILDING_STATUS_BUILT
} from '../../../constants';
import { PlayerBuilding } from '../../interfaces/player-building.config';
import {
  getBuildingAttribute,
  isAutoProduction,
  isBHP,
  isGastronomy,
  isProduction,
} from '../../../game-gui/helpers/buildings.helper';
import { GameService } from '../../../services/game.service';
import { isAssetLoadedToPhaserCache, isPointerupOnCanvas } from '../../utils/game.helper';
import { BuildingDetailsConfig } from '../../interfaces/building-details-config';
import { AutomaticProductionCombined } from '../../../interfaces/production.interfaces';
import { translate } from '../../../../../core/helpers/translate.helper';
import { TileMenuButtonConfig } from './TileMenuCore';
import { TILE_MENU_ATLAS } from '../../scenes-main/main.constants';

export class MenuButton extends GameObjects.Sprite {

  myScene: MyScene;
  gameService: GameService;
  event;
  eventName: string;
  playerTileId: number;
  tileMenu: TileMenu;
  name: string;
  basicFrame: string;
  hoverFrame: string;
  activeFrame: string;
  productTextObject: {
    text: Phaser.GameObjects.Text,
    icon: Phaser.GameObjects.Text
  };
  icon: string;
  building: PlayerBuilding;
  isActive: boolean;
  productIconObject: Phaser.GameObjects.Sprite;
  loadDelay: number;
  buildingDetails: BuildingDetailsConfig;
  autoProductionCombined: AutomaticProductionCombined;
  hasAutoProduction: boolean;
  hasProduction: boolean;

  activeCheckFn: Function;
  additionalBoardData: string;

  constructor(tileMenu: TileMenu, params: TileMenuButtonConfig) {
    super(tileMenu.myScene, params.x, params.y, TILE_MENU_ATLAS, params.frame);
    this.myScene = tileMenu.myScene;
    this.activeCheckFn = params.activeCheckFn;
    this.gameService = this.myScene.gameService;
    this.eventName = params.eventName;
    this.tileMenu = tileMenu;
    this.playerTileId = this.tileMenu.targetTile.tileData.player_tile_id;
    this.name = params.name;
    this.tileMenu.add(this);
    this.basicFrame = params.frame;
    this.hoverFrame = params.hoverFrame;
    this.activeFrame = params.activeFrame;
    this.icon = params.icon;
    this.building = tileMenu.building;
    this.additionalBoardData = params.additionalBoardData;
    this.loadDelay = 0;
    this.alpha = 0;
    this.setInteractive({cursor: 'pointer'});
    this.setButtonStatus();
    this.createTween();
    this.scale = tileMenu.scaleFactor;
  }

  createTween() {
    this.myScene.tweens.add({
      targets: [this, this.productTextObject],
      // x: {from: 0, to: this.x},
      // y: {from: 0, to: this.y},
      alpha: {
        from: 0,
        to: this.isActive ? 1 : 0,
      },
      ease: Phaser.Math.Easing.Expo.In,
      duration: 300,
      onComplete: () => {
        this.addPointerEvents();
        this.checkIfProduct();
      },
      delay: this.loadDelay,
      repeat: 0,
    });
  }

  addPointerEvents() {
    this.on('pointerover', () => {
      if (!this.scene.input.isOver) {
        return true;
      }
      this.tileMenu.changeMenuTextAndHideSecondLine(translate(this.name));
      this.setFrame(this.hoverFrame);
    });

    this.on('pointerout', () => {
      if (!this.scene.input.isOver) {
        return true;
      }
      this.tileMenu.setBasicMenuText();
      this.setFrame(this.basicFrame);
    });

    this.on('pointerdown', (pointer) => {
      if (!isPointerupOnCanvas(pointer, this.myScene)) {
        return;
      }
      this.setFrame(this.activeFrame);
    });

    this.on('pointerup', (pointer) => {
      if (!isPointerupOnCanvas(pointer, this.myScene)) {
        return;
      }
      this.gameService.emitGameEvent({
        name: this.eventName,
        value: this.playerTileId,
        data: this.tileMenu.targetTile.tileData,
      });
      this.tileMenu.destructor();
    });
  }

  checkIfProduct() {
    if (this.hasAutoProduction || this.hasProduction) {
      this.gameService.buildingsService.getBuildingDetails(this.building.building_id)
        .subscribe((buildingDetails) => {
          this.buildingDetails = buildingDetails;
          // this.processAutoProduction();
        });
    }
  }

  setButtonStatus() {
    const playerLevel = this.gameService.playerService.player.level;

    switch (this.eventName) {
      case GAME_EVENTS.BUILDING_UPGRADE:
        this.isActive = this.building.upgrade_building_id && this.building.status === PLAYER_BUILDING_STATUS_BUILT;
        break;

      case GAME_EVENTS.BUILDING_PRODUCTION:
        this.isActive = isProduction(this.building.group_type);
        this.hasProduction = this.isActive;
        break;

      case GAME_EVENTS.BUILDING_AUTO_PRODUCTION:
        this.isActive = isAutoProduction(this.building.group_type);
        this.hasAutoProduction = this.isActive;
        break;

      case GAME_EVENTS.BUILDING_GASTRO:
        this.isActive = isGastronomy(this.building.group_type);
        break;

      case GAME_EVENTS.OPEN_FUNCTIONAL:
        this.isActive = this.activeCheckFn ? this.activeCheckFn.call(this, this.tileMenu.targetTile) : false;
        break;

      case GAME_EVENTS.BUILDING_DELETE:
        let deletableFromLevelChecked = true;
        if (this.building.deletable_from_level) {
          deletableFromLevelChecked = playerLevel >= this.building.deletable_from_level;
        }
        this.isActive = this.building.is_deletable && deletableFromLevelChecked;
        break;

      case GAME_EVENTS.BUILDING_MOVE:
        let movableFromLevelChecked = true;
        if (this.building.moveable_from_level) {
          movableFromLevelChecked = playerLevel >= this.building.moveable_from_level;
        }
        this.isActive = this.building.is_moveable && movableFromLevelChecked;
        break;

      case GAME_EVENTS.BUILDING_INFO:
        this.isActive = true;
        break;

      default:
        this.isActive = false;
    }

    if (!this.isActive) {
      this.removeInteractive();
    }
  }

  processAutoProduction() {
    this.autoProductionCombined = {
      currency: this.building.automatic_currency
        ? this.gameService.productionService.currencyService.getCurrencyDefinition(this.building.automatic_currency)
        : null,
      product: this.building.automatic_product
        ? this.gameService.productionService.productsService.getProduct(this.building.automatic_product)
        : null,
      amount: null,
      storage: null,
      icon: null,
    };
    this.setExtendingIconTexture();
  }

  setExtendingIconTexture() {
    let tileImage = this.autoProductionCombined.product ? this.autoProductionCombined.product.icon_url : null;
    if (!tileImage) {
      tileImage = this.autoProductionCombined.currency ? this.autoProductionCombined.currency.iconUrlBig : null;
    }
    if (!tileImage) {
      return;
    }

    if (isAssetLoadedToPhaserCache(tileImage, this.scene && this.scene.game.textures.getTextureKeys())) {
      this.extendButtonByProduct(tileImage);
    } else {
      this.gameService.loadGameImages(this.scene as MyScene, [tileImage])
        .subscribe(() => {
          // Timeout fix to wait till Phaser prepare texture from image.
          setTimeout(() => {
            this.extendButtonByProduct(tileImage);
          }, this.loadDelay);
        });
    }
  }

  extendButtonByProduct(frame) {
    if (!this.tileMenu['scene']) {
      return;
    }
    this.productIconObject = this.myScene.add.sprite(this.x + 8, this.y - 16, frame);
    this.productIconObject.depth = this.depth + 1;
    this.productIconObject.setDisplaySize(32, 32);
    this.tileMenu.add(this.productIconObject);
    this.myScene.tweens.add({
      targets: [this.productIconObject],
      alpha: {
        from: 0,
        to: this.isActive ? 1 : 0,
      },
      ease: Phaser.Math.Easing.Back.Out,
      duration: 300,
      repeat: 0,
    });
    this.setAutoProductionAmount();
  }

  private setAutoProductionAmount() {
    if (!this.autoProductionCombined.currency && !this.autoProductionCombined.product) {
      return;
    }
    this.productTextObject = {
      text: null,
      icon: null,
    };

    this.autoProductionCombined.amount
      = getBuildingAttribute(AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME, this.buildingDetails.attributes).value || 0;
    const textConfig = {
      x: this.x - 40,
      y: this.y - 25,
      text: `${this.autoProductionCombined.amount}/h`,
      origin: 0,
      depth: this.depth + 1,
      style: {
        ...BUILDING_NAME_FONT_STYLES,
        fontSize: '10px',
      },
    };
    this.productTextObject.text = this.myScene.make.text(textConfig);
    this.productTextObject.text.setStroke('#000', 3);
    this.productTextObject.text.setShadow(2, 2, '#00000011', 1, true, true);
    this.tileMenu.add(this.productTextObject.text);
    this.productTextObject.text.x = this.x - this.productTextObject.text.width - 20;

    const iconConfig = {
      ...textConfig,
      text: '\uf021',
      x: this.productTextObject.text.x - 14,
      style: {
        ...BUTTON_ICON_TEXT_STYLE,
        fontSize: '10px',
      },
    };

    this.productTextObject.icon = this.myScene.make.text(iconConfig);
    this.productTextObject.icon.setStroke('#000', 3);
    this.productTextObject.icon.setShadow(2, 2, '#00000011', 1, true, true);
    this.tileMenu.add(this.productTextObject.icon);
  }

}
