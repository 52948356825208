import { Component, OnInit } from '@angular/core';
import { AbstractMessageDetailsNormalComponent } from '../../../../abstract/core/abstract-message-details-normal.component';

@Component({
  selector: 'app-message-details-normal',
  templateUrl: './message-details-normal.component.html',
  styleUrls: ['./message-details-normal.component.scss']
})
export class MessageDetailsNormalComponent extends AbstractMessageDetailsNormalComponent implements OnInit {
  ngOnInit() {
    this.afterInit();
  }
}
