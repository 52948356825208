import { Component } from '@angular/core';
import { AbstractSeparatorComponent } from '../../../abstract/abstract-separator.component';

@Component({
  selector: 'm-ui-separator',
  templateUrl: './ui-separator.component.html',
  styleUrls: ['./ui-separator.component.scss']
})
export class MUiSeparatorComponent extends AbstractSeparatorComponent {
}
