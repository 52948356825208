import { Component, OnInit } from '@angular/core';
import { AbstractChestComponent } from '../../../../abstract/abstract-chest.component';

@Component({
  selector: 'app-chest',
  templateUrl: './chest.component.html',
  styleUrls: ['./chest.component.scss']
})
export class ChestComponent extends AbstractChestComponent implements OnInit {
}
