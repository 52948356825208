import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractBuildingProductionComponent } from '../../../../abstract/core/abstract-building-production.component';

@Component({
  selector: 'app-m-building-production',
  templateUrl: './m-building-production.component.html',
  styleUrls: ['./m-building-production.component.scss']
})
export class MBuildingProductionComponent extends AbstractBuildingProductionComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.baseInit();
  }

  ngOnDestroy() {
    this.baseDestroy();
  }
}
