import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ActionTypes, ChangeFailed, ChangeStart, ChangeSuccess, MustChange } from './actions';
import { TokenObject } from '../../../modules/auth/interfaces/token-object';
import { ChangePassword } from '../../../modules/auth/interfaces/change-password';
import { AuthService } from '../../../modules/auth/providers/auth.service';
import { DialogService } from '../../../modules/shared/providers/dialog.service';
import { Router } from '@angular/router';
import { ApiService } from '../../../core/providers/api.service';
import { UtilityActions } from '../../utility';
import { AppState } from '../../state';
import { Store } from '@ngrx/store';

@Injectable()
export class PasswordEffects {

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private apiService: ApiService,
    private dialogService: DialogService,
    private store: Store<AppState>,
  ) {
  }

  @Effect({dispatch: false})
  changePasswordMustChange$: Observable<any> = this.actions$
    .pipe(
      ofType(ActionTypes.MUST_CHANGE),
      tap((action: MustChange) => {
        this.router.navigate(['/change-password']);
      })
    );

  @Effect()
  changePasswordStart$: Observable<ChangeSuccess | ChangeFailed> = this.actions$
    .pipe(
      ofType(ActionTypes.CHANGE_START),
      mergeMap((action: ChangeStart) => {
        return this.changePassword(action.payload.data, action.payload.tokenObject);
      })
    );

  changePassword(data: ChangePassword, tokenObject: TokenObject): Observable<ChangeSuccess | ChangeFailed> {
    return this.authService.changePassword(data)
      .pipe(
        map(() => {
          return new ChangeSuccess();
        }),
        tap(() => {
          this.store.dispatch(new UtilityActions.SetToken(tokenObject));
        }),
        catchError((error: any) => {
          return of(new ChangeFailed(error));
        })
      )
  }

  @Effect({dispatch: false})
  changePasswordFailed$: Observable<any> = this.actions$
    .pipe(
      ofType(ActionTypes.CHANGE_FAILED),
      tap((action: ChangeFailed) => {
        this.dialogService.openAlertErrorApi({errResp: action.payload});
      })
    )
}
