<!-- LOGO -->
<div class="logo"></div>

<loading></loading>

<div class="container loading-opacity" *ngIf="hiddenUserInfo">
  <p class="description text-center">
    {{ 'auth.reauthorize.its-you' | myTranslate:{hiddenUserInfo: hiddenUserInfo} }}
  </p>

  <div class="buttons flex-center">
    <button
      class="base primary"
      (click)="reauthorize()"
    >
      {{ 'auth.reauthorize.button-send' | myTranslate }}
    </button>
  </div>

  <!-- LOGOUT -->
  <a
    class="description text-center logout"
    (click)="logout()"
  >
    <p>{{ 'auth.reauthorize.its-not-you' | myTranslate }}</p>
  </a>

  <!-- HELPDESK -->
  <p class="description text-center margin-v1">
    {{ 'global.helpdesk-email' | myTranslate }}
  </p>
</div>


